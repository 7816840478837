import React, { FunctionComponent } from 'react'

import 'app/mainframe/components/Content/ContentPreview/ContentPreview.css'

interface ContentPreviewProps {
  content: {
    title: string
    description?: string
    creators?: string
    slug: string
    formSlug: string
    medium: string
    mediumDisplay: string
    publishedAt?: string
    image?: any
    users?: any[]
    totalUsers: number
  }
  log?: {
    user: {
      name: string
      username: string
      image: string
    }
    note: string
  }
  theme?: string
}

const ContentPreviewEmbed: FunctionComponent<ContentPreviewProps> = ({
  content,
  log,
  theme
}) => {
  return (
    <a
      className='text-decoration-none flex justify-center p-5'
      href={`${process.env.ROOT_PATH}/${content.formSlug}/${content.slug}${
        log ? `/@${log.user.username}` : ''
      }`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className='max-w-[386px] p-5 bg-gray-800 rounded-lg shadow-lg box-content'>
        <div className='contentImage h-[250px] flex items-center justify-center w-full'>
          <div className='imgWrapper w-1/3'>
            <div>
              <img
                className='shadow-book w-full'
                src={content.image}
                alt={content.title}
              />
            </div>
          </div>
        </div>

        <h1 className='mt-4 text-2xl'>{content.title}</h1>
        {content.creators && (
          <h3 className='single-line text-gray-100 text-base'>
            {content.creators}
          </h3>
        )}
        {log ? (
          <div className='flex flex-col mt-5'>
            <div className='single-line flex items-center text-gray-50 text-xl'>
              <img
                className='ml-1 mr-3 w-8 h-8 rounded-full'
                src={log.user.image}
                alt={log.user.name}
              />
              {log.user.name}
            </div>
            <div className='mt-2 p-3 bg-gray-700 rounded-lg shadow-lg'>
              <p className='line-clamp-3 text-gray-100 text-base'>{log.note}</p>
            </div>
          </div>
        ) : (
          <p className='line-clamp-2 mt-5 text-gray-300 text-base'>
            {content.description}
          </p>
        )}

        <div className='flex items-center mt-8'>
          {content.totalUsers > 0 && (
            <div className='flex items-center'>
              {content.users.map(user => (
                <img
                  key={user}
                  src={user}
                  className='mr-[-6px] w-7 h-7 border-2 border-gray-800 rounded-full'
                />
              ))}
              <p className='ml-4 text-gray-200 text-sm leading-none'>
                View all{' '}
                {content.totalUsers > 1 ? `${content.totalUsers} ` : ''}logs
              </p>
            </div>
          )}
          <img
            className='h-[21px] ml-auto'
            src='https://ik.imagekit.io/analogue/analogue_logo_WeDM8Tame.png?tr=h-63'
            alt='Analogue Logo'
          />
        </div>
      </div>
    </a>
  )
}

export default ContentPreviewEmbed
