import React, { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from 'app/mainframe/context/auth'

import AppLayout from 'app/mainframe/layouts/AppLayout'
import Layout from 'app/mainframe/layouts/Layout'

const Auth = lazy(() => import('app/mainframe/pages/public/Auth'))
const AuthToken = lazy(() => import('app/mainframe/pages/app/AuthToken'))

const Teaser = lazy(() => import('app/mainframe/pages/public/Teaser'))
const Home = lazy(() => import('app/mainframe/pages/public/Home'))

const Libraries = lazy(() => import('app/mainframe/pages/public/Libraries'))
const Section = lazy(() => import('app/mainframe/pages/public/Section/Section'))
const SubSection = lazy(
  () => import('app/mainframe/pages/public/Section/SubSection')
)

const Features = lazy(() => import('app/mainframe/pages/public/Features'))
const About = lazy(() => import('app/mainframe/pages/public/About'))
const Readme = lazy(() => import('app/mainframe/pages/public/Readme'))
const Membership = lazy(() => import('app/mainframe/pages/public/Membership'))
const Changelog = lazy(
  () => import('app/mainframe/pages/public/Changelog/Changelog')
)

const Privacy = lazy(() => import('app/mainframe/pages/public/legal/Privacy'))
const Terms = lazy(() => import('app/mainframe/pages/public/legal/Terms'))
const Community = lazy(
  () => import('app/mainframe/pages/public/legal/Community')
)

const Onboard = lazy(() => import('app/mainframe/pages/app/Onboard'))
const Feed = lazy(() => import('app/mainframe/pages/app/Feed'))
const Explore = lazy(() => import('app/mainframe/pages/app/Explore'))

const Profile = lazy(() => import('app/mainframe/pages/app/Profile/Profile'))
const Content = lazy(() => import('app/mainframe/pages/app/Content'))
const Log = lazy(() => import('app/mainframe/pages/app/Log/Log'))
const LogShare = lazy(() => import('app/mainframe/pages/app/Log/LogShare'))
const Saved = lazy(() => import('app/mainframe/pages/app/Saved/Saved'))

const Primer = lazy(() => import('app/mainframe/pages/app/Primer/Primer'))
const PrimerCreate = lazy(
  () => import('app/mainframe/pages/app/Primer/PrimerCreate')
)
const PrimerEdit = lazy(
  () => import('app/mainframe/pages/app/Primer/PrimerEdit')
)
const PrimerShare = lazy(
  () => import('app/mainframe/pages/app/Primer/PrimerShare')
)

const Settings = lazy(() => import('app/mainframe/pages/app/Settings'))
const NotFound = lazy(() => import('app/mainframe/pages/NotFound'))

const publicRoutes = [
  { path: 'app', element: <Home /> },
  { path: 'features', element: <Features /> },
  { path: 'about', element: <About /> },
  { path: 'membership', element: <Membership /> },
  { path: 'readme', element: <Readme /> },
  { path: 'changelog', element: <Changelog /> },
  { path: 'changelog/:slug', element: <Changelog /> },
  { path: 'privacy', element: <Privacy /> },
  { path: 'terms', element: <Terms /> },
  { path: 'community', element: <Community /> }
]
const tempRoutes = [
  { path: 'libraries', element: <Libraries /> },
  { path: 'culture', element: <Section /> },
  { path: 'culture/:subsection', element: <SubSection /> }
]

const appRoutes = [
  { path: 'explore', element: <Explore /> },
  { path: '@:username', element: <Profile /> },
  { path: '@:username/:type', element: <Profile /> },
  { path: ':medium/:slug', element: <Content /> },
  { path: ':medium/:slug/@:username', element: <Log /> },
  { path: 'collection/:slug', element: <Primer /> }
]

const appRoutesPrivate = [
  { path: 'feed', element: <Feed /> },
  { path: 'saved', element: <Saved /> },
  { path: 'collection/:slug/organize', element: <Primer organize /> },
  { path: '/welcome', element: <Onboard /> },
  {
    path: '/collection/:slug/onboard',
    element: (
      <div className='mx-auto max-w-screen-2xl px-6 pt-4'>
        <Primer organize onboard />
      </div>
    )
  }
]

const modalRoutesPrivate = [
  { path: '/collection/new', element: <PrimerCreate /> },
  { path: '/collection/:slug/edit', element: <PrimerEdit /> },
  { path: '/settings', element: <Settings /> },
  { path: '/settings/:type', element: <Settings /> }
]

const modalRoutes = [
  { path: '/:medium/:slug/@:username/share', element: <LogShare /> },
  { path: '/collection/:slug/share', element: <PrimerShare /> },
  { path: '/collection/:slug/onboard/share', element: <PrimerShare onboard /> },
  { path: '/login/:token', element: <AuthToken /> }
]

const routes = (routeList, wrapper = false) =>
  routeList.map(item => (
    <Route
      key={item.path}
      path={item.path}
      element={
        wrapper ? (
          <div className='mx-auto max-w-screen-2xl px-6 pt-4 pb-20 sm:pb-0'>
            {item.element}
          </div>
        ) : (
          item.element
        )
      }
    />
  ))

const AppRoutes = () => {
  const { currentUser } = useAuth()

  if (currentUser) {
    return (
      <Routes>
        {routes(modalRoutes)}
        {routes(modalRoutesPrivate)}

        <Route path='/' element={<AppLayout />}>
          <Route
            path=''
            element={
              <Navigate
                to={
                  currentUser.onboarded
                    ? `/@${currentUser.username}`
                    : '/welcome'
                }
              />
            }
          />
          {routes(appRoutes)}
          {routes(appRoutesPrivate)}
          <Route
            path='login'
            element={<Navigate to={`/@${currentUser.username}`} />}
          />
          <Route path='signup' element={<Navigate to='/' />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route path='/' element={<Layout />}>
          {routes(publicRoutes)}
        </Route>
        <Route path='/' element={<Layout header />}>
          {routes(tempRoutes)}
        </Route>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path='/' element={<Teaser />} />
      <Route path='/login' element={<Auth />} />
      <Route path='/login/reset' element={<Auth forgot />} />
      <Route path='/login/reset/:token' element={<Auth reset />} />
      <Route path='/signup' element={<Auth signup />} />
      {routes(modalRoutes)}

      <Route path='/' element={<Layout />}>
        {routes(appRoutes, true)}
        {routes(publicRoutes)}
        <Route path='*' element={<Navigate to='login' />} />
      </Route>

      <Route path='/' element={<Layout header />}>
        {routes(tempRoutes)}
      </Route>
    </Routes>
  )
}

export default AppRoutes
