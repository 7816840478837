import React from 'react'
import {
  Popover as MantinePopover,
  PopoverProps as MantinePopoverProps
} from '@mantine/core'
import clsx from 'clsx'

interface PopoverProps extends MantinePopoverProps {
  content: React.ReactNode
  className?: string
}
const Popover = (props: PopoverProps) => {
  const { children, content, className } = props
  return (
    <MantinePopover
      {...props}
      classNames={{
        dropdown: clsx([
          'rounded-md border border-gray-650 bg-gray-700 shadow-xl p-0',
          className
        ])
      }}
    >
      <MantinePopover.Target>{children}</MantinePopover.Target>
      <MantinePopover.Dropdown>{content}</MantinePopover.Dropdown>
    </MantinePopover>
  )
}

export default Popover
