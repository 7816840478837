import React from 'react'

import { useAppConfig } from 'app/mainframe/context/appConfig'
import { useAuth } from 'app/mainframe/context/auth'

import ContentHeader from 'app/mainframe/components/Content/ContentHeader'
import Editor from 'app/mainframe/components/Editor/Editor'

import clsx from 'clsx'
import EmptyNote from './EmptyNote'
import LogHeader from './LogHeader'
import LogFooter from './LogFooter'

const LogNote = ({
  log,
  content,
  onCancel = null,
  editable = false,
  hideContent = false
}: {
  log: any
  content: any
  onCancel?: any
  editable?: boolean
  hideContent?: boolean
}) => {
  const { currentUser } = useAuth()
  const { embed } = useAppConfig()

  const isOwner = currentUser && currentUser.username === log.user.username

  return (
    <>
      {content && !hideContent && (
        <ContentHeader
          content={content}
          className='border-b border-gray-50 px-5 pb-8 pt-5 dark:border-gray-700'
        />
      )}

      {log && !log.noteText && !editable && (
        <EmptyNote
          className='my-12'
          text={
            isOwner
              ? "You haven't left a note yet"
              : `${log?.user?.displayName} hasn't left a note yet`
          }
          link={
            isOwner
              ? `/${content?.formSlug}/${content?.slug}/@${log?.user?.username}`
              : `/${content?.formSlug}/${content?.slug}`
          }
        />
      )}

      {((log && log.noteText && log.user) || editable) && (
        <>
          <LogHeader log={log} />
          <div className={clsx(['px-2 pt-4 sm:px-8', { 'pb-4': !isOwner }])}>
            <Editor
              editable={isOwner}
              isOwner={isOwner}
              log={log}
              content={content}
              onCancel={onCancel}
            />
          </div>
          {!embed && <LogFooter content={content} log={log} />}
        </>
      )}
    </>
  )
}

export default LogNote
