import React, { useState, createContext, useContext } from 'react'

const LogCarouselContext = createContext(null)

const LogCarouselProvider = ({ children }) => {
  const [isResized, setIsResized] = useState(false)
  return (
    <LogCarouselContext.Provider value={{ isResized, setIsResized }}>
      {children}
    </LogCarouselContext.Provider>
  )
}

const useLogCarouselContext = () => {
  const context = useContext(LogCarouselContext)
  if (context === undefined) {
    throw new Error(`useLogCarousel must be used within LogCarouselProvider`)
  }
  return context
}

export { LogCarouselProvider, useLogCarouselContext }
