import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import Header from 'app/mainframe/components/Header/Header'
import Header2 from 'app/mainframe/components/Header/Header2'
import Footer from 'app/mainframe/components/Footer/Footer'
import AudioPlayer from 'app/mainframe/components/AudioPlayer/AudioPlayer'

import { AiOutlineLoading } from 'react-icons/ai'

const Layout = ({ header = false }) => {
  return (
    <>
      {header ? <Header2 /> : <Header />}
      <Suspense
        fallback={
          <div className='mt-[-50px] flex h-screen w-screen items-center justify-center'>
            <AiOutlineLoading className='flex items-center justify-center text-2xl leading-none animate-spin text-gray-50' />
          </div>
        }
      >
        <Outlet />
      </Suspense>
      <AudioPlayer />
      <Footer />
    </>
  )
}

export default Layout
