import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { toast } from 'react-toastify'

const CopyLink = ({ to, children, className = '' }) => {
  const handleCopy = e => {
    e.preventDefault()
    toast.success('Link copied')
  }

  return (
    <CopyToClipboard text={`${window.location.origin}${to}`}>
      <button type='button' className={className} onClick={handleCopy}>
        {children}
      </button>
    </CopyToClipboard>
  )
}

export default CopyLink
