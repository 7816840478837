import React, { useEffect, useMemo, useState } from 'react'
import { useAudio } from 'react-use'

import { usePlayer } from 'app/mainframe/context/player'

import Image from 'app/mainframe/components/Image'
import CustomRange from './CustomRange'

import { RiPlayCircleFill, RiPauseCircleFill } from 'react-icons/ri'
import { IoClose } from 'react-icons/io5'
import { Icon } from './Icons'

function secondsToTime(seconds) {
  return new Date(1000 * seconds).toISOString().substr(14, 5)
}

const AudioPlayer = () => {
  const [playbackRate, setPlaybackRate] = useState(1)
  const { show, current, player } = usePlayer()

  const [audio, state, controls, ref] = useAudio({
    src: current?.mediaUrl
  })

  useEffect(() => {
    player.setControls(controls)
  }, [])

  useEffect(() => {
    controls.play()
  }, [current])

  useEffect(() => {
    if (show) {
      controls.play()
    }
  }, [show])

  useEffect(() => {
    state.playing ? player.play(current) : player.pause()
  }, [state.playing])

  useEffect(() => {
    if (ref?.current?.playbackRate) {
      ref.current.playbackRate = playbackRate
    }
  }, [playbackRate])

  const volumeIcon = useMemo(() => {
    if (state.volume === 0 || state.muted) return 'volumeMuted'
    if (state.volume > 0 && state.volume < 0.33) return 'volumeLow'
    if (state.volume >= 0.33 && state.volume < 0.66) return 'volumeNormal'
    return 'volumeFull'
  }, [state.volume, state.muted])

  const playbackIcon = useMemo(() => {
    if (playbackRate === 1.5) return 'playBack150'
    if (playbackRate === 2) return 'playBack200'
    return 'playBack100'
  }, [playbackRate])

  const setPlayback = () => {
    if (playbackRate === 1) setPlaybackRate(1.5)
    if (playbackRate === 1.5) setPlaybackRate(2)
    if (playbackRate === 2) setPlaybackRate(1)
  }

  if (show) {
    return (
      <div className='fixed bottom-0 z-20 w-full border-t border-gray-666 bg-gray-777 py-2 backdrop-blur transition duration-300'>
        <div className='flex h-full items-center justify-between px-4'>
          <div className='md:w-[30%] md:min-w-[11.25rem]'>
            <div className='single-line flex w-full cursor-pointer items-center'>
              <div className='mr-4 h-14 w-14 flex-shrink-0'>
                <Image
                  src={current.image}
                  alt={current.title}
                  className='h-full w-full object-contain'
                />
              </div>
              <div className='single-line mr-0 hidden flex-col md:flex'>
                <p className='single-line text-base text-white'>
                  {current.title}
                </p>
                <p className='single-line text-sm text-gray-200'>
                  {current.creatorList &&
                    current.creatorList.map(c => c.name).join(', ')}
                </p>
              </div>
            </div>
          </div>
          <div className='flex w-full flex-col items-center px-1 pt-2 sm:px-4 md:w-[40%] md:max-w-[45.125rem]'>
            <div className='flex items-center gap-x-2'>
              <button
                onClick={() => controls.seek(state.time - 10)}
                className='flex h-8 w-8 flex-shrink-0 items-center justify-center text-white text-opacity-70 hover:text-opacity-100'
              >
                <Icon name='seekBack' />
              </button>
              <button
                onClick={controls[state?.playing ? 'pause' : 'play']}
                className='flex h-10 w-10 items-center justify-center rounded-full bg-white text-gray-800 duration-200 hover:scale-[1.06]'
              >
                {state?.playing ? (
                  <RiPauseCircleFill className='h-full w-full' />
                ) : (
                  <RiPlayCircleFill className='h-full w-full' />
                )}
              </button>
              <button
                onClick={() => controls.seek(state.time + 10)}
                className='flex h-8 w-8 flex-shrink-0 items-center justify-center text-white text-opacity-70 hover:text-opacity-100'
              >
                <Icon name='seekForward' />
              </button>
            </div>

            <div className='mt-1.5 flex w-full items-center gap-x-2'>
              {audio}
              <div className='text-[0.688rem] text-white text-opacity-70'>
                {secondsToTime(state?.time)}
              </div>
              <CustomRange
                step={0.1}
                min={0}
                max={state?.duration || 1}
                value={state?.time}
                onChange={value => controls.seek(value)}
              />
              <div className='text-[0.688rem] text-white text-opacity-70'>
                {secondsToTime(state?.duration)}
              </div>
            </div>
          </div>

          <div className='flex items-center justify-end md:w-[30%] md:min-w-[11.25rem]'>
            <button
              onClick={setPlayback}
              className='mr-4 hidden h-8 w-8 items-center justify-center text-white text-opacity-70 duration-200 hover:text-opacity-100 sm:flex'
            >
              <Icon name={playbackIcon} />
            </button>
            <button
              onClick={controls[state.muted ? 'unmute' : 'mute']}
              className='hidden h-8 w-8 items-center justify-center text-white text-opacity-70 duration-200 hover:text-opacity-100 sm:flex'
            >
              <Icon size={16} name={volumeIcon} />
            </button>
            <div className='hidden w-[5.813rem] max-w-full md:block'>
              <CustomRange
                step={0.01}
                min={0}
                max={1}
                value={state.muted ? 0 : state?.volume}
                onChange={value => {
                  controls.unmute()
                  controls.volume(value)
                }}
              />
            </div>

            <button
              onClick={player.close}
              className='ml-4 flex items-center justify-center text-xs uppercase tracking-widest text-white text-opacity-70 duration-200 hover:text-opacity-100'
            >
              <IoClose size={21} />
              <span className='hidden md:block'> Close</span>
            </button>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default AudioPlayer
