import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import {
  useNotifications,
  useUnreadNotifications,
  useMarkNotificationAsRead
} from 'app/mainframe/queries/notification'

import { useAuth } from 'app/mainframe/context/auth'

import Image from 'app/mainframe/components/Image'
import FollowButton from 'app/mainframe/components/Profile/FollowButton'

import { Popover, Transition } from '@headlessui/react'
import { FiBell } from 'react-icons/fi'
import { IoSettingsSharp } from 'react-icons/io5'
import { AiOutlineLoading } from 'react-icons/ai'

const NotificationItem = ({ id, actor, message, read, content = null }) => {
  const { currentUser } = useAuth()

  const { mutate } = useMarkNotificationAsRead()
  const [isRead, setIsRead] = useState(read)

  const markAsRead = () => {
    if (!isRead) {
      setIsRead(!isRead)
      mutate(id)
    }
  }

  return (
    <Link
      onMouseEnter={markAsRead}
      to={
        content
          ? `/${content.formSlug}/${content.slug}/@${currentUser.username}`
          : `/@${actor.username}`
      }
      className={clsx([
        'mb-3 flex select-none items-center rounded border-l-2 py-4 pl-2 pr-3 align-text-bottom transition duration-200 last:mb-0',
        { 'border-gold-500 bg-gray-650': !isRead, 'border-transparent': isRead }
      ])}
      onClick={markAsRead}
    >
      <Image
        ratio='1x1'
        className={clsx([
          'mr-2 h-7 w-7 rounded-full border object-cover object-top',
          actor.curated ? 'border-gold-500' : 'border-gray-700'
        ])}
        src={actor.image}
        alt={actor.name}
      />
      <h2 className='mr-1 flex-shrink-0 text-base'>
        {actor.name ? actor.name : actor.username}
      </h2>
      <p className='single-line mr-auto text-base text-gray-200'>{message}</p>
      {content ? (
        <div className='ml-2 h-12 w-12 flex-shrink-0'>
          <Image
            src={content.image}
            alt={content.title}
            className='h-full w-full object-contain'
          />
        </div>
      ) : (
        <FollowButton
          followText='Follow back'
          className='ml-2 h-[26px] w-[90px] flex-shrink-0 p-0 text-tiny'
          unstyled
          profile={actor}
        />
      )}
    </Link>
  )
}

const NotificationFeed = () => {
  const { data, isLoading } = useNotifications()

  if (isLoading) {
    return (
      <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
        <AiOutlineLoading className='flex animate-spin items-center justify-center text-xl leading-none text-gray-50' />
      </div>
    )
  }
  if (data && data.length === 0) {
    return (
      <div className='flex flex-col items-center justify-center text-center'>
        <svg
          viewBox='0 0 30 30'
          className='mb-3 mt-10 w-12 flex-shrink-0 fill-[#999]'
        >
          <path d='M4.445 25.3h20.532c2.296 0 3.457-1.16 3.457-3.421v-6.715c0-.902-.13-1.324-.563-1.887l-4.406-5.53c-1.195-1.5-1.793-1.934-3.668-1.934H9.625c-1.875 0-2.473.433-3.668 1.933l-4.406 5.531c-.434.563-.563.985-.563 1.887v6.715c0 2.273 1.172 3.422 3.457 3.422zm10.266-6.948c-1.734 0-2.93-1.442-2.93-3v-.059c0-.422-.234-.785-.738-.785h-8.11c-.304 0-.374-.27-.222-.469l4.664-5.93c.574-.726 1.207-1.02 2.121-1.02h10.43c.914 0 1.547.294 2.12 1.02l4.665 5.93c.164.2.094.469-.211.469h-8.121c-.504 0-.738.363-.738.785v.059c0 1.558-1.196 3-2.93 3zM4.469 23.859c-1.313 0-2.028-.703-2.028-2.05v-5.977h7.957c.188 2.25 2.004 3.855 4.313 3.855 2.309 0 4.125-1.617 4.312-3.855h7.957v5.977c0 1.347-.738 2.05-2.027 2.05H4.47z' />
        </svg>
        <h3 className='mb-1 text-lg font-light'>You&apos;re all caught up</h3>
        <p className='text-sm font-light text-gray-200'>
          When someone follows you, you’ll be notified here.
        </p>
      </div>
    )
  }
  return data.map(item => {
    return <NotificationItem key={item.id} {...item} />
  })
}

const Notifications = () => {
  const { isFetching, isLoading } = useNotifications()
  const { data: unreadCount } = useUnreadNotifications()

  return (
    <Popover className='static sm:relative'>
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx([
              'mr-4 rounded-lg p-2 text-xl text-gray-300 transition duration-200 hover:bg-gray-700 hover:text-gold-500',
              {
                'bg-gray-700 text-gold-500': open,
                'bg-gray-750 text-white': unreadCount && !open
              }
            ])}
          >
            <FiBell />

            <span
              className={clsx([
                'absolute left-[21px] top-0 inline-flex h-5 w-5 items-center justify-center rounded-full border border-gray-700 bg-gold-500 text-xs text-white transition duration-200',
                { 'opacity-0': open || unreadCount === 0 }
              ])}
            >
              {unreadCount}
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute top-[-100vh] -right-0 z-10 mt-[75px] h-screen max-h-[400px] w-screen max-w-md transform px-4 sm:-right-12 sm:top-0 sm:mt-[50px] sm:max-h-full sm:px-0 md:max-h-almost'>
              <div className='h-full overflow-y-auto overflow-x-hidden rounded-lg bg-gray-700 px-6 pb-6 pt-2 shadow-lg ring-1 ring-black ring-opacity-5'>
                <div className='mb-4 flex flex-row items-center justify-between border-b border-gray-650'>
                  <div className='mr-6 cursor-pointer select-none border-b-2 border-gold-500 py-3 text-sm uppercase tracking-widest text-gold-500 last:mr-0'>
                    {`Notifications${unreadCount ? ` (${unreadCount})` : ''}`}
                  </div>
                  <div className='flex items-center'>
                    {isFetching && !isLoading && (
                      <AiOutlineLoading className='mr-2 flex animate-spin items-center justify-center text-sm leading-none text-gray-50' />
                    )}
                    <Link to='/settings/notifications'>
                      <IoSettingsSharp className='text-base text-gray-300 hover:text-gray-50' />
                    </Link>
                  </div>
                </div>
                <NotificationFeed />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default Notifications
