import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { useAuth } from 'app/mainframe/context/auth'

const RequireAuth = ({ children }) => {
  const { currentUser } = useAuth()

  // override onClick of child component
  const onClick = e => {
    if (currentUser) {
      if (children.props?.onClick) {
        children.props.onClick(e)
      }
    } else {
      e.preventDefault()
      e.stopPropagation()
      NiceModal.show('signup-modal')
    }
  }
  return React.cloneElement(children, {
    onClick
  })
}
export default RequireAuth
