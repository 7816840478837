import React from 'react'
import clsx from 'clsx'

import { Button as AntButton } from 'antd'
import { ButtonProps as AntButtonProps } from 'antd/lib/button'

export declare type ButtonProps = AntButtonProps & {}

import './Button.css'

interface Props extends AntButtonProps {
  unstyled?: boolean
}
/**
 * Wrapper around Ant Design button that allows you to extend with Tailwind CSS utility classes.
 * Refer to [their docs](https://ant.design/components/button) to see all available props.
 */
const Button = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const { children, className, unstyled = false, ...propsRest } = props

  const cx = clsx(
    'inline-flex items-center justify-center font-serif font-serif font-medium tracking-widest leading-none outline-none focus:outline-none uppercase',
    {
      'text-gray-800 border border-gray-800 hover:bg-gray-800 hover:text-gray-100 dark:text-gray-50 dark:border-gray-50 dark:hover:bg-gray-50 dark:hover:text-gray-800':
        !unstyled && !propsRest.type || propsRest.type == 'default',
      'dark:text-gray-500 dark:border-gray-500 border-solid dark:hover:bg-gray-50 dark:hover:text-gray-800':
        propsRest.type === 'dashed',
      'dark:bg-gray-800 dark:border-gold-500 dark:text-gold-500 dark:hover:bg-gray-50 dark:hover:text-gray-800':
        propsRest.type === 'primary',
      'text-xs py-4 px-4': propsRest.size === 'small',
      'text-sm py-6 px-6':
        !unstyled && (!propsRest.size || propsRest.size === 'middle'),
      'text-base py-8 px-8': propsRest.size === 'large',
      '': unstyled
    }
  )

  return (
    <AntButton ref={ref} className={clsx([className, cx])} {...propsRest}>
      {children}
    </AntButton>
  )
})

export default Button
