import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useAuth } from 'app/mainframe/context/auth'
import { FiCompass, FiBookmark, FiUser } from 'react-icons/fi'

import analogueIcon from 'shared/assets/img/icon_gold_transparent.png'
import clsx from 'clsx'

const Sidebar = () => {
  const { currentUser } = useAuth()
  const { pathname } = useLocation()

  const links = [
    {
      name: 'Profile',
      pathname: `/@${currentUser.username}`,
      icon: <FiUser />
    },
    {
      name: 'Explore',
      pathname: '/explore',
      icon: <FiCompass />
    },
    {
      name: 'Saved',
      pathname: '/saved',
      icon: <FiBookmark />
    }
  ]

  return (
    <div className='fixed inset-0 flex h-screen w-24 flex-col items-center justify-between border-r border-gray-666 bg-gray-777 py-5'>
      <Link className='relative' to='/'>
        <img src={analogueIcon} alt='Analogue logo' className='w-8' />
        <div className='absolute bottom-[-8px] right-[-19px] rounded-[4px] border border-gray-500 bg-gray-700 px-1 text-xs text-gray-100'>
          beta
        </div>
      </Link>

      <div className='flex w-full flex-col items-center space-y-12 text-3xl'>
        {links.map(link => {
          const isActive = pathname === link.pathname
          return (
            <Link
              key={link.pathname}
              className={clsx([
                'w-full border-r transition duration-200',
                isActive ? 'border-gold-500' : ' border-transparent'
              ])}
              to={link.pathname}
            >
              {React.cloneElement(link.icon, {
                className: clsx([
                  'mx-auto stroke-1 transition duration-200',
                  isActive
                    ? 'text-gold-500'
                    : 'text-gray-300 hover:text-gold-500'
                ])
              })}
            </Link>
          )
        })}
      </div>

      <Link className='invisible' to='/'>
        <img src={analogueIcon} alt='Analogue logo' className='w-8' />
      </Link>
    </div>
  )
}

export default Sidebar
