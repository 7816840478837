import api from 'app/mainframe/api'
import { useQuery } from 'react-query'

import { useInfiniteQueryWithInitialData } from 'app/mainframe/queries/utils'

const useInfiniteCollectiveContents = (slug, users, filters = null) =>
  useInfiniteQueryWithInitialData(
    ['collective', slug, 'contents', users ? users : 'all', filters],
    ['collective', slug, 'contents', users ? users : 'all', filters],
    api.Collective.contents(slug, users, filters)
  )

const useCollectiveLogs = (slug, content) =>
  useQuery(
    ['collective', slug, 'logs', content ? content.slug : null],
    () => api.Collective.logs(slug, content ? content.id : null),
    {
      retry: false,
      enabled: false // manually trigger refetch to avoid in FeedView fetching null
    }
  )

export { useInfiniteCollectiveContents, useCollectiveLogs }
