import React, { useState } from 'react'
import { RiPlayCircleFill, RiPauseCircleFill } from 'react-icons/ri'
import clsx from 'clsx'
import { usePlayer } from 'app/mainframe/context/player'
import VideoModal from 'app/mainframe/components/Content/ContentPlayButton/VideoModal'

const ContentPlayButton = ({ content }) => {
  const { playing, current, player, controls } = usePlayer()
  const [videoVisible, setVideoVisible] = useState(false)

  const isCurrentlyPlaying = playing && current && current.id === content.id

  return (
    <>
      <button
        type='button'
        className={clsx([
          'shadow-book absolute z-10 flex items-center justify-center rounded-full bg-white duration-200 hover:scale-110 hover:bg-gold-500',
          content.formDisplay === 'audio'
            ? 'bottom-2 right-2 h-10 w-10'
            : 'top-1/2 left-1/2 h-9 w-9 -translate-x-1/2 -translate-y-1/2 transform'
        ])}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          if (content.form === 'video') {
            setVideoVisible(!videoVisible)
          } else if (isCurrentlyPlaying) {
            player.pause()
            controls.pause()
          } else {
            player.play(content)
            controls.play()
          }
        }}
      >
        {isCurrentlyPlaying ? (
          <RiPauseCircleFill className='absolute h-full w-full scale-110 text-gray-800' />
        ) : (
          <RiPlayCircleFill className='absolute h-full w-full scale-110 text-gray-800' />
        )}
      </button>
      {content.form === 'video' && (
        <VideoModal
          content={content}
          setVisible={setVideoVisible}
          visible={videoVisible}
        />
      )}
    </>
  )
}

export default ContentPlayButton
