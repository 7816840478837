import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState
} from 'react'
import api from 'app/mainframe/api'
import logger from 'use-reducer-logger'

import { useModal } from '@ebay/nice-modal-react'

import OracleButton from 'app/mainframe/components/OracleButton'

import { toast } from 'react-toastify'
import { AiOutlineLoading } from 'react-icons/ai'

const localStorageKey = 'jwt_auth_token'

const initialState = {
  currentUser: null,
  isLoading: false,
  error: null
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'AUTH':
      return {
        ...state,
        isLoading: true
      }
    case 'AUTH_SUCCESS':
      return {
        ...initialState,
        currentUser: action.response.user
      }
    case 'AUTH_ERROR':
      return {
        ...initialState,
        error: action.response
      }
    case 'LOGOUT':
      return initialState
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const AuthStateContext = createContext(null)

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(
    process.env.NODE_ENV === 'development' ? logger(authReducer) : authReducer,
    initialState
  )
  const [appLoading, setAppLoading] = useState(true)
  const [oracleLink, setOracleLink] = useState(null)

  const signupModal = useModal('signup-modal')

  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = () => {
    try {
      const authToken = localStorage?.getItem(localStorageKey)
      if (authToken) {
        api.setAccessToken(authToken)
        api.Auth.user().then(
          response => {
            dispatch({ type: 'AUTH_SUCCESS', response })
            setAppLoading(false)
          },
          () => {
            logout()
            setAppLoading(false)
          }
        )
      } else {
        setAppLoading(false)
      }
    } catch (error) {
      setAppLoading(false)
    }
  }

  const signup = ({ email, username, name, password }) => {
    dispatch({ type: 'AUTH' })
    api.Auth.signup({ email, username, name, password }).then(
      response => {
        window.location.href = '/welcome'
        localStorage?.setItem(localStorageKey, response.user.token)
        api.setAccessToken(response.user.token)
        dispatch({ type: 'AUTH_SUCCESS', response })
        signupModal.hide()
      },
      error => {
        dispatch({ type: 'AUTH_ERROR', response: error })
      }
    )
  }

  const login = ({ email, password }) => {
    dispatch({ type: 'AUTH' })
    api.Auth.login({ email, password }).then(
      response => {
        localStorage?.setItem(localStorageKey, response.user.token)
        api.setAccessToken(response.user.token)
        dispatch({ type: 'AUTH_SUCCESS', response })
      },
      error => {
        dispatch({ type: 'AUTH_ERROR', response: error })
      }
    )
  }

  const logout = () => {
    localStorage?.removeItem(localStorageKey)
    api.setAccessToken(null)
    dispatch({ type: 'LOGOUT' })
  }

  const update = user => {
    dispatch({ type: 'AUTH' })
    api.Auth.update(user).then(
      response => {
        dispatch({ type: 'AUTH_SUCCESS', response })
        toast.success('Profile updated')
      },
      error => {
        dispatch({ type: 'AUTH_ERROR', response: error })
      }
    )
  }

  const updatePassword = ({ password, token }) => {
    api.Password.update({ password, token }).then(
      response => {
        dispatch({ type: 'AUTH_SUCCESS', response })
        toast.success('Password updated')
      },
      error => {
        dispatch({ type: 'AUTH_ERROR', response: error })
      }
    )
  }

  const forgotPassword = ({ email }) => api.Password.forgot(email)
  const checkToken = token => api.Password.token(token)

  const onboard = () => {
    api.User.onboard().then(
      response => {
        dispatch({ type: 'AUTH_SUCCESS', response })
      },
      error => {
        dispatch({ type: 'AUTH_ERROR', response: error })
      }
    )
  }

  const handleSetOracleLink = link => {
    if (state.currentUser && state.currentUser.type === 'oracle') {
      setOracleLink(link)
    }
  }

  if (appLoading) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <AiOutlineLoading className='flex animate-spin items-center justify-center text-2xl leading-none text-gray-50' />
      </div>
    )
  }

  return (
    <AuthStateContext.Provider
      value={{
        ...state,
        signup,
        login,
        logout,
        update,
        onboard,
        loadUser,
        setOracleLink: handleSetOracleLink,
        updatePassword,
        forgotPassword,
        checkToken
      }}
    >
      {children}
      {state?.currentUser &&
        state?.currentUser.type === 'oracle' &&
        oracleLink && <OracleButton to={oracleLink} />}
    </AuthStateContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth, localStorageKey }
