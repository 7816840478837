import React from 'react'
import clsx from 'clsx'

const ProfileGroup = ({
  users,
  className
}: {
  users: any
  className?: string
}) => (
  <div className={clsx(['flex items-center space-x-2', className])}>
    <div className='flex items-center -space-x-1'>
      {users.map(user => (
        <img
          key={user.id}
          className={clsx([
            'inline-block h-5 w-5 rounded-full border border-gray-800',
            { 'border-gold-500': user.curated }
          ])}
          src={user.image}
          alt={user.displayName}
        />
      ))}
    </div>
  </div>
)

export default ProfileGroup
