import React, { useState } from 'react'
import clsx from 'clsx'

import { useAuth } from 'app/mainframe/context/auth'
import { useLikeLog } from 'app/mainframe/queries/log'

import { FaBookmark, FaRegBookmark } from 'react-icons/fa'

const LikeButton = ({ log, className = null }) => {
  const { currentUser } = useAuth()
  const [liked, setLiked] = useState(!!log.like)
  const [likesCount, setLikesCount] = useState(log.likesCount)

  const { mutate, isLoading } = useLikeLog(log.id)

  const handleClick = e => {
    e.preventDefault()
    setLiked(!liked)
    setLikesCount(liked ? likesCount - 1 : likesCount + 1)
    mutate(liked)
  }

  if (log && currentUser) {
    return (
      <button
        type='button'
        className={clsx([
          'transition duration-200 hover:text-gold-500',
          className,
          {
            'pointer-events-none': isLoading
          }
        ])}
        onClick={handleClick}
      >
        {liked ? (
          <FaBookmark className='text-gold-500' />
        ) : (
          <FaRegBookmark className='text-gold-500' />
        )}
      </button>
    )
  }
  return null
}

export default LikeButton
