import React, { useEffect, useState } from 'react'

import RequireAuth from 'app/mainframe/components/Auth/RequireAuth'
import { useFollow } from 'app/mainframe/queries/profile'

import Button from 'app/mainframe/components/Button/Button'

const FollowButton = ({
  profile,
  className = 'w-[150px] my-4',
  unstyled = false,
  followText = 'Follow'
}) => {
  const { following, username } = profile

  const { mutate, isLoading } = useFollow(username)
  const [isFollowing, setIsFollowing] = useState(following)

  const onClick = e => {
    e.preventDefault()
    setIsFollowing(!isFollowing)
    mutate(isFollowing)
  }

  useEffect(() => setIsFollowing(following), [following])

  return (
    <RequireAuth>
      <Button
        onClick={onClick}
        size={unstyled ? null : 'small'}
        unstyled={unstyled}
        disabled={isLoading}
        className={className}
        type={isFollowing ? 'primary' : 'default'}
      >
        {isFollowing ? 'Following' : followText}
      </Button>
    </RequireAuth>
  )
}

export default FollowButton
