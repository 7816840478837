import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { BrowserRouter } from 'app/mainframe/context/history'
import { AppProviders } from 'app/mainframe/context/app'
import Routes from 'app/mainframe/Routes'

import { AiOutlineLoading } from 'react-icons/ai'

import 'shared/assets/styles/global.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://eab282d9a9b4435ebe899b9984e50fec@o197336.ingest.sentry.io/5726674',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

const Mainframe = () => {
  return (
    <Suspense
      fallback={
        <div className='flex h-screen w-screen items-center justify-center'>
          <AiOutlineLoading className='flex animate-spin items-center justify-center text-2xl leading-none text-gray-50' />
        </div>
      }
    >
      <BrowserRouter>
        <AppProviders>
          <Routes />
        </AppProviders>
      </BrowserRouter>
    </Suspense>
  )
}

export default Mainframe
