import React, { createContext, useContext } from 'react'
import mixpanel from 'mixpanel-browser'

let isInit = false

const mixpanelDev = {
  track: (name, properties) => {
    console.log('%cMixpanel:', 'color: #4f44e0; font-weight: 700;', {
      name,
      properties
    })
  }
}

const MixpanelContext = createContext(null)

const MixpanelProvider = ({ children, token = process.env.MIXPANEL_TOKEN }) => {
  if (!!token) {
    mixpanel.init(token)
    isInit = true
  }

  return (
    <MixpanelContext.Provider
      value={{ mixpanel: isInit ? mixpanel : mixpanelDev }}
    >
      {children}
    </MixpanelContext.Provider>
  )
}

const useMixpanel = () => {
  const context = useContext(MixpanelContext)
  if (context === undefined) {
    throw new Error(`useMixpanel must be used within MixpanelProvider`)
  }
  return context
}

export { useMixpanel, MixpanelProvider }
