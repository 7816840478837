import React from 'react'
import { Link } from 'react-router-dom'

import Menu from 'app/mainframe/components/Menu'

import { FaTwitter, FaInstagram, FaGithub } from 'react-icons/fa'

import './Footer.css'

const menu = [
  { title: 'About', to: '/about' },
  { title: 'Features', to: '/features' },
  { title: 'Changelog', to: '/changelog' },
  { title: "We're Hiring", to: '/readme' }
]

const Footer = () => {
  return (
    <div className='mx-auto flex max-w-screen-2xl flex-col-reverse border-t border-gray-300 px-8 py-6 dark:border-gray-700 sm:border-none sm:py-10 md:flex-col'>
      <div className='flex flex-row items-center justify-between'>
        <Link to='/'>
          <img
            className='w-8'
            src='https://ik.imagekit.io/analogue/analogue_icon_9w67oOjFj.png?tr=h-80'
            alt='Analogue Icon'
          />
        </Link>

        <div className='flex space-x-8'>
          <a
            className='text-lg text-gray-200 hover:text-gold-500'
            href='https://instagram.com/analogue.app'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaInstagram />
          </a>
          <a
            className='text-lg text-gray-200 hover:text-gold-500'
            href='https://twitter.com/analogue_app'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaTwitter />
          </a>
          <a
            className='text-lg text-gray-200 hover:text-gold-500'
            href='https://github.com/analogueapp'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaGithub />
          </a>
        </div>
      </div>
      <div className='mt-4 flex flex-col-reverse items-center justify-between md:flex-row'>
        <p className='my-10 text-sm text-gray-500 md:my-0'>
          Analogue — Designed in California.
        </p>

        <div className='flex flex-col items-center justify-center space-y-2 md:flex-row md:space-x-8 md:space-y-0'>
          {menu.map(link => (
            <Link
              key={link.to}
              className='text-sm text-gray-500 hover:text-gold-500'
              to={link.to}
            >
              {link.title}
            </Link>
          ))}
          <a
            className='text-sm text-gray-500 hover:text-gold-500'
            href='https://docs.analogue.com/docs/api'
            target='_blank'
            rel='noopener noreferrer'
          >
            API
          </a>
          <Menu
            position='top'
            className='w-56'
            menu={[
              {
                items: [
                  <Link to='/terms'>Terms of Service</Link>,
                  <Link to='/community'>Community Guidelines</Link>,
                  <Link to='/privacy'>Privacy Policy</Link>
                ]
              }
            ]}
          >
            <span className='cursor-pointer text-sm text-gray-500 hover:text-gold-500'>
              Policies
            </span>
          </Menu>
          <a
            className='text-sm text-gray-500 hover:text-gold-500'
            href='mailto:team@analogue.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
