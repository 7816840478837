import { useInfiniteQuery, useQueryClient } from 'react-query'

const useInfiniteQueryWithInitialData = (
  queryKey,
  initialDataQueryKey,
  apiCall,
  options = {}
) => {
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = 0 }) => apiCall({ page: pageParam }),
    {
      initialData: () => {
        const queryClient = useQueryClient()
        const queryCacheData =
          queryClient.getQueryState(initialDataQueryKey)?.data
        if (queryCacheData) {
          return {
            pageParams: [undefined, 1],
            pages: [queryCacheData]
          } as any
        }
      },
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length !== 0 ? allPages.length : undefined,
      ...options
    }
  )
}

export { useInfiniteQueryWithInitialData }
