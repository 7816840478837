/* eslint-disable camelcase */
import React from 'react'
import clsx from 'clsx'
import placeholder_1x1 from 'shared/assets/img/placeholders/placeholder_1x1.jpg'
import placeholder_2x3 from 'shared/assets/img/placeholders/placeholder_2x3.jpg'
import placeholder_16x9 from 'shared/assets/img/placeholders/placeholder_16x9.jpg'

const ratioPlaceholders = {
  '1x1': placeholder_1x1,
  '2x3': placeholder_2x3,
  '16x9': placeholder_16x9
}

interface ImageProps {
  src?: string
  alt?: string
  ratio?: '1x1' | '2x3' | '16x9'
  className?: string
}

const Image = ({ src, alt, ratio = '1x1', className }: ImageProps) => (
  <img
    alt={alt}
    src={src || ratioPlaceholders[ratio]}
    className={clsx(['select-none', className])}
  />
)

export default Image
