/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import api from 'app/mainframe/api'
import { useAuth } from 'app/mainframe/context/auth'

import {
  FormItemEmail,
  FormItemName,
  FormItemUsername,
  FormItemPassword
} from 'app/mainframe/components/Auth/FormItems'
import SocialAuth from 'app/mainframe/components/Auth/SocialAuth'
import ErrorMessage from 'app/mainframe/components/ErrorMessage/ErrorMessage'
import Button from 'app/mainframe/components/Button/Button'

import { Form, Input } from 'antd'
import { BsArrowLeft } from 'react-icons/bs'
import { FiSend, FiCheckCircle } from 'react-icons/fi'

const SocialButtons = ({ text }) => {
  return (
    <div className='mt-8 flex flex-col border-t border-gray-666 pt-8'>
      <SocialAuth provider='google' text={text} />
    </div>
  )
}

const FormFooter = ({ isLoading, error, signup = false }) => {
  return (
    <>
      <div className='mt-10 flex flex-row items-center justify-between'>
        <Button htmlType='submit' className='w-full' loading={isLoading}>
          {signup ? 'Join us' : 'Log in'}
        </Button>
      </div>

      {error && <ErrorMessage error={error} />}
    </>
  )
}

const LoginForm = () => {
  const { login, isLoading, error } = useAuth()

  return (
    <>
      <Form onFinish={login} validateTrigger='onFinish'>
        <FormItemEmail />
        <FormItemPassword forgot />
        <FormFooter isLoading={isLoading} error={error} />
      </Form>
      <SocialButtons text='Log in' />
    </>
  )
}

const RequestAccessForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const requestAccess = email => {
    setIsLoading(true)
    api.Auth.requestAccess(email).then(
      () => {
        setIsLoading(false)
        setSuccess(true)
      },
      err => {
        setIsLoading(false)
        setError(err.message)
      }
    )
  }
  if (success) {
    return (
      <h2 className='flex flex-row items-center justify-center space-x-3 text-center text-xl text-gold-500'>
        <FiCheckCircle /> <span>You&apos;re on the waitlist</span>
      </h2>
    )
  }

  return (
    <Form
      onFinish={({ email }) => {
        requestAccess(email)
      }}
      validateTrigger='onFinish'
    >
      <div className='relative flex flex-row items-center'>
        <Form.Item
          name='email'
          className='mb-0 w-full'
          rules={[
            { required: true, message: 'Enter your email' },
            { type: 'email', message: 'Not a valid email' }
          ]}
        >
          <Input
            className='border-gray-100 py-2.5 pr-24 text-lg placeholder:text-gray-200 hover:border-gold-500 active:border-gold-500'
            placeholder='Join the email list'
          />
        </Form.Item>
        <Button
          htmlType='submit'
          size='small'
          loading={isLoading}
          className='absolute right-1.5 top-2'
        >
          Submit
        </Button>
      </div>
      {error && (
        <p className='text absolute mt-2 text-sm text-red-600'>{error}</p>
      )}
    </Form>
  )
}

const SignupForm = () => {
  const { signup, isLoading, error } = useAuth()

  return (
    <>
      <Form
        onFinish={values => {
          const { email, name, username, password } = values
          signup({ email, name, username, password })
        }}
        validateTrigger='onFinish'
      >
        <FormItemEmail />
        <FormItemName />
        <FormItemUsername />
        <FormItemPassword />
        <FormFooter isLoading={isLoading} error={error} signup />
      </Form>
      <SocialButtons text='Sign up' />
    </>
  )
}

const ForgotPasswordForm = () => {
  const { forgotPassword, isLoading, error } = useAuth()
  const [showConfirmation, setShowConfirmation] = useState(false)

  return showConfirmation && !error ? (
    <div className='mx-auto flex h-full max-w-[435px] flex-col items-center justify-center'>
      <div className='flex h-14 w-14 items-center justify-center rounded-full bg-gray-50 text-2xl leading-none text-gray-300 dark:bg-gray-650'>
        <FiSend />
      </div>
      <h1 className='mt-4 mb-2 text-2xl md:text-3xl'>Confirmation sent</h1>
      <p className='text-center text-lg text-gray-300'>
        Check your email to reset your password.
      </p>
    </div>
  ) : error ? (
    <ErrorMessage error={error} />
  ) : (
    <>
      <h1 className='mb-8 text-2xl md:text-3xl'>Forgot password</h1>
      <Form
        onFinish={values => {
          forgotPassword(values)
          setShowConfirmation(true)
        }}
        validateTrigger='onFinish'
      >
        <FormItemEmail />
        <Button htmlType='submit' loading={isLoading} className='w-full'>
          Submit
        </Button>
      </Form>
      <div className='mt-5 flex flex-row items-center space-x-2 text-lg text-gray-300'>
        <BsArrowLeft />
        <Link
          to='/login'
          className='text-sm uppercase tracking-widest text-gray-300 hover:text-gold-500'
        >
          Return to login
        </Link>
      </div>
    </>
  )
}

export { RequestAccessForm, SignupForm, LoginForm, ForgotPasswordForm }
