import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

const AuthWindow = ({
  url = '',
  name = '',
  title = '',
  features = { width: 640, height: 600 } as any,
  onBlock = null,
  onOpen = null,
  onUnload = null,
  center = 'parent',
  children = null
}) => {
  const container = document.createElement('div')
  let _window = null
  let windowCheckerInterval = null
  let released = false

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    openChild()
    setMounted(true)
    return () => {
      if (_window) _window.close()
    }
  }, [])

  /**
   * Create the new window when component mounts
   */
  const openChild = () => {
    // Prepare position of the new window to be centered against the 'parent' window or 'screen'.
    if (features.width === undefined || features.height === undefined) {
      console.warn(
        'width and height window features must be present when a center prop is provided'
      )
    } else if (center === 'parent') {
      features.left =
        window.top.outerWidth / 2 + window.top.screenX - features.width / 2
      features.top =
        window.top.outerHeight / 2 + window.top.screenY - features.height / 2
    } else if (center === 'screen') {
      const windowScreen = window.screen as any
      const screenLeft =
        window.screenLeft !== undefined ? window.screenLeft : windowScreen.left
      const screenTop =
        window.screenTop !== undefined ? window.screenTop : windowScreen.top

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height

      features.left = width / 2 - features.width / 2 + screenLeft
      features.top = height / 2 - features.height / 2 + screenTop
    }

    // Open a new window.
    _window = window.open(url, name, toWindowFeatures(features))

    // When a new window use content from a cross-origin there's no way we can attach event
    // to it. Therefore, we need to detect in a interval when the new window was destroyed
    // or was closed.
    windowCheckerInterval = setInterval(() => {
      if (!_window || _window.closed) release()
    }, 50)

    // Check if the new window was succesfully opened.
    if (_window) {
      _window.document.title = title
      _window.document.body.appendChild(container)

      if (typeof onOpen === 'function') {
        onOpen(_window)
      }

      // Release anything bound to this component before the new window unload.
      _window.addEventListener('ononload', () => release())
    } else {
      // Handle error on opening of new window.
      if (typeof onBlock === 'function') {
        onBlock(null)
      } else {
        console.warn('A new window could not be opened. Maybe it was blocked.')
      }
    }
  }

  const release = () => {
    // This method can only be called once.
    if (released) {
      return
    }
    released = true

    // Remove checker interval.
    clearInterval(windowCheckerInterval)

    // Call any function bound to the `onUnload` prop.
    if (typeof onUnload === 'function') {
      onUnload(null)
    }
  }

  if (mounted) return createPortal(children, container)
  return null
}

export default AuthWindow

/**
 * Convert features props to window features format (name=value,other=value).
 * @param {Object} obj
 * @return {String}
 * @private
 */
function toWindowFeatures(obj) {
  return Object.keys(obj)
    .reduce((features, name) => {
      const value = obj[name]
      if (typeof value === 'boolean') {
        features.push(`${name}=${value ? 'yes' : 'no'}`)
      } else {
        features.push(`${name}=${value}`)
      }
      return features
    }, [])
    .join(',')
}
