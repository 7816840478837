import React, { Fragment } from 'react'
import clsx from 'clsx'
import NiceModal from '@ebay/nice-modal-react'
import InfiniteScroll from 'react-infinite-scroller'

import ContentPreview from 'app/mainframe/components/Content/ContentPreview/ContentPreview'
import PrimerPreview from 'app/mainframe/components/Primer/PrimerPreview/PrimerPreview'
import LogPreview from 'app/mainframe/components/Log/LogPreview/LogPreview'

import { useAppConfig } from 'app/mainframe/context/appConfig'

import { AiOutlineLoading } from 'react-icons/ai'

const InfiniteGrid = ({
  query,
  type,
  content,
  gridClassName,
  isCarousel = false,
  setCollectiveContent = null,
  collectiveLogs = null
}: {
  query: any
  type: 'content' | 'primer' | 'log'
  content?: any
  gridClassName?: string
  isCarousel?: boolean
  setCollectiveContent?: any // resets query in collective logs
  collectiveLogs?: any
}) => {
  const { data, error, isError, fetchNextPage, hasNextPage } = query
  const { embed } = useAppConfig()

  const onContentClick = key => e => {
    e.preventDefault()
    const dataFlattened = [].concat.apply([], data.pages)
    NiceModal.show('log-carousel-modal', {
      logs: dataFlattened,
      center: key
    })
  }

  const onCollectiveContentClick = content => e => {
    e.preventDefault()
    setCollectiveContent(content)
    NiceModal.show('log-carousel-modal', {
      logs: collectiveLogs,
      content
    })
  }

  if (isError) {
    return <span>Error: {error}</span>
  }

  return (
    <InfiniteScroll
      loadMore={fetchNextPage}
      hasMore={hasNextPage}
      loader={
        <AiOutlineLoading className='my-12 w-full animate-spin text-xl text-gray-300' />
      }
      className='overflow-clip pb-6'
    >
      <div
        className={clsx([
          'grid items-baseline justify-items-center py-6',
          gridClassName,
          {
            'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3':
              (type === 'primer' || type === 'log') && !gridClassName,
            'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8':
              type === 'content' && !gridClassName
          }
        ])}
      >
        {data &&
          data.pages.map((page, index) => (
            <Fragment key={index}>
              {type === 'content' &&
                page.map((content, contentIndex) => (
                  <ContentPreview
                    key={content.id}
                    logId={content.id}
                    content={content.content ? content.content : content}
                    className='pb-12'
                    size={embed ? 'large' : 'default'}
                    onClick={
                      setCollectiveContent
                        ? onCollectiveContentClick(content)
                        : isCarousel
                        ? onContentClick(index * 8 + contentIndex)
                        : null
                    }
                  />
                ))}
              {type === 'primer' &&
                page.map(primer => (
                  <PrimerPreview
                    key={primer.id}
                    primer={primer}
                    className='mb-12 w-[308px]'
                  />
                ))}
              {type === 'log' &&
                page.map(item => (
                  <LogPreview
                    key={item.id}
                    log={item}
                    content={content || item.content}
                    className='mb-12'
                  />
                ))}
            </Fragment>
          ))}
      </div>
    </InfiniteScroll>
  )
}

export default InfiniteGrid
