import React, { useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from 'app/mainframe/components/Modals/Modal'
import LogCarousel from 'app/mainframe/components/Log/LogCarousel/LogCarousel'

import { AiOutlineLoading } from 'react-icons/ai'

const LogCarouselModal = NiceModal.create(
  ({ logs = null, content = null, center = 0, hideContent = false }) => {
    const modal = useModal()
    const isMobile = window.matchMedia('(max-width: 768px)').matches

    return (
      <Modal
        visible={modal.visible}
        onCancel={modal.hide}
        maskOnly
        width={null}
        className='flex w-full flex-col justify-center'
        padding={isMobile ? 'p-0' : null}
      >
        {logs ? (
          <LogCarousel
            logs={logs}
            center={center}
            content={content}
            hideContent={hideContent}
          />
        ) : (
          <div className='flex items-center justify-center w-screen h-screen'>
            <AiOutlineLoading className='flex items-center justify-center text-gray-50 text-2xl leading-none animate-spin' />
          </div>
        )}
      </Modal>
    )
  }
)

export default LogCarouselModal
