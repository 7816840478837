import React from 'react'
import clsx from 'clsx'

import {
  useAddPrimerLog,
  useRemovePrimerLog
} from 'app/mainframe/queries/primer'

import Image from 'app/mainframe/components/Image'

import { AiOutlineLoading } from 'react-icons/ai'
import { FiCircle, FiCheckCircle } from 'react-icons/fi'

import { useMixpanel } from 'app/mainframe/context/mixpanel'

const PrimerListItem = ({ primer, log, content }) => {
  const { mixpanel } = useMixpanel()
  const { mutate: removePrimerLog, isLoading: isLoadingRemove } =
    useRemovePrimerLog(primer.slug)
  const { mutate: addPrimerLog, isLoading: isLoadingAdd } = useAddPrimerLog(
    primer.slug
  )

  const onClick = () => {
    if (primer.selected) {
      removePrimerLog({ slug: primer.slug, logId: log.id })
    } else {
      mixpanel.track('Log Collected', {
        id: log.id,
        primer: primer.id,
        content: content.id
      })
      addPrimerLog({
        slug: primer.slug,
        contentId: content.id,
        title: content.title
      })
    }
  }
  const isLoading = isLoadingRemove || isLoadingAdd

  return (
    <div
      onClick={isLoading ? null : onClick}
      className={clsx(
        'flex items-center mb-3 p-2.5 hover:bg-gray-650 bg-gray-700 rounded-md select-none',
        isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
    >
      <div className='flex-shrink-0 mr-3 w-8 h-8'>
        <Image
          className='w-full h-full rounded object-cover'
          src={primer.image}
          alt={primer.title}
        />
      </div>
      <p className='single-line mr-4 text-base'>{primer.title}</p>
      {isLoading ? (
        <AiOutlineLoading className='text-[21px] flex-shrink-0 ml-auto text-gray-500 animate-spin' />
      ) : primer.selected ? (
        <FiCheckCircle
          className='flex-shrink-0 ml-auto text-gold-500'
          size='21px'
        />
      ) : (
        <FiCircle className='flex-shrink-0 ml-auto text-gray-500' size='21px' />
      )}
    </div>
  )
}

export default PrimerListItem
