/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import LinkWrapper from 'app/mainframe/components/LinkWrapper'

import { useAppConfig } from 'app/mainframe/context/appConfig'
import { useIsOverflow } from 'app/mainframe/hooks/useIsOverflow'

import ContentPreviewBox from 'app/mainframe/components/Content/ContentPreviewBox/ContentPreviewBox'
import LogButton from 'app/mainframe/components/Log/Buttons/LogButton'
import SaveButton from 'app/mainframe/components/Log/Buttons/SaveButton'
import Button from 'app/mainframe/components/Button/Button'
import clsx from 'clsx'
import { useLogCarouselContext } from 'app/mainframe/context/logCarousel'

const ContentHeader = ({ content, className = null }) => {
  const { embed } = useAppConfig()
  const [overflowRef, isOverflow] = useIsOverflow()
  const { isResized, setIsResized } = useLogCarouselContext()
  const [showOverflow, setShowOverflow] = useState(false)
  const { title, formSlug, slug, creatorList, medium, description } = content

  const toggleDescription = () => {
    setShowOverflow(!showOverflow)
    setIsResized(!isResized)
  }

  const contentBody = `${description ? `<br /><br />${description}` : ''}`

  return (
    <div
      className={clsx([
        'mx-auto flex w-full max-w-screen-sm flex-col justify-between sm:flex-row',
        className
      ])}
    >
      <div className='mx-auto flex h-full w-full flex-col items-center justify-center sm:mx-0 sm:h-[250px] sm:w-[250px] sm:justify-start'>
        <ContentPreviewBox content={content} />
      </div>

      <div className='relative mt-5 w-full sm:mt-0 sm:w-1/2'>
        <LinkWrapper
          to={`/${formSlug}/${slug}`}
          className='block text-2xl text-gray-900 dark:text-gray-50'
        >
          {medium === 'book' ? title.split(':')[0].split('(')[0] : title}
        </LinkWrapper>
        {creatorList && creatorList.length > 0 && (
          <>
            {creatorList.map((creator, index) => (
              <LinkWrapper
                key={creator.username}
                to={`/@${creator.username}`}
                className='mt-1 text-lg text-gray-500 hover:text-gold-500 dark:text-gray-100'
              >
                {`${creator.name}${creatorList.length - index > 1 ? ', ' : ''}`}
              </LinkWrapper>
            ))}
          </>
        )}

        <div className='my-6 flex flex-col space-y-2'>
          {embed ? (
            <LinkWrapper to={`/${formSlug}/${slug}`}>
              <Button type='default' className='w-full'>
                View on Analogue
              </Button>
            </LinkWrapper>
          ) : (
            <>
              <LogButton content={content} />
              <SaveButton content={content} />
            </>
          )}
        </div>
        {(description) && (
          <button
            type='button'
            className='text-left'
            onClick={toggleDescription}
          >
            <div
              ref={overflowRef}
              className={clsx([
                'mt-1 text-base text-gray-300',
                showOverflow ? 'line-clamp-none' : 'line-clamp-2',
                { 'cursor-pointer': isOverflow }
              ])}
              dangerouslySetInnerHTML={{
                __html: contentBody
              }}
            />
            {isOverflow && (
              <div className='mt-1 cursor-pointer pt-2 text-xs uppercase tracking-wider text-gray-600 transition duration-200 hover:text-gold-500 dark:text-gray-100'>
                Read more
              </div>
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default ContentHeader
