import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from 'app/mainframe/components/Modals/Modal'

const InfoModal = NiceModal.create(({ title, body }) => {
  const modal = useModal()

  return (
    <Modal centered visible={modal.visible} onCancel={modal.hide}>
      <>
        {title && <h1 className='text-center'>{title}</h1>}
        {body}
      </>
    </Modal>
  )
})

export default InfoModal
