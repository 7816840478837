import { useQuery, useQueryClient, useMutation } from 'react-query'
import api from 'app/mainframe/api'

import { useInfiniteQueryWithInitialData } from 'app/mainframe/queries/utils'

const useUnreadNotifications = () =>
  useQuery(['notifications', 'unread'], api.Notifications.unread)

const useNotifications = () =>
  useQuery(['notifications'], api.Notifications.all)
// const useInfiniteUserNotifications = () =>
//   useInfiniteQueryWithInitialData(
//     ['notifications'],
//     ['notifications'],
//     api.Notifications.all
//   )

const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Notifications.read, {
    onSuccess: data => {
      queryClient.invalidateQueries(['notifications', 'unread'])
    }
  })
}

export { useNotifications, useUnreadNotifications, useMarkNotificationAsRead }
