import React from 'react'
import Share from 'app/mainframe/components/Share'
import LikeButton from 'app/mainframe/components/LikeButton'
import LogStatus from 'app/mainframe/components/Log/LogStatus'

const LogFooter = ({ content, log }) => {
  return (
    <div className='sticky bottom-0 flex items-center justify-between border-t border-gray-200 bg-white px-2 py-4 dark:border-gray-700 dark:bg-gray-800 sm:px-5'>
      <LogStatus log={log} content={content} />
      <div className='flex items-center'>
        <LikeButton className='mr-3 text-lg text-gray-300' log={log} />
        {content && log && <Share content={content} log={log} />}
      </div>
    </div>
  )
}

export default LogFooter
