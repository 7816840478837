import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { MixpanelProvider } from 'app/mainframe/context/mixpanel'
import { AppConfigProvider } from 'app/mainframe/context/appConfig'
import { ThemeProvider } from 'app/mainframe/context/theme'
import { AuthProvider } from 'app/mainframe/context/auth'
import { Analytics } from 'app/mainframe/Analytics'
import { ModalsProvider } from 'app/mainframe/modals/ModalsProvider'
import { LogCarouselProvider } from 'app/mainframe/context/logCarousel'
import { PlayerProvider } from 'app/mainframe/context/player'

const queryClient = new QueryClient()

export const EmbedProviders = ({ children, initialTheme = null }) => (
  <QueryClientProvider client={queryClient}>
    <AppConfigProvider embed>
      <ThemeProvider initialTheme={initialTheme}>
        <AuthProvider>
          <MixpanelProvider>
            <Analytics>
              <PlayerProvider>
                <LogCarouselProvider>
                  <ModalsProvider>{children}</ModalsProvider>
                </LogCarouselProvider>
              </PlayerProvider>
            </Analytics>
          </MixpanelProvider>
        </AuthProvider>
      </ThemeProvider>
    </AppConfigProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
