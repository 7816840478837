import React, { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Extension } from '@tiptap/core'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import Typography from '@tiptap/extension-typography'
import Highlight from '@tiptap/extension-highlight'
import Dropcursor from '@tiptap/extension-dropcursor'

import { useAuth } from 'app/mainframe/context/auth'
import { useMixpanel } from 'app/mainframe/context/mixpanel'
import { useEditLog } from 'app/mainframe/queries/log'

import PopConfirm from 'app/mainframe/components/Modals/PopConfirm'
import Button from 'app/mainframe/components/Button/Button'

import { AiOutlineLoading } from 'react-icons/ai'
import FloatingMenu from './plugins/FloatingMenu'
import BubbleMenu from './plugins/BubbleMenu'
import Image from './extensions/image'

import './Editor.css'

const Editor = ({
  log,
  content,
  autoFocus,
  editable = true,
  isOwner,
  onCancel
}: {
  log: any
  content: any
  autoFocus?: boolean
  editable?: boolean
  isOwner?: boolean
  onCancel?: () => void
}) => {
  const { currentUser } = useAuth()
  const { mixpanel } = useMixpanel()

  const hasNote = log && log.note && Object.keys(log.note).length

  const upload = uploadImage(log, currentUser)

  const editor = useEditor({
    editable: !!(isOwner && editable),
    autofocus: autoFocus ? 'end' : false,
    content: log?.note ? log.note : {},
    extensions: [
      Placeholder.configure({
        placeholder: 'Log your thoughts…'
      }),
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3]
        }
      }),
      Typography,
      Highlight,
      Image(upload),
      Link.configure({
        openOnClick: !isOwner
      }).extend({
        addKeyboardShortcuts() {
          return {
            'Mod-k': () =>
              this.editor
                .chain()
                .focus()
                .setLink({ href: this.editor.getAttributes('link').href })
                .run()
          }
        }
      }),
      Dropcursor.configure({
        color: '#c7ac75',
        width: 1
      }),
      Extension.create({
        addKeyboardShortcuts() {
          return {
            'Mod-Enter': () => {
              onSubmit(this.editor)
              return true
            }
          }
        }
      })
    ]
  })

  useEffect(() => {
    editor?.commands?.setContent(log.note)
  }, [log.note])

  const { mutateAsync: editLog, isLoading: isLoadingEditor } = useEditLog(
    content && content.slug,
    currentUser && currentUser.username
  )
  const onSubmit = (currentEditor = null) => {
    const e = currentEditor || editor
    if (e.isEmpty) {
      toast.error('Please add a note before submitting')
    } else {
      // Or use: https://www.tiptap.dev/api/editor#methods
      // editor.getJSON()
      editLog({
        ...log,
        note: e.state.doc.toJSON(),
        // https://prosemirror.net/docs/ref/#model.Node.textBetween
        // Last argument is the placeholder for a leaf node like image ('\nIMAGE\n')
        noteText: e.state.doc.textBetween(0, e.state.doc.content.size, ' ', '')
      }).then(() => {
        if (onCancel) onCancel()
      })
      mixpanel.track(`Note ${hasNote ? 'Edited' : 'Added'}`, {
        id: log.id,
        content: content.id
      })
    }
  }

  const removeNote = () => {
    editLog({
      ...log,
      note: {},
      // https://prosemirror.net/docs/ref/#model.Node.textBetween
      // Last argument is the placeholder for a leaf node like image ('\nIMAGE\n')
      noteText: ''
    }).then(response => {
      if (!response.error) {
        mixpanel.track('Note Deleted', {
          id: log.id,
          content: content.id
        })
        if (onCancel) onCancel()
      }
    })
  }

  return (
    <div className='group'>
      {editor && isOwner && editable && (
        <>
          <BubbleMenu editor={editor} />
          <FloatingMenu editor={editor} upload={upload} />
        </>
      )}
      <EditorContent editor={editor} />
      {isLoadingEditor && (
        <AiOutlineLoading className='mr-auto h-full animate-spin text-sm leading-none' />
      )}

      {isOwner && editable && (
        <div className='sticky bottom-[57px] -mx-1 mt-2 flex items-center bg-white py-3 dark:bg-gray-800'>
          {!!hasNote && (
            <PopConfirm text='Delete note?' onConfirm={removeNote}>
              <p className='cursor-pointer text-xs uppercase tracking-widest text-gray-300 opacity-100 transition group-hover:opacity-100 sm:opacity-0'>
                Delete
              </p>
            </PopConfirm>
          )}
          <Button
            size='small'
            className='ml-auto'
            type='text'
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button size='small' className='ml-2' onClick={() => onSubmit()}>
            {hasNote ? 'Save' : 'Post'}
          </Button>
        </div>
      )}
    </div>
  )
}

const uploadImage = (log, currentUser) => file => {
  const uploadUrl = `/api/logs/${log.id}/upload`
  const maxFileSize = 10485760 // 10MB

  if (file.size === 0) {
    toast.error('The image you added looks empty.')
    return
  }
  if (file.size > maxFileSize) {
    toast.error('Maximum image size is 10MB.')
    return
  }

  const form = new FormData()
  form.append('Content-Type', file.type)
  form.append('file', file)

  const config = {
    method: 'POST',
    headers: {
      Authorization: `Token ${currentUser.token}`
    },
    body: form
  }

  return window.fetch(uploadUrl, config).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      toast.error('Upload failed. Try to reload the page.')
      return null
    }
    return data
  })
}

export default Editor
