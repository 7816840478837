import React, { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'
import { Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { useAuth } from 'app/mainframe/context/auth'
import RequireAuth from 'app/mainframe/components/Auth/RequireAuth'
import Image from 'app/mainframe/components/Image'
import Button from 'app/mainframe/components/Button/Button'

import { AiOutlineSearch } from 'react-icons/ai'

const sections = [
  { title: 'Browse All', slug: 'culture' },
  {
    title: 'Art & Culture',
    slug: 'culture'
  },
  {
    title: 'Science & Technology',
    slug: 'culture'
  },
  {
    title: 'Philosophy & Mind',
    slug: 'culture'
  }
]

const Header2 = () => {
  const { currentUser } = useAuth()

  const location = useLocation()
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = throttle(
    () => setIsScrolled(window.pageYOffset > 0),
    250
  )

  const isHome = location.pathname === '/libraries'
  return (
    <header
      className={clsx([
        'sticky z-50 border-b',
        {
          'border-gray-700 bg-gray-850': showMobileNav,
          'border-gray-700 backdrop-blur backdrop-brightness-75 transition duration-300':
            isScrolled && !showMobileNav,
          'border-transparent bg-transparent transition duration-300':
            !isScrolled && !showMobileNav,
          'top-[-60px]': isHome
        }
      ])}
    >
      <div className='max-w-6xl px-3 mx-auto'>
        <div className='grid items-center grid-cols-3 gap-3 py-5'>
          <div className='flex items-center w-full text-base text-gray-400 duration-200 cursor-pointer justify-self-start hover:text-gray-100'>
            <AiOutlineSearch className='mr-4 text-2xl text-gray-200' />
            <span className='hidden sm:block'>Search</span>
          </div>
          <Link
            className='flex-shrink-0 w-40 justify-self-center md:w-48'
            to='/libraries'
          >
            <img
              src='https://ik.imagekit.io/analogue/analogue_logo_WeDM8Tame.png?tr=h-63'
              alt='Analogue Logo'
            />
          </Link>
          <div className='flex items-center justify-self-end'>
            {currentUser ? (
              <Link to={`/@${currentUser.username}`}>
                <Image
                  className='object-cover w-8 h-8 rounded-full cursor-pointer'
                  src={currentUser.image}
                  alt={currentUser.username}
                />
              </Link>
            ) : (
              <RequireAuth>
                <Button
                  unstyled
                  className='hidden bg-gray-900 rounded-none text-gold-500 hover:border-gold-500 lg:block'
                >
                  Sign in
                </Button>
              </RequireAuth>
            )}

            <svg
              className='flex-shrink-0 block cursor-pointer lg:hidden'
              onClick={() => setShowMobileNav(!showMobileNav)}
              width='24'
              height='24'
              fill='#aaa'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H4C3.44772 6 3 5.55228 3 5ZM3 12C3 11.4477 3.44772 11 4 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12ZM3 19C3 18.4477 3.44772 18 4 18H16C16.5523 18 17 18.4477 17 19C17 19.5523 16.5523 20 16 20H4C3.44772 20 3 19.5523 3 19Z'
              ></path>
            </svg>
          </div>
        </div>

        {isHome && (
          <div className='items-center hidden grid-cols-4 pt-2 pb-6 mx-auto justify-items-start md:grid'>
            {sections.map(({ title, slug }) => (
              <Link
                key={slug}
                to={`/${slug}`}
                className='text-sm font-thin tracking-widest text-gray-100 uppercase last:justify-self-end hover:text-gold-500 lg:text-base'
              >
                {title}
              </Link>
            ))}
          </div>
        )}

        {showMobileNav && (
          <div className='flex flex-col lg:hidden'>
            {!currentUser && (
              <>
                <RequireAuth>
                  <div className='flex w-full px-6'>
                    <Button
                      unstyled
                      className='w-full rounded-none bg-gray-900 py-6 text-base tracking-[4px] text-gold-500 hover:border-gold-500'
                    >
                      Sign in
                    </Button>
                  </div>
                </RequireAuth>
              </>
            )}
            {sections.map(({ slug, title }) => (
              <Link
                key={slug}
                to={`/${slug}`}
                onClick={() => setShowMobileNav(!showMobileNav)}
                className='w-full px-6 py-6 text-base uppercase tracking-[4px] text-gray-100 transition'
              >
                {title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </header>
  )
}

export default Header2
