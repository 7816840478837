import React from 'react'

const VolumeMutedIcon = ({ size }) => {
  return (
    <svg
      viewBox='0 0 16 16'
      width={size}
      height={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 5v6h2.804L8 14V2L2.804 5H0zm7-1.268v8.536L3.072 10H1V6h2.072L7 3.732zm8.623 2.121l-.707-.707-2.147 2.147-2.146-2.147-.707.707L12.062 8l-2.146 2.146.707.707 2.146-2.147 2.147 2.147.707-.707L13.477 8l2.146-2.147z'
        fill='currentColor'
      />
    </svg>
  )
}

const VolumeLowIcon = ({ size }) => {
  return (
    <svg
      viewBox='0 0 16 16'
      width={size}
      height={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.04 5.984l.658-.77q.548.548.858 1.278.31.73.31 1.54 0 .54-.144 1.055-.143.516-.4.957-.259.44-.624.805l-.658-.77q.825-.865.825-2.047 0-1.183-.825-2.048zM0 11.032v-6h2.802l5.198-3v12l-5.198-3H0zm7 1.27v-8.54l-3.929 2.27H1v4h2.071L7 12.302z'
        fill='currentColor'
      />
    </svg>
  )
}

const VolumeNormalIcon = ({ size }) => {
  return (
    <svg
      viewBox='0 0 16 16'
      width={size}
      height={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 11.032v-6h2.802l5.198-3v12l-5.198-3H0zm7 1.27v-8.54l-3.929 2.27H1v4h2.071L7 12.302zm4.464-2.314q.401-.925.401-1.956 0-1.032-.4-1.957-.402-.924-1.124-1.623L11 3.69q.873.834 1.369 1.957.496 1.123.496 2.385 0 1.262-.496 2.385-.496 1.123-1.369 1.956l-.659-.762q.722-.698 1.123-1.623z'
        fill='currentColor'
      />
    </svg>
  )
}

const VolumeFullIcon = ({ size }) => {
  return (
    <svg
      viewBox='0 0 16 16'
      width={size}
      height={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.945 1.379l-.652.763c1.577 1.462 2.57 3.544 2.57 5.858s-.994 4.396-2.57 5.858l.651.763a8.966 8.966 0 00.001-13.242zm-2.272 2.66l-.651.763a4.484 4.484 0 01-.001 6.397l.651.763c1.04-1 1.691-2.404 1.691-3.961s-.65-2.962-1.69-3.962zM0 5v6h2.804L8 14V2L2.804 5H0zm7-1.268v8.536L3.072 10H1V6h2.072L7 3.732z'
        fill='currentColor'
      />
    </svg>
  )
}

const PlaybackSpeed100 = () => (
  <svg
    aria-hidden='true'
    viewBox='0 0 16 16'
    fill='none'
    stroke='white'
    strokeWidth='1'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='w-5 h-5 duration-200 stroke-gray-200 hover:stroke-white active:stroke-white'
  >
    <path
      d='M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1Z'
      strokeWidth='1'
    ></path>
    <path d='M3.75 7.25L5.25 5.77539V11.25'></path>
    <path d='M8.75 7.75L11.25 10.25'></path>
    <path d='M11.25 7.75L8.75 10.25'></path>
  </svg>
)

const PlaybackSpeed150 = () => (
  <svg
    aria-hidden='true'
    viewBox='0 0 16 16'
    fill='none'
    stroke='white'
    strokeWidth='1'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='w-5 h-5 duration-200 stroke-gray-200 hover:stroke-white active:stroke-white'
  >
    <path
      d='M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1Z'
      stroke-width='1'
    ></path>
    <path d='M2.75 7.25L4.25 5.77539V11.25'></path>
    <path
      d='M7.5 11C7.5 11.2761 7.27614 11.5 7 11.5C6.72386 11.5 6.5 11.2761 6.5 11C6.5 10.7239 6.72386 10.5 7 10.5C7.27614 10.5 7.5 10.7239 7.5 11Z'
      strokeWidth='1'
    ></path>
    <path d='M12.25 5.75H9.75V8.25H10.75C11.5784 8.25 12.25 8.92157 12.25 9.75V9.75C12.25 10.5784 11.5784 11.25 10.75 11.25H9.75'></path>
  </svg>
)

const PlaybackSpeed200 = () => (
  <svg
    aria-hidden='true'
    viewBox='0 0 16 16'
    fill='none'
    stroke='white'
    strokeWidth='1'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='w-5 h-5 duration-200 stroke-gray-200 hover:stroke-white active:stroke-white'
  >
    <path
      d='M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1Z'
      strokeWidth='1'
    ></path>
    <path d='M9.75 8.75L12.25 11.25'></path>
    <path d='M12.25 8.75L9.75 11.25'></path>
    <path d='M3.75 7.25C3.75 7.25 3.90144 5.75 5.63462 5.75C6.1633 5.75 6.5448 5.95936 6.81973 6.25035C7.67157 7.15197 6.97033 8.47328 6.0238 9.28942L3.75 11.25H7.25'></path>
  </svg>
)

const SeekBack = () => (
  <svg
    aria-hidden='true'
    viewBox='0 0 24 24'
    fill='none'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='w-5 h-5 duration-200 stroke-gray-200 hover:stroke-white'
  >
    <path d='M8 5L5 8M5 8L8 11M5 8H13.5C16.5376 8 19 10.4624 19 13.5C19 15.4826 18.148 17.2202 17 18.188'></path>
    <path d='M5 15V19'></path>
    <path d='M8 18V16C8 15.4477 8.44772 15 9 15H10C10.5523 15 11 15.4477 11 16V18C11 18.5523 10.5523 19 10 19H9C8.44772 19 8 18.5523 8 18Z'></path>
  </svg>
)

const SeekForward = () => (
  <svg
    aria-hidden='true'
    viewBox='0 0 24 24'
    fill='none'
    className='w-5 h-5 duration-200 stroke-gray-200 hover:stroke-white'
  >
    <path
      d='M16 5L19 8M19 8L16 11M19 8H10.5C7.46243 8 5 10.4624 5 13.5C5 15.4826 5.85204 17.2202 7 18.188'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M13 15V19'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M16 18V16C16 15.4477 16.4477 15 17 15H18C18.5523 15 19 15.4477 19 16V18C19 18.5523 18.5523 19 18 19H17C16.4477 19 16 18.5523 16 18Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
)

const Icon = ({ name, size = 24 }) => {
  const icons = {
    volumeMuted: VolumeMutedIcon,
    volumeLow: VolumeLowIcon,
    volumeNormal: VolumeNormalIcon,
    volumeFull: VolumeFullIcon,
    seekBack: SeekBack,
    seekForward: SeekForward,
    playBack100: PlaybackSpeed100,
    playBack150: PlaybackSpeed150,
    playBack200: PlaybackSpeed200
  }

  const Component = icons[name]
  return <Component size={size} />
}

export { Icon }
