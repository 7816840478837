import React, { createContext, useContext, useReducer } from 'react'
import logger from 'use-reducer-logger'

const initialState = {
  show: false,
  playing: false,
  current: false,
  controls: false
}

const playerReducer = (state, action) => {
  switch (action.type) {
    case 'PLAY':
      return {
        ...state,
        show: true,
        playing: true,
        current: action.content
      }
    case 'PAUSE':
      return {
        ...state,
        playing: false
      }
    case 'SET CONTROLS':
      return {
        ...state,
        controls: action.controls
      }
    case 'CLOSE':
      return {
        ...state,
        show: false,
        playing: false
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const PlayerStateContext = createContext(null)

const PlayerProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(
    process.env.NODE_ENV === 'development'
      ? logger(playerReducer)
      : playerReducer,
    initialState
  )

  const player = {
    play: content => dispatch({ type: 'PLAY', content }),
    pause: () => dispatch({ type: 'PAUSE' }),
    close: () => dispatch({ type: 'CLOSE' }),
    setControls: controls => dispatch({ type: 'SET CONTROLS', controls })
  }

  return (
    <PlayerStateContext.Provider value={{ ...state, player }}>
      {children}
    </PlayerStateContext.Provider>
  )
}

const usePlayer = () => {
  const context = useContext(PlayerStateContext)
  if (context === undefined) {
    throw new Error('usePlayer must be used within PlayerProvider')
  }
  return context
}

export { PlayerProvider, usePlayer }
