import React from 'react'
import Moment from 'react-moment'
import moment from 'moment'

interface Props {
  date: string // ISO format date string
}

const TimeAgo = ({ date }: Props) => {
  const momentDate = moment(date, moment.ISO_8601)
  const daysDiff = momentDate.diff(Date.now(), 'days')

  // if date is within a week, show time ago
  if (daysDiff * -1 < 6) {
    return (
      <Moment
        ago
        fromNow
        filter={value => {
          value = value
            .replace(/^a few seconds/g, 'just now')
            .replace(/^a /g, '1 ')
            .replace(/^an /g, '1 ')
            .replace(' minute', 'm')
            .replace(' hour', 'h')
            .replace(' day', 'd')
            .replace(' minute', 'm')
            .replace(' month', 'm')
          if (value.charAt(value.length - 1) === 's') {
            value = value.replace('s', '')
          }
          return value
        }}
      >
        {date}
      </Moment>
    )
  }

  // else show full date + year if different year
  return (
    <Moment
      format={`MMM D${momentDate.isSame(Date.now(), 'year') ? '' : ' YYYY'}`}
    >
      {date}
    </Moment>
  )
}

export default TimeAgo
