import React from 'react'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { MixpanelProvider } from 'app/mainframe/context/mixpanel'
import { AppConfigProvider } from 'app/mainframe/context/appConfig'
import { ThemeProvider } from 'app/mainframe/context/theme'
import { AuthProvider } from 'app/mainframe/context/auth'
import { PlayerProvider } from 'app/mainframe/context/player'
import { Analytics } from 'app/mainframe/Analytics'
import { ModalsProvider } from 'app/mainframe/modals/ModalsProvider'
import { LogCarouselProvider } from 'app/mainframe/context/logCarousel'

import ToastConfig from 'app/mainframe/components/Toast/ToastConfig'
import useStream from 'app/mainframe/hooks/useStream'

const queryClient = new QueryClient()

export const AppProviders = ({
  children,
  initialTheme = null
}: {
  children: React.ReactNode
  initialTheme?: string
}) => (
  <QueryClientProvider client={queryClient}>
    <AppConfigProvider>
      <ThemeProvider initialTheme={initialTheme}>
        <AuthProvider>
          <MixpanelProvider>
            <Analytics>
              <StreamNotifications>
                <PlayerProvider>
                  <LogCarouselProvider>
                    <ModalsProvider>
                      {children}
                      <ToastConfig />
                    </ModalsProvider>
                  </LogCarouselProvider>
                </PlayerProvider>
              </StreamNotifications>
            </Analytics>
          </MixpanelProvider>
        </AuthProvider>
      </ThemeProvider>
    </AppConfigProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

const StreamNotifications = ({ children }) => {
  const queryClient = useQueryClient()

  useStream('notification', () => {
    queryClient.invalidateQueries(['notifications'])
    queryClient.invalidateQueries(['notifications', 'unread'])
  })

  return children
}
