import React, { useRef } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from 'app/mainframe/components/Modals/Modal'
import Button from 'app/mainframe/components/Button/Button'

const PopConfirmModal = NiceModal.create(
  ({ text, onConfirm }: { text: string; onConfirm: Function }) => {
    const modal = useModal()
    const cancelButtonRef = useRef()

    return (
      <Modal
        centered
        closable={false}
        width='max-w-xs'
        visible={modal.visible}
        onCancel={modal.hide}
        initialFocus={cancelButtonRef}
      >
        <>
          <p className='pb-6 text-center text-gray-50 text-lg select-none'>
            {text}
          </p>
          <Button
            className='mb-3'
            onClick={() => {
              if (onConfirm) onConfirm()
              modal.hide()
            }}
          >
            Yes
          </Button>
          <Button type='text' onClick={modal.hide} ref={cancelButtonRef}>
            Cancel
          </Button>
        </>
      </Modal>
    )
  }
)

export default PopConfirmModal
