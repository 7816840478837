import { useQuery, useMutation, useQueryClient } from 'react-query'
import api from 'app/mainframe/api'

import { useInfiniteQueryWithInitialData } from 'app/mainframe/queries/utils'

const useProfile = username =>
  useQuery(['profile', username], () => api.Profile.get(username))

const useInfiniteLogs = username =>
  useInfiniteQueryWithInitialData(
    ['profile', username, 'logs', 'all'],
    ['profile', username, 'logs'],
    api.Profile.logs(username)
  )

const usePrimers = username =>
  useQuery(['profile', username, 'primers'], api.Profile.primers(username))

const useInfinitePrimers = username =>
  useInfiniteQueryWithInitialData(
    ['profile', username, 'primers', 'all'],
    ['profile', username, 'primers'],
    api.Profile.primers(username)
  )

const useWorks = username =>
  useQuery(['content', username, 'works'], api.Content.works(username))

const useInfiniteWorks = username =>
  useInfiniteQueryWithInitialData(
    ['profile', username, 'works', 'all'],
    ['profile', username, 'works'],
    api.Content.works(username)
  )

const useFollow = username => {
  const queryClient = useQueryClient()
  return useMutation(
    (following: boolean) =>
      following ? api.Profile.unfollow(username) : api.Profile.follow(username),
    {
      onMutate: async following => {
        await queryClient.cancelQueries(['profile', username])
        // Snapshot the previous value
        const previousProfile = queryClient.getQueryData([
          'profile',
          username
        ]) as any

        // Optimistically update to the new value
        queryClient.setQueryData(['profile', username], {
          ...previousProfile,
          following: !following
        })

        // Return a context object with the snapshotted value
        return { previousProfile }
      },
      onError: (err, newTodo, context: any) => {
        queryClient.setQueryData(['profile', username], context.previousProfile)
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['profile', username])
        queryClient.invalidateQueries(['activity', 'home'])
        queryClient.invalidateQueries(['activity', 'all'])
      }
    }
  )
}

export {
  useFollow,
  useProfile,
  useWorks,
  useInfiniteWorks,
  useInfiniteLogs,
  usePrimers,
  useInfinitePrimers
}
