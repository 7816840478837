import React from 'react'
import LinkWrapper from 'app/mainframe/components/LinkWrapper'
import Button from 'app/mainframe/components/Button/Button'
import { BiMessageSquareEdit } from 'react-icons/bi'
import clsx from 'clsx'

const EmptyNote = ({
  text,
  link,
  className = null
}: {
  text: string
  link?: string
  className?: string
}) => {
  return (
    <div
      className={clsx([
        'flex flex-col items-center justify-center text-center',
        className
      ])}
    >
      <div className='flex h-12 w-12 items-center justify-center rounded-full bg-gray-50 text-2xl text-gray-300 dark:bg-gray-650'>
        <BiMessageSquareEdit />
      </div>
      <h3 className='my-4 text-lg font-light text-gray-600 dark:text-gray-50'>
        {text}
      </h3>
      <LinkWrapper to={link}>
        <Button size='small'>Add your thoughts</Button>
      </LinkWrapper>
    </div>
  )
}

export default EmptyNote
