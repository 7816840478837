import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from 'app/mainframe/components/Modals/Modal'
import { SignupForm } from 'app/mainframe/components/Auth/AuthForms'

const SignupModal = NiceModal.create(() => {
  const modal = useModal()

  return (
    <Modal centered visible={modal.visible} onCancel={modal.hide}>
      <>
        <h1 className='mb-8 text-2xl text-center'>
          Start building your library
        </h1>
        <SignupForm />
      </>
    </Modal>
  )
})

export default SignupModal
