import { useQuery } from 'react-query'
import api from 'app/mainframe/api'

import { useInfiniteQueryWithInitialData } from 'app/mainframe/queries/utils'

const useSavedContentCount = () =>
  useQuery(['content', 'saved', 'count'], api.User.savedContentCount)
const useSavedContent = () =>
  useQuery(['content', 'saved'], api.User.savedContent)
const useInfiniteSavedContent = () =>
  useInfiniteQueryWithInitialData(
    ['content', 'saved', 'all'],
    ['content', 'saved'],
    api.User.savedContent
  )

const useSavedLogsCount = () =>
  useQuery(['logs', 'saved', 'count'], api.User.savedLogsCount)
const useSavedLogs = () => useQuery(['logs', 'saved'], api.User.savedLogs)
const useInfiniteSavedLogs = () =>
  useInfiniteQueryWithInitialData(
    ['logs', 'saved', 'all'],
    ['logs', 'saved'],
    api.User.savedLogs
  )

const useUserPrimersList = () =>
  useQuery(['primers', 'user'], api.User.primersList)

export {
  useUserPrimersList,
  useSavedContent,
  useSavedContentCount,
  useInfiniteSavedContent,
  useSavedLogs,
  useSavedLogsCount,
  useInfiniteSavedLogs
}
