import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import api from 'app/mainframe/api'

import { useInfiniteQueryWithInitialData } from 'app/mainframe/queries/utils'

import { toast } from 'react-toastify'

const useContent = slug =>
  useQuery(['content', slug], () => api.Content.get(slug))

const useInfiniteContentLogs = slug =>
  useInfiniteQuery(
    ['content', slug, 'logs'],
    async ({ pageParam = 0 }) => api.Content.logs(slug, { page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length !== 0 ? allPages.length : undefined
    }
  )

const useContentPrimers = slug =>
  useQuery(['content', slug, 'primers'], api.Content.primers(slug))

const useInfiniteContentPrimers = slug =>
  useInfiniteQueryWithInitialData(
    ['content', slug, 'primers', 'all'],
    ['content', slug, 'primers'],
    api.Content.primers(slug)
  )

const useFeaturedContent = () =>
  useQuery(['content', 'featured'], api.Content.featured, {
    staleTime: Infinity
  })

const usePopularContent = () =>
  useQuery(['content', 'popular'], api.Content.popular, {
    staleTime: Infinity
  })

const useHomeContent = () =>
  useQuery(['content', 'home'], api.Content.home, {
    staleTime: Infinity
  })

const useEditContent = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Content.update, {
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
    onError: (error: any) => {
      toast.error(error.message)
    }
  })
}

const useEnrichContent = () => {
  return useMutation(api.External.enrich, {
    onMutate: async ({ title }) => {
      const toasted = toast.loading(`Adding ${title}`, {
        autoClose: false
      })
      return { toasted }
    },
    onSuccess: (data, variables: any, context: any) => {
      if (data.errors) {
        toast.update(context?.toasted, {
          render: data.message,
          type: 'error',
          autoClose: 3333,
          isLoading: false
        })
      } else {
        toast.dismiss(context?.toasted)
      }
    },
    onError: (error: any, variables, context: any) => {
      toast.update(context?.toasted, {
        render: error.message,
        type: 'error',
        autoClose: 3333,
        isLoading: false
      })
    }
  })
}

const useParseContentFromUrl = () => {
  return useMutation(api.External.parse, {
    onMutate: async ({ url }) => {
      const toasted = toast.loading(`Adding ${url}`, {
        autoClose: false
      })
      return { toasted }
    },
    onSuccess: (data, variables: any, context: any) => {
      if (data.errors) {
        toast.update(context?.toasted, {
          render: data.message,
          type: 'error',
          autoClose: 3333,
          isLoading: false
        })
      } else {
        toast.dismiss(context?.toasted)
      }
    },
    onError: (error: any, variables, context: any) => {
      toast.update(context?.toasted, {
        render: error.message,
        type: 'error',
        autoClose: 3333,
        isLoading: false
      })
    }
  })
}

export {
  useContent,
  useInfiniteContentLogs,
  useContentPrimers,
  useInfiniteContentPrimers,
  useFeaturedContent,
  usePopularContent,
  useHomeContent,
  useEnrichContent,
  useParseContentFromUrl,
  useEditContent
}
