interface ConfigProps {
  body?: string
  method: string
  headers: {
    'Content-Type': string
    Authorization?: string
  }
}

const API_ROOT_URL = '/api'

const encode = encodeURIComponent

let accessToken = null
const setAccessToken = token => {
  accessToken = token
}

const client = async (method, endpoint, data = null, root = API_ROOT_URL) => {
  const config = {
    method,
    headers: { 'Content-Type': 'application/json' }
  } as ConfigProps

  if (data) config.body = JSON.stringify(data)

  if (accessToken) config.headers.Authorization = `Token ${accessToken}`

  return window.fetch(`${root}${endpoint}`, config).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const errorKey = data.errors ? Object.keys(data.errors)[0] : null
      const message = errorKey
        ? data.errors[errorKey][0]
        : data.error
        ? data.error
        : 'Error fetching data'
      throw new Error(message)
    }
    return data
  })
}

const requests = {
  get: endpoint => client('GET', endpoint),
  del: endpoint => client('DELETE', endpoint),
  put: (endpoint, data = null) => client('PUT', endpoint, data),
  post: (endpoint, data = null) => client('POST', endpoint, data)
}

const limit = ({ page, count = 8 }) =>
  `limit=${count}&offset=${page ? page * count : 0}`

const Activity = {
  all: ({ page, count } = null) =>
    requests.get(`/activity?${limit({ page, count })}`)
}

const Auth = {
  user: () => requests.get('/user'),
  update: user => requests.put('/user', { user }),
  signup: ({ email, name, username, password }) =>
    requests.post('/users', { user: { email, name, username, password } }),
  requestAccess: email => requests.post('/signups', { email }),
  login: ({ email, password }) =>
    requests.post('/users/login', { user: { email, password } }),
  oauth: provider => requests.get(`/users/auth/${provider}`),
  username: username =>
    requests.get(`/user/exists?username=${encode(username)}`)
}

const Collective = {
  contents:
    (slug, users, filters) =>
    ({ page, count } = null) =>
      requests.get(
        `/collective/${slug}/contents?${limit({ page, count })}${
          users ? `&users=${users}` : ''
        }${
          filters
            ? Object.entries(filters)
                .map(([key, value]) => (value ? `&${key}=${value}` : null))
                .join('')
            : ''
        }`
      ),
  logs: (slug, id) => requests.get(`/collective/${slug}/logs/${id}`)
}

const Content = {
  get: slug => requests.get(`/contents/${slug}`),
  featured: () => requests.get('/contents/featured'),
  popular: () => requests.get('/contents/popular'),
  home: () => requests.get('/contents/home'),
  update: content => requests.put(`/contents/${content.slug}`, { content }),
  parse: ({ url, ...params }) =>
    requests.post('/contents/parse', { url, ...params }),
  logs: (slug, { page, count } = null) =>
    requests.get(`/contents/${slug}/logs?${limit({ page, count })}`),
  primers:
    slug =>
    ({ page, count } = null) =>
      requests.get(`/contents/${slug}/primers?${limit({ page, count })}`),
  works:
    username =>
    ({ page, count } = null) =>
      requests.get(
        `/contents/works?username=${encode(username)}&${limit({ page, count })}`
      )
}

const External = {
  search: (medium, query, { page = 0, count = 5 } = {}) =>
    requests.get(
      `/external/search?medium=${medium}&query=${query}&${limit({
        page,
        count
      })}`
    ),
  enrich: ({ medium, type, title, id }) =>
    requests.post(`/external/enrich?medium=${medium}&type=${type}&id=${id}`),
  parse: ({ url }) => requests.post('/external/parse', { url })
}

const Log = {
  get: (slug, username) => requests.get(`/logs/${slug}/${username}`),
  create: ({ id, title }) => requests.post('/logs', { contentId: id }),
  save: ({ content }) => requests.post('/logs/save', { contentId: content.id }),
  update: log => requests.put(`/logs/${log.id}`, { log }),
  del: id => requests.del(`/logs/${id}`),
  unlike: id => requests.del(`/logs/${id}/likes`),
  like: id => requests.post(`/logs/${id}/likes`),
  featured: () => requests.get('/logs/featured'),
  feed:
    filter =>
    ({ page, count } = null) =>
      requests.get(`/logs/feed?filter=${filter}&${limit({ page, count })}`)
}

const Notifications = {
  get: id => requests.get(`/notifications/${id}`),
  all: ({ page, count } = null) =>
    requests.get(`/notifications?${limit({ page, count })}`),
  unread: () => requests.get('/notifications/unread'),
  read: id => requests.post(`/notifications/${id}/read`)
}

const Password = {
  forgot: email => requests.post('/password/forgot', { email }),
  token: token => requests.post('/password/token', { token }),
  update: ({ password, token }) =>
    requests.put('/password/update', { password, token })
}

const Primer = {
  get: slug => requests.get(`/primers/${slug}`),
  logs: (slug, { page, count } = null) =>
    requests.get(`/primers/${slug}/logs?${limit({ page, count })}`),
  create: primer => requests.post('/primers', { primer }),
  del: ({ slug }) => requests.del(`/primers/${slug}`),
  update: primer => requests.put(`/primers/${primer.slug}`, { primer }),
  sortLog: ({ slug, logId, position, onMutate = null }) =>
    requests.put(`/primers/${slug}/log/${logId}/sort/${position}`),
  addLog: ({ slug, contentId, title, onMutate = null }) =>
    requests.post(`/primers/${slug}/log/${contentId}`),
  removeLog: ({ slug, logId, onMutate = null }) =>
    requests.del(`/primers/${slug}/log/${logId}`),
  featured: () => requests.get('/primers/featured')
}

const Profile = {
  get: username => requests.get(`/profiles/${username}`),
  follow: username => requests.post(`/profiles/${username}/follow`),
  unfollow: username => requests.del(`/profiles/${username}/follow`),
  featured: () => requests.get('/profiles/featured'),
  logs:
    username =>
    ({ page, count } = null) =>
      requests.get(`/profiles/${username}/logs?${limit({ page, count })}`),
  primers:
    username =>
    ({ page, count } = null) =>
      requests.get(`/profiles/${username}/primers?${limit({ page, count })}`)
}

const User = {
  savedContent: ({ page, count } = null) =>
    requests.get(`/user/content/saved?${limit({ page, count })}`),
  savedContentCount: () => requests.get('/user/content/saved/count'),
  savedLogs: ({ page, count } = null) =>
    requests.get(`/user/logs/saved?${limit({ page, count })}`),
  savedLogsCount: () => requests.get('/user/logs/saved/count'),
  primersList: () => requests.get('/user/primers/list'),
  onboard: () => requests.post('/user/onboard')
}

export default {
  Activity,
  Auth,
  Collective,
  Content,
  External,
  Log,
  Notifications,
  Password,
  Primer,
  Profile,
  User,
  setAccessToken
}
