import React from 'react'
import { Link } from 'react-router-dom'

import api from 'app/mainframe/api'

import { Form, Input } from 'antd'

const checkUsername = (rule, value, callback) => {
  if (value && value.length >= 2) {
    api.Auth.username(value).then(resp => {
      if (resp) {
        callback('Username already exists')
      } else {
        callback()
      }
    })
  } else {
    callback()
  }
}

const Label = ({ text }) => (
  <p className='mb-3 text-sm uppercase tracking-widest text-gray-300'>{text}</p>
)

const FormItemEmail = ({
  initialValue = null,
  label = true,
  placeholder = 'you@email.com',
  className = ''
}) => (
  <>
    {label && <Label text='Email address' />}
    <Form.Item
      className={className}
      name='email'
      initialValue={initialValue}
      rules={[
        { required: true, message: 'Enter your email' },
        { type: 'email', message: 'Not a valid email' }
      ]}
    >
      <Input size='large' placeholder={placeholder} />
    </Form.Item>
  </>
)

const FormItemName = ({ initialValue = null }) => (
  <>
    <Label text='Name' />
    <Form.Item
      name='name'
      initialValue={initialValue}
      rules={[{ required: true, message: 'Enter your name' }]}
    >
      <Input size='large' placeholder='Your name' />
    </Form.Item>
  </>
)

const FormItemUsername = ({ initialValue = null }) => (
  <>
    <Label text='Username' />
    <Form.Item
      name='username'
      initialValue={initialValue}
      rules={[
        { required: true, message: 'Choose a username' },
        { min: 2, message: 'Must be at least 2 characters' },
        {
          pattern: /^[a-zA-Z0-9_-]+$/,
          message:
            'Username can only contain letters, numbers, underscores, and hyphens'
        },
        { validator: checkUsername }
      ]}
    >
      <Input size='large' prefix='@' placeholder='username' />
    </Form.Item>
  </>
)

const FormItemPassword = ({
  required = true,
  placeholder = 'Your password',
  forgot = false
}) => (
  <>
    <Label text='Password' />
    <Form.Item
      name='password'
      rules={[
        { required, message: 'Enter your password' },
        { min: 6, message: 'Must be at least 6 characters' }
      ]}
      extra={
        forgot ? (
          <Link
            to='reset'
            className='float-right mt-2 text-xs uppercase tracking-widest text-gray-500 hover:text-gold-500'
          >
            Forgot password?
          </Link>
        ) : (
          ''
        )
      }
    >
      <Input.Password size='large' placeholder={placeholder} />
    </Form.Item>
  </>
)

const FormItemConfirmPassword = ({ required = true }) => (
  <>
    <Label text='Confirm password' />
    <Form.Item
      name='confirm'
      dependencies={['password']}
      rules={[
        { required, message: 'Please confirm your password' },
        { min: 6, message: 'Must be at least 6 characters' },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('Passwords do not match'))
          }
        })
      ]}
    >
      <Input.Password size='large' placeholder='Confirm password' />
    </Form.Item>
  </>
)

const FormItemAmazon = ({ initialValue = null }) => (
  <>
    <Label text='Amazon Referral Code' />
    <Form.Item name='amazon_referral_code' initialValue={initialValue}>
      <Input size='large' placeholder='amazoncode-20' />
    </Form.Item>
  </>
)

export {
  FormItemEmail,
  FormItemName,
  FormItemUsername,
  FormItemPassword,
  FormItemConfirmPassword,
  FormItemAmazon
}
