import React from 'react'
import { Link } from 'react-router-dom'

import Image from 'app/mainframe/components/Image'

const ContentResult = ({ item = null }) => (
  <div className='flex items-center p-3 cursor-pointer'>
    <div className='flex-shrink-0 mr-2 w-12 h-12'>
      <Image
        src={item.image_url}
        alt={item.title}
        className='w-full h-full object-contain'
      />
    </div>
    <div className='flex flex-col min-w-0'>
      <div
        className='single-line mb-1 text-gray-200 text-base'
        dangerouslySetInnerHTML={{
          __html: item._highlightResult.title
            ? item._highlightResult.title.value
            : item.title
        }}
      />
      <div
        className='single-line text-gray-400 text-sm'
        dangerouslySetInnerHTML={{
          __html: item._highlightResult.creator_list
            ? item._highlightResult.creator_list.map(c => c.value).join(', ')
            : item.creator_list.join(', ')
        }}
      />
    </div>
  </div>
)

export default ContentResult
