import React, { useState, useRef, useLayoutEffect } from 'react'

export const useIsOverflow = () => {
  const overflowRef = useRef()
  const [isOverflow, setIsOverflow] = useState(undefined)

  useLayoutEffect(() => {
    const { current } = overflowRef as any

    const trigger = () => {
      const hasOverflow = current?.scrollHeight > current?.clientHeight
      setIsOverflow(hasOverflow)
    }

    if (current) {
      trigger()
    }
  })

  return [overflowRef, isOverflow]
}
