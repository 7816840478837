import React, { Fragment } from 'react'
import clsx from 'clsx'

import { Menu, Transition } from '@headlessui/react'
import { IoFilter, IoClose } from 'react-icons/io5'

export const longNowMenu = {
  board: {
    title: 'Board Members',
    users: ['Stewart Brand', 'Brian Eno', 'Kevin Kelly']
  },
  speakers: {
    title: 'Seminar Speakers',
    users: [
      'George Dyson',
      'David Brin',
      'Bruce Sterling',
      'Daniel Suarez',
      'Mark Pauline',
      'Lewis Darnell',
      'Neal Stephenson',
      'Maria Popova',
      'Megan Prelinger',
      'Rick Prelinger'
    ]
  },
  staff: { title: 'Staff Members', users: ['Nicholas Paul Brysiewicz'] }
}

export default function CollectiveDropdown({ filters, setFilters }) {
  const group = filters && filters.group
  return (
    <Menu as='div' className='relative flex flex-shrink-0'>
      <Menu.Button className='justify-center focus:outline-none focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-2'>
        <div className='border-[#DDDDDD] flex items-center px-6 py-3 w-full hover:text-gold-500 text-gray-400 text-base bg-white border-b border-r border-t rounded-r-3xl cursor-pointer select-none transition duration-200'>
          {group ? longNowMenu[group].title : 'Filter'}{' '}
          <IoFilter className='ml-2' />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='mt-[55px] absolute z-10 right-0 flex flex-col p-2 w-48 bg-white rounded-md focus:outline-none shadow-lg ring-black ring-opacity-5 ring-1'>
          {Object.entries(longNowMenu).map(entries => {
            const [key, value] = entries
            const { title } = value
            const isActive = group === key

            return (
              <Menu.Item key={key}>
                {({ active }) => (
                  <div
                    className={clsx([
                      'hover:bg-[#EEEEEE] last:mb-0 mb-1 w-full rounded-lg transition duration-200',
                      {
                        'bg-[#EEEEEE]': isActive
                      }
                    ])}
                  >
                    <div
                      onClick={() =>
                        isActive
                          ? setFilters({ ...filters, group: null })
                          : setFilters({ ...filters, group: key })
                      }
                      className={clsx([
                        'px-2.5 py-2 w-full flex items-center text-base group cursor-pointer select-none transition duration-200 hover:text-gray-800',
                        isActive ? 'text-gray-800' : 'text-gray-400'
                      ])}
                    >
                      {title}
                      {isActive && (
                        <IoClose className='ml-auto text-gray-400 group-hover:text-gray-800' />
                      )}
                    </div>
                  </div>
                )}
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
