import React, { useState, useEffect } from 'react'
import {
  AutocompleteOptions,
  AutocompleteState,
  createAutocomplete
} from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { Hit } from '@algolia/client-search'
import { searchClient } from 'app/mainframe/clients/algoliaClient'
import clsx from 'clsx'
import NiceModal from '@ebay/nice-modal-react'

import { useAddLog } from 'app/mainframe/queries/log'
import {
  useEnrichContent,
  useParseContentFromUrl
} from 'app/mainframe/queries/content'
import api from 'app/mainframe/api'

import Image from 'app/mainframe/components/Image'
import Button from 'app/mainframe/components/Button/Button'

import { Empty } from 'antd'
import Popover from 'app/mainframe/components/Popover'
import { InfoCircleOutlined } from '@ant-design/icons'

import { AiOutlineSearch, AiOutlineLoading } from 'react-icons/ai'

import { useMixpanel } from 'app/mainframe/context/mixpanel'

import 'app/mainframe/components/Autocomplete/Autocomplete.css'
import {
  FiBook,
  FiFilm,
  FiHeadphones,
  FiMic,
  FiTv,
  FiYoutube
} from 'react-icons/fi'

type AutocompleteItem = Hit<{
  objectID: string
  title: string
  creator_list: string[]
  description: string
  medium: string
  form: string
  slug: string
  form_display: string
  form_slug: string
  origin: string
  image_url: string
  placeholder_image_url: string
  origin_url: string
  name: string
  username: string
  user_list: string[]
  content_collection: any[]
}>

interface SearchWrapperProps
  extends Partial<AutocompleteOptions<AutocompleteItem>> {
  slug?: string
  onboard?: boolean
  indices?: Index[]
  onSelect?: (any) => void
}

interface Index {
  key: string
  title: string
  indexName: string
  filter: string
  component: any
}

const enrichOptions = [
  {
    id: 'books',
    media: ['books'],
    title: 'Books',
    icon: <FiBook />
  },
  { id: 'films', media: ['films'], title: 'Films', icon: <FiFilm /> },
  { id: 'videos', media: ['videos'], title: 'YouTube', icon: <FiYoutube /> },
  {
    id: 'music',
    media: ['albums', 'tracks'],
    title: 'Music',
    icon: <FiHeadphones />
  },
  {
    id: 'podcasts',
    media: ['shows', 'episodes'],
    title: 'Podcasts',
    icon: <FiMic />
  },
  {
    id: 'tv',
    media: ['tv'],
    title: 'TV',
    icon: <FiTv />
  }
]

const SearchFooter = ({ onClickAdd }: { onClickAdd?: any }) => (
  <div className='flex items-center border-t border-gray-600 px-3 py-3 leading-none text-gray-200'>
    <div className='font-xs mr-4 flex items-center'>
      <span className='mr-1.5 flex items-center rounded-md bg-gray-900 px-1 pb-1 pt-0.5 font-mono text-sm leading-none text-gray-50'>
        ↑↓
      </span>
      Select
    </div>
    <div className='font-xs mr-4 flex items-center'>
      <span className='mr-1.5 flex items-center rounded-md bg-gray-900 px-1.5 py-1 font-mono text-xs leading-none text-gray-50'>
        enter
      </span>
      Open
    </div>
    {onClickAdd && (
      <Button
        unstyled
        className='ml-auto px-3 py-1.5 text-[0.7rem]'
        onClick={onClickAdd}
      >
        Add to Catalog
      </Button>
    )}
  </div>
)

const SearchWrapper = (props: SearchWrapperProps) => {
  const { mixpanel } = useMixpanel()

  const { mutateAsync: parseUrl } = useParseContentFromUrl()
  const { mutateAsync: enrichResult } = useEnrichContent()
  const { mutateAsync: addLog } = useAddLog()

  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<AutocompleteItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle'
  })

  const [searchResponse, setSearchResponse] = useState(null)
  const [selectedResult, setSelectedResult] = useState({
    keyIndex: -1,
    index: -1
  })
  const [mediaType, setMediaType] = useState(null)

  const onSearchSubmit = () => {
    setSearchResponse(null)
    setSelectedResult({ keyIndex: -1, index: -1 })

    api.External.search(
      mediaType.id,
      autocompleteState.query.replace('&', '')
    ).then(response => {
      setSearchResponse(response)
    })
  }

  const autocomplete = React.useMemo(
    () =>
      createAutocomplete<
        AutocompleteItem,
        React.BaseSyntheticEvent,
        React.MouseEvent,
        React.KeyboardEvent
      >({
        onStateChange({ state }) {
          setAutocompleteState(state)
        },
        getSources() {
          return props.indices.map(index => ({
            sourceId: index.key,
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: index.indexName,
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: '<mark>',
                      highlightPostTag: '</mark>',
                      filters: index.filter
                    }
                  }
                ]
              })
            },
            onSelect({ item, setIsOpen, setQuery }) {
              props.onSelect(item)
              setQuery('')
              setIsOpen(false)
            }
          }))
        },
        ...props
      }),
    [props]
  )
  const inputRef = React.useRef<HTMLInputElement>(null)
  const formRef = React.useRef<HTMLFormElement>(null)
  const panelRef = React.useRef<HTMLDivElement>(null)
  const { getEnvironmentProps } = autocomplete

  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current
    })

    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
    }
  }, [getEnvironmentProps, formRef, inputRef, panelRef])

  useEffect(() => {
    if (searchResponse) {
      setSearchResponse(null)
    }
    setMediaType(null)
  }, [autocompleteState.query])

  useEffect(() => {
    if (mediaType) {
      onSearchSubmit()
    }
  }, [mediaType])

  const onAddFromSearch = (keyIndex = null, index = null) => {
    const k = keyIndex || selectedResult.keyIndex
    const i = index || selectedResult.index
    const result = searchResponse[mediaType.media[k]][i]
    if (result) {
      NiceModal.hide('log-editor-modal')
      NiceModal.hide('search-modal')
      enrichResult({
        medium: mediaType.id,
        type: mediaType.media[k],
        title: result.title,
        id: result.id
      }).then(response => {
        if (!response.errors) {
          addLog({ id: response.id, title: result.title }).then(res => {
            NiceModal.show('log-editor-modal', { slug: res.slug })
          })
          mixpanel.track('External Added', {
            id: response.id,
            context: 'search'
          })
          mixpanel.track('Content Added', {
            id: response.id,
            context: 'search'
          })
        }
      })
      autocomplete.setQuery('')
      autocomplete.setIsOpen(false)
    }
  }

  const moveDown = () => {
    // if nothing is highlighted, select the first result
    if (selectedResult.index === -1) {
      return {
        keyIndex: 0,
        key: mediaType.media[0],
        index: 0
      }
    }
    // if the last result in the section is highlighted...
    if (
      selectedResult.index ===
      searchResponse[mediaType.media[selectedResult.keyIndex]].length - 1
    ) {
      // if the highlighted section is the last section, select the very first result
      if (selectedResult.keyIndex === mediaType.media.length - 1) {
        return {
          keyIndex: 0,
          key: mediaType.media[0],
          index: 0
        }
      }
      // otherwise, select the first result in the next section
      return {
        keyIndex: selectedResult.keyIndex + 1,
        key: mediaType.media[selectedResult.keyIndex + 1],
        index: 0
      }
    }
    // otherwise, select the next result in the section
    return {
      keyIndex: selectedResult.keyIndex,
      key: mediaType.media[selectedResult.keyIndex],
      index: selectedResult.index + 1
    }
  }

  const moveUp = () => {
    // if nothing is highlighted or the first result in the first section is highlighted, select the last result in the last section
    if (
      selectedResult.index === -1 ||
      (selectedResult.index === 0 && selectedResult.keyIndex === 0)
    ) {
      return {
        keyIndex: mediaType.media.length - 1,
        key: mediaType.media.at(-1),
        index: searchResponse[mediaType.media.at(-1)].length - 1
      }
    }
    // if the first result in the section is highlighted, select the last result in the previous section
    if (selectedResult.index === 0) {
      return {
        keyIndex: selectedResult.keyIndex - 1,
        key: mediaType.media[selectedResult.keyIndex - 1],
        index:
          searchResponse[mediaType.media[selectedResult.keyIndex - 1]].length -
          1
      }
    }
    // otherwise, select the previous result in the section
    return {
      keyIndex: selectedResult.keyIndex,
      key: mediaType.media[selectedResult.keyIndex],
      index: selectedResult.index - 1
    }
  }

  const onKeyDown = e => {
    if (searchResponse) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault()
        e.stopPropagation()

        const newSelectedResult = e.key === 'ArrowDown' ? moveDown() : moveUp()
        setSelectedResult(newSelectedResult)
      }

      if (e.key === 'Enter' && selectedResult.index > -1) {
        e.preventDefault()
        e.stopPropagation()
        onAddFromSearch()
      }
    }
  }

  const onPaste = e => {
    const text = e.clipboardData.getData('text')

    e.preventDefault()
    NiceModal.hide('log-editor-modal')
    NiceModal.hide('search-modal')
    parseUrl({ url: text }).then(response => {
      if (!response.errors) {
        addLog({ id: response.id, title: response.title }).then(result => {
          NiceModal.show('log-editor-modal', { slug: result.slug })
        })
        mixpanel.track('External Added', {
          id: response.id,
          context: 'search'
        })
        mixpanel.track('Content Added', {
          id: response.id,
          context: 'search'
        })
      }
    })
    autocomplete.setQuery('')
    autocomplete.setIsOpen(false)
  }

  const noResults =
    !autocompleteState.isOpen &&
    autocompleteState.collections.length > 0 &&
    autocompleteState.query &&
    autocompleteState.query.length > 0

  return (
    <div className='searchAutocomplete' {...autocomplete.getRootProps({})}>
      <form
        ref={formRef}
        className='group flex items-center'
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
      >
        <AiOutlineSearch className='absolute ml-4 text-2xl text-gray-200 group-hover:text-gray-100' />
        <input
          className='w-full rounded-lg border-gray-700 bg-gray-800 py-3 pr-4 pl-14 text-lg text-gray-50 placeholder-gray-500 outline-none ring-0'
          ref={inputRef}
          {...autocomplete.getInputProps({ inputElement: inputRef.current })}
          onKeyDown={
            searchResponse || noResults
              ? onKeyDown
              : autocomplete.getInputProps({ inputElement: inputRef.current })
                  .onKeyDown
          }
          onPaste={onPaste}
        />
      </form>

      {autocompleteState.isOpen && (
        <div
          ref={panelRef}
          className='z-20 mt-2 w-full rounded-lg bg-gray-700 shadow-xl'
          {...autocomplete.getPanelProps({})}
        >
          <div className='searchResultsWrapper styled-scrollbar'>
            {autocompleteState.collections.map(collection => {
              const { source, items } = collection
              const { sourceId } = source
              const index = props.indices
                .filter(index => index.key === sourceId)
                .pop()

              if (items && items.length > 0) {
                return (
                  <section
                    key={`index-${sourceId}`}
                    className='searchResults pt-4 first:pt-3'
                  >
                    <div className='resultsTitle px-3 pb-1 text-sm uppercase tracking-widest text-gray-400'>
                      {index.title}
                    </div>
                    <ul
                      className='mb-0 overflow-hidden'
                      {...autocomplete.getListProps()}
                    >
                      {items.length > 0 &&
                        items.map(item => {
                          return (
                            <li
                              key={item.objectID}
                              className='searchResult'
                              {...autocomplete.getItemProps({ item, source })}
                            >
                              {React.cloneElement(index.component, {
                                item
                              })}
                            </li>
                          )
                        })}
                    </ul>
                  </section>
                )
              }
            })}
          </div>
          <SearchFooter
            onClickAdd={() => {
              autocomplete.setIsOpen(false)
              autocomplete.setCollections([
                {
                  items: [],
                  source: autocompleteState.collections[0].source
                }
              ])
            }}
          />
        </div>
      )}

      {noResults && (
        <div
          ref={panelRef}
          className='z-20 mt-2 w-full rounded-lg bg-gray-700 shadow-xl'
          {...autocomplete.getPanelProps({})}
        >
          <ul
            className='no-scrollbar mb-0 max-h-[888px] overflow-y-auto rounded-lg'
            {...autocomplete.getListProps()}
          >
            {!searchResponse && (
              <>
                <li className='p-4'>
                  <Popover
                    position='left-start'
                    content={
                      <div className='w-60 py-3 px-4 md:w-[320px]'>
                        <div className='mb-2 border-b border-gray-650 pb-2'>
                          <h2 className='text-center text-lg uppercase'>
                            In Search of Quality
                          </h2>
                        </div>
                        <p className='mb-4 text-base text-gray-200'>
                          In order to maintain a level of quality, Analogue
                          relies on the curation of our users.
                        </p>

                        <p className='mb-4 text-base text-gray-200'>
                          Therefore, we&apos;ve decided not to add all existing
                          content from other databases. Every piece of content
                          in the database has been referred by a public figure
                          or a user of the platform.
                        </p>

                        <p className='mb-2 text-base text-gray-200'>
                          Thank you for understanding that there may be some
                          pieces missing. We hope you will help us add
                          meaningful content to our ever-growing catalog.
                        </p>
                      </div>
                    }
                  >
                    <InfoCircleOutlined className='right-4 top-3 float-right cursor-pointer text-gray-300 hover:text-gold-500' />
                  </Popover>
                  <h3 className='mt-2 mb-1 text-center text-xl'>
                    You have exceptional taste.
                  </h3>
                  <p className='mb-2 text-center text-base text-gray-300'>
                    Help build our catalog.
                  </p>
                </li>
                <li className='px-4 pb-6'>
                  <div className='grid grid-cols-3 gap-4'>
                    {enrichOptions.map(option => (
                      <div
                        key={option.id}
                        className={clsx(
                          'flex cursor-pointer flex-col items-center justify-center rounded-md border border-gray-750 py-4 shadow-md',
                          mediaType && mediaType.id === option.id
                            ? 'bg-gradient-to-br from-gold-500 to-gold-700'
                            : 'bg-gradient-to-br from-gray-900 to-gray-800'
                        )}
                        onClick={() => setMediaType(option)}
                      >
                        <div
                          className={clsx(
                            'text-2xl',
                            mediaType && mediaType.id === option.id
                              ? 'text-gray-800'
                              : 'text-gray-100'
                          )}
                        >
                          {option.icon}
                        </div>
                        <p
                          className={clsx(
                            'mt-1 text-sm',
                            mediaType && mediaType.id === option.id
                              ? 'text-gray-600'
                              : 'text-gray-300'
                          )}
                        >
                          {option.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </li>
                {mediaType ? (
                  <li className='flex cursor-pointer items-center border-t border-gray-600 bg-gray-650 p-4 text-sm'>
                    <AiOutlineLoading className='mr-2 animate-spin text-base text-gray-300' />
                    <div className='single-line mr-2'>
                      Searching for &quot;
                      <span className='text-base italic text-gold-500'>
                        {autocompleteState.query}
                      </span>
                      &quot;
                    </div>
                  </li>
                ) : (
                  <li className='flex cursor-pointer items-center border-t border-gray-600 bg-gray-650 p-4 text-sm'>
                    <div className='single-line mr-2'>
                      Select a medium to search for &quot;
                      <span className='text-base italic text-gold-500'>
                        {autocompleteState.query}
                      </span>
                      &quot;
                    </div>
                  </li>
                )}
              </>
            )}

            {searchResponse && (
              <div>
                {searchResponse.num_results > 0 ? (
                  mediaType.media.map(
                    (key, keyIndex) =>
                      searchResponse[key].length > 0 && (
                        <section className='searchResults pt-4 first:pt-3'>
                          <div className='resultsTitle px-3 pb-1 text-sm uppercase tracking-widest text-gray-400'>
                            {key}
                          </div>
                          {searchResponse[key].map((item, itemIndex) => (
                            <li
                              key={`${key}.${itemIndex}`}
                              className={clsx(
                                'flex cursor-pointer items-center p-4',
                                {
                                  'bg-gray-650':
                                    selectedResult.keyIndex === keyIndex &&
                                    selectedResult.index === itemIndex
                                }
                              )}
                              onMouseEnter={() =>
                                setSelectedResult({
                                  keyIndex,
                                  index: itemIndex
                                })
                              }
                              onMouseLeave={() =>
                                setSelectedResult({
                                  keyIndex: -1,
                                  index: -1
                                })
                              }
                              onClick={() =>
                                onAddFromSearch(keyIndex, itemIndex)
                              }
                            >
                              <div className='mr-2 h-12 w-12 flex-shrink-0'>
                                <Image
                                  src={item.image}
                                  alt={item.title}
                                  className='h-full w-full object-contain'
                                />
                              </div>
                              <div className='flex min-w-0 flex-col'>
                                <div className='single-line mb-1 text-base text-gray-200'>
                                  {item.title}
                                </div>
                                {item.creators && item.creators.length > 0 ? (
                                  <div className='single-line text-sm text-gray-400'>
                                    {item.creators}
                                  </div>
                                ) : (
                                  <div className='single-line text-sm text-gray-400'>
                                    {item.published_at}
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                        </section>
                      )
                  )
                ) : (
                  <li className='flex items-center px-4 py-2 text-sm'>
                    <div className='mr-4 h-12 w-12 flex-shrink-0'>
                      <Empty description={false} />
                    </div>
                    <div className='single-line flex min-w-0 items-center'>
                      No results for &quot;
                      <span className='text-base italic text-gold-500'>
                        {autocompleteState.query}
                      </span>
                      &quot;
                    </div>
                  </li>
                )}
              </div>
            )}
          </ul>

          {searchResponse && searchResponse.num_results > 0 && <SearchFooter />}
        </div>
      )}
    </div>
  )
}

export default SearchWrapper
