import algoliasearch from 'algoliasearch/lite'
import recommend from '@algolia/recommend'

const recommendClient = recommend(
  process.env.ALGOLIASEARCH_APPLICATION_ID,
  process.env.ALGOLIASEARCH_API_KEY
)

const searchClient = algoliasearch(
  process.env.ALGOLIASEARCH_APPLICATION_ID,
  process.env.ALGOLIASEARCH_API_KEY
)

export { searchClient, recommendClient }
