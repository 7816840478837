/* eslint-disable react/jsx-key */
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react'

import { useHistory } from 'app/mainframe/context/history'
import { useAuth } from 'app/mainframe/context/auth'

import SearchBar from 'app/mainframe/components/Search/SearchBar/SearchBar'
import Notifications from 'app/mainframe/components/Notifications'

import Image from 'app/mainframe/components/Image'
import Menu from 'app/mainframe/components/Menu'
import Button from 'app/mainframe/components/Button/Button'
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc'
import { FiPlus } from 'react-icons/fi'

const Header = () => {
  const { currentUser, logout } = useAuth()
  const { hasBack, hasForward } = useHistory()
  const navigate = useNavigate()

  const menuItems = [
    { text: 'Profile', to: `/@${currentUser.username}` },
    { text: 'Settings', to: '/settings' },
    { text: 'Logout', onClick: logout }
  ]

  return (
    <header className='flex items-center border-b border-gray-666 bg-gray-800 px-6'>
      <div className='flex flex-shrink-0 items-center'>
        <Button
          disabled={!hasBack}
          onClick={hasBack ? () => navigate(-1) : null}
          unstyled
          type='text'
          className='mr-4 p-0 text-2xl'
        >
          <VscChevronLeft />
        </Button>
        <Button
          disabled={!hasForward}
          onClick={hasForward ? () => navigate(1) : null}
          unstyled
          type='text'
          className='p-0 text-2xl'
        >
          <VscChevronRight />
        </Button>
      </div>

      <SearchBar />

      <div className='ml-auto flex flex-shrink-0 items-center'>
        <button
          type='button'
          className='mr-2 cursor-pointer rounded-lg p-1.5 text-2xl text-gray-300 transition duration-200 hover:bg-gray-700 hover:text-gold-500'
          onClick={() => NiceModal.show('log-editor-modal')}
        >
          <FiPlus />
        </button>
        <Notifications />
        <Menu
          className='mt-3 w-32'
          position='bottom-end'
          menu={[
            {
              items: menuItems.map(item => {
                if (item.onClick) {
                  return (
                    <button type='button' onClick={item.onClick}>
                      {item.text}
                    </button>
                  )
                }
                return <Link to={item.to}>{item.text}</Link>
              })
            }
          ]}
        >
          <Image
            className='h-8 w-8 cursor-pointer rounded-full object-cover'
            src={currentUser.image}
            alt={currentUser.username}
          />
        </Menu>
      </div>
    </header>
  )
}

export default Header
