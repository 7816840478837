import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  useMutation
} from 'react-query'
import api from 'app/mainframe/api'
import { toast } from 'react-toastify'

const usePrimer = slug => useQuery(['primer', slug], () => api.Primer.get(slug))

const usePrimerLogs = slug =>
  useQuery(
    ['primer', slug, 'share'],
    () => api.Primer.logs(slug, { page: 0 }),
    {
      initialData: () => {
        const queryClient = useQueryClient()
        const queryCacheData = queryClient.getQueryState([
          'primer',
          slug,
          'logs'
        ])?.data as any
        if (queryCacheData) {
          return queryCacheData.pages[0]
        }
      }
    }
  )

const useInfinitePrimerLogs = slug =>
  useInfiniteQuery(
    ['primer', slug, 'logs'],
    async ({ pageParam = 0 }) => api.Primer.logs(slug, { page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length !== 0 ? allPages.length : undefined
    }
  )

const useSortPrimerLogs = slug => {
  const queryClient = useQueryClient()
  return useMutation(api.Primer.sortLog, {
    // When mutate is called:
    onMutate: async ({ slug, onMutate }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['primer', slug, 'logs'])
      const previousLogs = queryClient.getQueryData(['primer', slug, 'logs'])

      // Optimistically update to the new value
      if (onMutate) onMutate()
      return { previousLogs }
    },
    onSuccess: () => queryClient.invalidateQueries(),
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error: any, variables, context: any) => {
      toast.error(error.message)
      if (context?.previousLogs) {
        queryClient.setQueryData(['primer', slug, 'logs'], context.previousLogs)
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(['primer', slug, 'logs'])
    }
  })
}

const useAddPrimerLog = slug => {
  const queryClient = useQueryClient()
  return useMutation(api.Primer.addLog, {
    onMutate: async ({ slug, title, onMutate }) => {
      await queryClient.cancelQueries(['primer', slug, 'logs'])
      const previousLogs = queryClient.getQueryData(['primer', slug, 'logs'])

      // TODO optimistically update primer
      if (onMutate) onMutate()

      const toasted = toast.loading(`Adding ${title}`, {
        autoClose: false
      })

      return { previousLogs, toasted }
    },
    onSuccess: (data, variables, context: any) => {
      toast.update(context?.toasted, {
        render: `Added ${variables.title}`,
        type: 'success',
        autoClose: 3333,
        isLoading: false
      })
      queryClient.invalidateQueries(['log', data.slug, data.username])
      queryClient.invalidateQueries(['content', data.slug])
      queryClient.invalidateQueries()
    },
    onError: (error: any, variables, context: any) => {
      toast.update(context?.toasted, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 3333
      })
      if (context?.previousLogs) {
        queryClient.setQueryData(['primer', slug, 'logs'], context.previousLogs)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['primer', slug])
      queryClient.invalidateQueries(['primer', slug, 'logs'])
    }
  })
}

const useRemovePrimerLog = slug => {
  const queryClient = useQueryClient()
  return useMutation(api.Primer.removeLog, {
    onMutate: async ({ slug, onMutate }) => {
      await queryClient.cancelQueries(['primer', slug, 'logs'])
      const previousLogs = queryClient.getQueryData(['primer', slug, 'logs'])

      // TODO optimistically update primer
      if (onMutate) onMutate()

      const toasted = toast.loading('Removing', {
        autoClose: false
      })

      return { previousLogs, toasted }
    },
    onSuccess: (data, variables, context: any) => {
      queryClient.invalidateQueries(['log', data.slug, data.username])
      queryClient.invalidateQueries(['content', data.slug])
      queryClient.invalidateQueries()
      toast.update(context?.toasted, {
        render: `Removed ${data.title}`,
        type: 'success',
        autoClose: 3333,
        isLoading: false
      })
    },
    onError: (error: any, variables, context: any) => {
      toast.update(context?.toasted, {
        render: error.message,
        type: 'error',
        autoClose: 3333,
        isLoading: false
      })
      if (context?.previousLogs) {
        queryClient.setQueryData(['primer', slug, 'logs'], context.previousLogs)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['primer', slug])
      queryClient.invalidateQueries(['primer', slug, 'logs'])
    }
  })
}

const useCreatePrimer = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Primer.create, {
    onSuccess: () => queryClient.invalidateQueries(['primers', 'user']),
    onError: (error: any, variables, context: any) => {
      toast.error(error.message)
    }
  })
}
const useEditPrimer = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Primer.update, {
    onSuccess: data => {
      queryClient.invalidateQueries(['primer', data.slug])
      queryClient.invalidateQueries(['primers', 'user'])
    },
    onError: (error: any, variables, context: any) => {
      toast.error(error.message)
    }
  })
}
const useDeletePrimer = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Primer.del, {
    onSuccess: data => {
      toast.success(`Deleted ${data.title}`)
      queryClient.invalidateQueries(['primers', 'user'])
    },
    onError: (error: any, variables, context: any) => {
      toast.error(error.message)
    }
  })
}
export {
  usePrimer,
  usePrimerLogs,
  useInfinitePrimerLogs,
  useSortPrimerLogs,
  useAddPrimerLog,
  useRemovePrimerLog,
  useCreatePrimer,
  useEditPrimer,
  useDeletePrimer
}
