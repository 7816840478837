import React from 'react'
import clsx from 'clsx'
import Pluralize from 'react-pluralize'

import { useAuth } from 'app/mainframe/context/auth'

import Image from 'app/mainframe/components/Image'
import LinkWrapper from 'app/mainframe/components/LinkWrapper'
import OracleButton from 'app/mainframe/components/OracleButton'
import TimeAgo from 'app/mainframe/components/TimeAgo'

import { FiLayers } from 'react-icons/fi'

const LogHeader = ({ log }) => {
  const { currentUser } = useAuth()

  const isOwner = currentUser && currentUser.username === log.user.username

  return (
    <div
      className={`sticky z-10 flex w-full border-b border-gray-50 bg-white px-2 py-4 dark:border-gray-700 dark:bg-gray-800 sm:px-5 ${
        currentUser ? 'top-0' : 'top-[64px]'
      }`}
    >
      <LinkWrapper
        to={`/@${log.user.username}`}
        className='flex items-center text-gray-900 hover:text-gold-500 dark:text-gray-50'
      >
        <Image
          src={log.user.image}
          alt={log.user.name}
          className={clsx([
            'mr-3 h-10 w-10 rounded-full border object-cover object-top',
            log.user.curated ? 'border-gold-500' : 'border-gray-700'
          ])}
        />
        <div className='flex flex-col'>
          <p className='text-xl leading-none line-clamp-1'>{log.user.name}</p>
          <p className='mt-1 text-sm leading-none text-gray-400 line-clamp-1'>
            @{log.user.username}
          </p>
        </div>
      </LinkWrapper>

      <div className='ml-auto flex items-center pl-3'>
        <div className='flex flex-col items-end'>
          <span className='flex items-end text-sm text-gray-500 dark:text-gray-300'>
            <TimeAgo date={log.postedAt} />
            {isOwner && log.postedAt !== log.createdAt && (
              <span className='ml-2 hidden text-xs italic text-gray-500 sm:block'>
                (first logged <TimeAgo date={log.createdAt} />)
              </span>
            )}
          </span>
          {log.primers && log.primers.length > 0 && (
            <div className='single-line flex items-center text-xs text-gray-400'>
              <FiLayers className='mr-1.5 flex-shrink-0 text-gray-400' />
              {log.primers.length > 1 ? (
                <Pluralize
                  className='flex items-center text-gray-400'
                  singular='collection'
                  count={log.primers.length}
                />
              ) : (
                log.primers[0].title
              )}
            </div>
          )}
        </div>
        <OracleButton
          to={`/logs/${log.id}`}
          className='ml-2 hidden bg-transparent sm:flex'
        />
      </div>
    </div>
  )
}

export default LogHeader
