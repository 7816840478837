import React, { FunctionComponent, useEffect, useState } from 'react'
import { EmbedProviders } from 'app/mainframe/context/embed'
import { ReactTitle } from 'react-meta-tags'
import clsx from 'clsx'

import { useInfiniteCollectiveContents } from 'app/mainframe/queries/collective'

import Image from 'app/mainframe/components/Image'
import CollectiveSelect from './CollectiveSelect/CollectiveSelect'
import CollectiveDropdown, {
  longNowMenu
} from './CollectiveDropdown/CollectiveDropdown'
import CollectiveTagsCarousel from './CollectiveTagsCarousel/CollectiveTagsCarousel'
import Carousel from 'app/mainframe/components/Carousel/Carousel'
import { CollectiveBookFeed } from 'app/mainframe/components/Feed/Grids'
import AudioPlayer from 'app/mainframe/components/AudioPlayer/AudioPlayer'

import { FiCheck } from 'react-icons/fi'

import './CollectiveEmbed.css'

interface CollectiveEmbedProps {
  title: string
  slug: string
  currentUsers: any[]
  col?: string
  theme?: string
}

const CollectiveEmbedProvider: FunctionComponent<
  CollectiveEmbedProps
> = props => {
  return (
    <EmbedProviders initialTheme={props.theme}>
      <CollectiveEmbed {...props} />
    </EmbedProviders>
  )
}

const CollectiveEmbed: FunctionComponent<CollectiveEmbedProps> = ({
  title,
  slug,
  currentUsers,
  col,
  theme
}) => {
  const [users, setUsers] = useState(currentUsers)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [userIds, setUserIds] = useState([])
  const [filters, setFilters] = useState({
    group: undefined,
    primer: slug === 'longnow' ? 'Manual for Civilization' : undefined
  })

  useEffect(() => {
    setUserIds(
      filteredUsers.length > 0 ? filteredUsers.map(u => u.split(',')[0]) : null
    )
  }, [filteredUsers])

  useEffect(() => {
    if (filters && filters.group) {
      const usersInGroup = currentUsers.filter(user =>
        longNowMenu[filters.group].users.includes(user.name)
      )
      setUsers(usersInGroup)
      setFilteredUsers([])
    } else {
      setUsers(currentUsers)
    }
  }, [filters])

  const { isFetchingNextPage } = useInfiniteCollectiveContents(
    slug,
    userIds,
    filters
  )

  // key in the form of "id,name"
  const onSelect = key => {
    const [id, name] = key.split(',')

    if (slug === 'longnow') {
      setFilteredUsers(filteredUsers[0] === key ? [] : [key])

      const newUsers = users.map(user => {
        if (user.id === parseInt(id, 10)) {
          return { ...user, active: !user.active }
        }
        return { ...user, active: false }
      })
      setUsers(newUsers)
    } else {
      const userPreviouslySelected = filteredUsers.indexOf(key) !== -1
      const newUserKeys = userPreviouslySelected
        ? filteredUsers.filter(user => user !== key)
        : [...filteredUsers, key]
      setFilteredUsers(newUserKeys)

      const newUsers = users.map(user => {
        if (user.id === parseInt(id)) {
          return { ...user, active: !userPreviouslySelected }
        }
        return user
      })
      setUsers(newUsers)
    }
  }

  const onClear = () => {
    setFilteredUsers([])
    setUsers(currentUsers)
    setFilters(null)
  }

  return (
    <>
      <ReactTitle title={`Analogue / ${title}`} />

      {users && (
        <div className='mx-auto w-full pb-1 shadow-lg'>
          <div className='mx-auto mt-2 flex w-full max-w-[700px] items-center px-2 pt-5 md:mt-4'>
            <CollectiveSelect
              users={users}
              filteredUsers={filteredUsers}
              onSelect={onSelect}
              onClear={onClear}
              loading={isFetchingNextPage}
            />
            {slug === 'longnow' && (
              <CollectiveDropdown setFilters={setFilters} filters={filters} />
            )}
          </div>

          <Carousel className='mx-auto my-4 max-w-[1055px] px-1 sm:my-8'>
            {users.map(user => (
              <div
                key={user.username}
                className='filterImage mr-4 h-[60px] w-[60px] cursor-pointer rounded-full last:mr-0 sm:h-[90px] sm:w-[90px]'
                onClick={() => onSelect(`${user.id},${user.name}`)}
              >
                <Image
                  className='m-auto block h-full w-full rounded-full object-cover'
                  src={user.image}
                  alt={user.name}
                />
                <div
                  className={clsx(
                    'activeMask absolute left-5 top-4 text-5xl text-gold-500 drop-shadow-sm',
                    {
                      show: user.active
                    }
                  )}
                >
                  <FiCheck />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}

      {slug === 'longnow' && (
        <CollectiveTagsCarousel filters={filters} setFilters={setFilters} />
      )}

      <div className='mx-auto mt-2 max-w-[1120px] md:mt-9'>
        <CollectiveBookFeed
          gridClassName={clsx([
            'grid-cols-2 md:grid-cols-3',
            {
              'lg:grid-cols-4': col && col === '4'
            }
          ])}
          slug={slug}
          users={userIds}
          filters={filters}
        />
      </div>

      <a
        className='fixed bottom-0 z-10 flex w-full items-center justify-center border-t border-gray-50 bg-white p-4 text-sm font-light uppercase tracking-[3px] text-gray-200 dark:border-gray-700 dark:bg-gray-800 md:text-[16px]'
        href={process.env.ROOT_PATH}
        target='_blank'
        rel='noopener noreferrer'
      >
        Powered by
        <img
          className='ml-2 h-[21px]'
          src='https://ik.imagekit.io/analogue/analogue_logo_WeDM8Tame.png?tr=h-63'
          alt='Analogue Logo'
        />
      </a>
      <AudioPlayer />
    </>
  )
}

export default CollectiveEmbedProvider
