import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect
} from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

const HistoryContext = createContext(null)

const useHistory = () => useContext(HistoryContext)

const BrowserRouter = ({ children }: { children: React.ReactNode }) => {
  const { current: history } = useRef(createBrowserHistory({ window }))
  const [{ action, location }, setHistoryState] = useState({
    action: history.action,
    location: history.location
  })

  // TODO reset on login/logout
  // TODO store location history in localstorage and set on mount
  const [historyList, setHistoryList] = useState([])
  const [historyCurrentIndex, setHistoryCurrentIndex] = useState(-1)

  const hasBack = historyCurrentIndex !== -1 && historyList.length > 0
  const hasForward = historyCurrentIndex + 1 !== historyList.length

  useEffect(
    () =>
      history.listen(({ action, location }) => {
        setHistoryState({ action, location })
        if (action === 'REPLACE') {
          // handle same link clicked (changed key)
          setHistoryList([
            ...historyList.slice(0, historyCurrentIndex),
            location,
            ...historyList.slice(historyCurrentIndex, historyList.length)
          ])
        } else if (action === 'PUSH') {
          // handle new location
          const newHistory = [
            ...historyList.slice(0, historyCurrentIndex + 1),
            location
          ]
          setHistoryList(newHistory)
          setHistoryCurrentIndex(newHistory.length - 1)
        } else if (action === 'POP') {
          // Handle back
          if (historyList[historyCurrentIndex + 1]?.key === location.key) {
            setHistoryCurrentIndex(historyCurrentIndex + 1)
          } else {
            // Handle back
            setHistoryCurrentIndex(historyCurrentIndex - 1)
          }
        }
      }),
    [history, historyCurrentIndex]
  )

  return (
    <Router action={action} location={location} navigator={history}>
      <HistoryContext.Provider
        value={{
          history,
          historyList,
          historyCurrentIndex,
          hasBack,
          hasForward
        }}
      >
        {children}
      </HistoryContext.Provider>
    </Router>
  )
}

export { useHistory, BrowserRouter }
