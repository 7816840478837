import React from 'react'
import NiceModal from '@ebay/nice-modal-react'

import { useAddLog } from 'app/mainframe/queries/log'

import RequireAuth from 'app/mainframe/components/Auth/RequireAuth'
import PrimerSelect from 'app/mainframe/components/Log/PrimerSelect/PrimerSelect'
import Button from 'app/mainframe/components/Button/Button'

import { FiPlus } from 'react-icons/fi'

const LogButton = ({ content }) => {
  const { log, id, title } = content

  const { mutateAsync: addLog, isLoading } = useAddLog()

  const isLogged = log && log?.status !== 'saved'

  const onClick = e => {
    if (!isLogged) {
      e.preventDefault()
      addLog({ id, title }).then(result => {
        NiceModal.show('log-editor-modal', { slug: result.slug })
      })
    }
  }

  if (isLogged) {
    return <PrimerSelect content={content} />
  }

  return (
    <RequireAuth>
      <Button
        onClick={onClick}
        className='w-full'
        type='default'
        loading={isLoading}
        icon={<FiPlus className='mr-3' size='21px' />}
      >
        Log
      </Button>
    </RequireAuth>
  )
}

export default LogButton
