import React, { cloneElement } from 'react'
import NiceModal from '@ebay/nice-modal-react'

const PopConfirm = ({ onConfirm, text = 'Are you sure?', children }) => {
  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    NiceModal.show('pop-confirm-modal', { text: text, onConfirm: onConfirm })
  }
  return cloneElement(children, {
    onClick: onClick
  })
}

export default PopConfirm
