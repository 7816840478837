import ReactOnRails from 'react-on-rails'

import Mainframe from 'app/mainframe/App'
import PrimerEmbed from 'app/embed/PrimerEmbed/PrimerEmbed'
import CollectiveEmbed from 'app/embed/CollectiveEmbed/CollectiveEmbed'
import ContentPreviewEmbed from 'app/embed/ContentPreviewEmbed/ContentPreviewEmbed'

ReactOnRails.register({
  Mainframe,
  PrimerEmbed,
  CollectiveEmbed,
  ContentPreviewEmbed
})
