import React from 'react'

import CopyLink from 'app/mainframe/components/CopyLink'
import LinkWrapper from 'app/mainframe/components/LinkWrapper'

import Menu from 'app/mainframe/components/Menu'

import { FiShare } from 'react-icons/fi'

const Share = ({ log, content }) => {
  const url = `/${content.formSlug}/${content.slug}/@${log.user.username}`

  return (
    <Menu
      className='mb-3 w-32'
      position='top-end'
      menu={[
        {
          items: [
            <CopyLink to={url}>Copy link</CopyLink>,
            <LinkWrapper to={`${url}/share`}>Embed code</LinkWrapper>
          ]
        }
      ]}
    >
      <div className='flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-300 transition duration-200 hover:bg-gray-666 hover:text-gold-500'>
        <FiShare />
      </div>
    </Menu>
  )
}

export default Share
