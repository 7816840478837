import React, { useEffect, useRef } from 'react'
import { connect } from 'getstream'

import { useAuth } from 'app/mainframe/context/auth'

const useStream = (feed: 'notification' | 'network', callback: () => void) => {
  const subscription = useRef(null)
  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser) {
      const client = connect(
        process.env.STREAM_KEY,
        currentUser.streamToken,
        process.env.STREAM_ID
      )
      const streamFeed = client.feed(feed, `${currentUser.id}`)
      subscription.current = streamFeed.subscribe(callback).then(
        () => console.log(`stream connected - ${feed}:${currentUser.id}`),
        data =>
          console.log(
            `error with the stream connection - ${feed}:${currentUser.id}`,
            data
          )
      )
    }
    return () => {
      if (subscription?.current && subscription?.current?.cancel) {
        subscription.current.cancel()
      }
    }
  }, [currentUser])
}

export default useStream
