import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Pluralize from 'react-pluralize'

import ContentPreview from 'app/mainframe/components/Content/ContentPreview/ContentPreview'
import Image from 'app/mainframe/components/Image'
import TimeAgo from 'app/mainframe/components/TimeAgo'

import { FiLayers } from 'react-icons/fi'

import './LogPreview.css'

const LogPreview = ({
  log,
  content,
  className,
  onClick = null,
  showContent = false
}: {
  log?: any
  content?: any
  className?: string
  onClick?: any
  showContent?: boolean
}) => {
  const handleClick = e => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
  }
  return (
    <Link
      to={`/${content.formSlug}/${content.slug}/@${log.user.username}`}
      className={clsx([
        'logPreview group flex w-full flex-shrink-0 flex-col overflow-visible rounded-md p-6 text-gray-100 shadow-lg transition-all duration-200 sm:w-[308px]',
        className
      ])}
      onClick={handleClick}
    >
      {showContent && (
        <div className='mx-auto mb-5'>
          <ContentPreview content={content} hideTitle />
        </div>
      )}
      <Link
        to={`/@${log.user.username}`}
        className='mb-1 flex flex-row items-center text-gray-50 hover:text-gold-500'
        onClick={e => e.stopPropagation()}
      >
        <Image
          className='mr-2 h-5 w-5 rounded-full object-cover object-top'
          src={log.user.image}
          alt={log.user.name}
        />
        <p className='single-line text-base'>{log.user.displayName}</p>
      </Link>

      <div className='mt-1 mb-3 text-base text-gray-300 line-clamp-3'>
        {log.noteText}
      </div>

      <div className='flex items-center'>
        {log.primers && log.primers.length > 0 && (
          <div className='single-line mr-2 flex items-center text-xs text-gray-400'>
            <FiLayers className='mr-1.5 flex-shrink-0 text-gray-400' />
            {log.primers.length > 1 ? (
              <Pluralize
                className='flex items-center text-gray-400'
                singular='collection'
                count={log.primers.length}
              />
            ) : (
              log.primers[0].title
            )}
          </div>
        )}
        <div className='ml-auto mr-1 flex-shrink-0 text-xs text-gray-400'>
          <TimeAgo date={log.postedAt} />
        </div>
      </div>
    </Link>
  )
}

export default LogPreview
