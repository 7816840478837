/* eslint-disable no-nested-ternary */
import React from 'react'
import clsx from 'clsx'
import Tilt from 'react-parallax-tilt'

import { useRemoveLog } from 'app/mainframe/queries/log'
import { useAppConfig } from 'app/mainframe/context/appConfig'

import ContentMedium from 'app/mainframe/components/Content/ContentMedium'
import ContentImage from 'app/mainframe/components/Content/ContentImage/ContentImage'
import PopConfirm from 'app/mainframe/components/Modals/PopConfirm'
import LinkWrapper from 'app/mainframe/components/LinkWrapper'

import { AiOutlineLoading } from 'react-icons/ai'
import { IoCloseCircleOutline } from 'react-icons/io5'

import './ContentPreview.css'

interface ContentPreviewProps {
  content?: any
  logId?: number // will also trigger isOwner
  className?: string
  onClick?: any
  hideTitle?: boolean
  size?: 'default' | 'small' | 'medium' | 'large'
  status?: 'pub' | 'priv'
}

const ContentPreview = ({
  content,
  logId,
  className,
  onClick,
  hideTitle = false,
  size = 'default',
  status = 'pub'
}: ContentPreviewProps) => {
  const { title, formSlug, formDisplay, slug, creatorList } = content

  const { mutate: removeLog, isLoading } = useRemoveLog()
  const { embed } = useAppConfig()

  return (
    <LinkWrapper
      to={`/${formSlug}/${slug}`}
      className={clsx([
        'contentPreview group flex-shrink-0 overflow-visible',
        className,
        formDisplay,
        size,
        {
          'brightness-50 transition hover:brightness-100': status === 'priv'
        }
      ])}
      onClick={onClick}
    >
      {!embed && logId && (
        <button
          type='button'
          className='absolute right-0.5 top-0.5 z-10 cursor-pointer rounded-full bg-gray-800 text-3xl text-gray-300 opacity-0 shadow transition hover:text-red-700 group-hover:opacity-100'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <PopConfirm
            text='Remove from your library?'
            onConfirm={() => removeLog(logId)}
          >
            {isLoading ? (
              <AiOutlineLoading className='animate-spin text-2xl' />
            ) : (
              <IoCloseCircleOutline />
            )}
          </PopConfirm>
        </button>
      )}
      <div className='mx-auto table'>
        <Tilt
          glareEnable
          tiltEnable={false}
          glareMaxOpacity={0.3}
          glarePosition='all'
          scale={1.03}
          glareBorderRadius={
            formDisplay === 'article' || formDisplay === 'video' ? '7px' : '0px'
          }
        >
          <ContentImage content={content} />
        </Tilt>
        {size === 'default' && !hideTitle && (
          <div className='contentPreviewCaption mt-4 table-caption space-y-1.5 p-0.5'>
            <ContentMedium content={content} size='small' />
            <h3 className='text-base text-gray-800 line-clamp-2 dark:text-gray-50'>
              {content.medium === 'book'
                ? title.split(':')[0].split('(')[0]
                : content.medium === 'film' || content.medium === 'tv'
                ? title.split('(')[0]
                : title}
            </h3>
            {creatorList && creatorList.length > 0 && (
              <p className='text-sm text-gray-300 line-clamp-2'>
                {creatorList.map(creator => creator.name).join(', ')}
              </p>
            )}
          </div>
        )}
      </div>
    </LinkWrapper>
  )
}

export default ContentPreview
