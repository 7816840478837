import React, { useState } from 'react'

import { useCreatePrimer } from 'app/mainframe/queries/primer'
import { useMixpanel } from 'app/mainframe/context/mixpanel'

import TextInput from 'app/mainframe/components/Input/TextInput'

import { Transition } from '@headlessui/react'
import { FiPlus } from 'react-icons/fi'

const PrimerCreateButton = () => {
  const { mixpanel } = useMixpanel()
  const { mutateAsync, isLoading } = useCreatePrimer()

  const [visible, setVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const onChange = e => {
    const value = e.target.value
    setInputValue(value)
  }

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      createPrimer()
    }
    if (e.key === 'Escape') {
      e.stopPropagation()
      onClear()
    }
  }

  const onBlur = () => {
    if (inputValue.length === 0) {
      setVisible(false)
    }
  }

  const onClear = () => {
    setInputValue('')
    setVisible(false)
  }

  const createPrimer = () => {
    if (inputValue.length > 0 && !isLoading) {
      mutateAsync({
        title: inputValue
      }).then(response => {
        mixpanel.track('Primer Added', { id: response.id })
        onClear()
      })
    }
  }

  if (visible) {
    return (
      <div className='z-10 flex items-center w-full bg-gray-700'>
        <TextInput
          autoFocus
          loading={isLoading}
          value={inputValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClear={onClear}
          placeholder='Name your collection...'
          className='transition-slowest ease w-full rounded duration-500'
        />
        <div className='flex items-center ml-3 w-auto'>
          <Transition
            show={inputValue !== ''}
            enter='transition ease-in transform duration-100'
            enterFrom='translate-x-full opacity-0'
            enterTo='translate-x-0 opacity-100'
            leave='ease-out transition transform duration-100'
            leaveFrom='translate-x-0 opacity-100'
            leaveTo='translate-x-full opacity-0'
          >
            <div
              onClick={createPrimer}
              className='group flex items-center mr-2'
            >
              <div className='mr-1 px-2 py-1 group-hover:text-gold-500 font-mono text-xs bg-gray-900 rounded-md cursor-pointer'>
                return
              </div>
              <div className='group-hover:text-gold-500 text-gray-300 text-xs tracking-widest cursor-pointer uppercase'>
                Create
              </div>
            </div>
          </Transition>
          <div
            onClick={() => setVisible(false)}
            className='hover:text-gray-100 text-gray-300 text-xs tracking-widest cursor-pointer uppercase'
          >
            Cancel
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='flex items-center w-full'>
      <div
        onClick={() => setVisible(true)}
        className='group flex items-center px-0.5 py-1 w-full hover:text-gold-500 text-gray-300 text-sm tracking-widest rounded-md cursor-pointer select-none uppercase transition'
      >
        <div className='flex items-center justify-center mr-3 w-8 h-8 bg-gray-550 rounded'>
          <FiPlus className='group-hover:text-gold-500 text-gray-300 text-xl transition' />
        </div>
        New Collection
      </div>
    </div>
  )
}

export default PrimerCreateButton
