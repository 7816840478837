import { useEffect } from 'react'
import { toast } from 'react-toastify'

const ErrorMessage = ({ error }) => {
  useEffect(() => {
    toast.error(error.message)
  }, [error])

  return null
}

export default ErrorMessage
