import React, { useRef, useEffect } from 'react'
import clsx from 'clsx'

import { MdCancel } from 'react-icons/md'
import { AiOutlineLoading } from 'react-icons/ai'

interface TextInputProps {
  className?: string
  prefix?: any
  name?: string
  placeholder?: string
  value?: string
  size?: 'lg' | 'sm' | 'md' | undefined
  required?: boolean
  disabled?: boolean
  loading?: boolean
  autoFocus?: boolean
  onChange?: any
  onBlur?: any
  onFocus?: any
  onKeyDown?: any
  onClear?: any
}

export const TextInput = ({
  className,
  prefix,
  name,
  placeholder,
  value,
  size = 'md',
  disabled,
  loading,
  autoFocus = false,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onClear
}: TextInputProps) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div className='relative flex items-center w-full'>
      {prefix && (
        <div className='absolute flex items-center px-3 text-gray-300 text-lg'>
          {prefix}
        </div>
      )}
      <input
        type='text'
        autoFocus={autoFocus}
        name={name}
        ref={inputRef}
        disabled={disabled || loading}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        className={clsx([
          'w-full overflow-hidden text-gray-100 border focus:ring-transparent p-1 text-base bg-gray-800 border-gray-600 focus:border-gold-800 py-2.5',
          className,
          prefix ? 'pl-10' : 'pl-3',
          onClear || loading ? 'pr-9' : 'pr-3'
        ])}
      />
      {(loading || (onClear && value !== '')) && (
        <div
          className='absolute right-0 flex items-center ml-auto px-3 text-gray-400 text-base cursor-pointer'
          onClick={onClear}
        >
          {loading ? (
            <AiOutlineLoading className='animate-spin' />
          ) : (
            <MdCancel />
          )}
        </div>
      )}
    </div>
  )
}
