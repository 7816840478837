import React, { useState, useEffect } from 'react'

import { useRemoveLog } from 'app/mainframe/queries/log'
import { useUserPrimersList } from 'app/mainframe/queries/user'

import { FiCheckCircle } from 'react-icons/fi'
import {
  AiOutlineSearch,
  AiOutlineLoading,
  AiOutlineDelete
} from 'react-icons/ai'

import Popover from 'app/mainframe/components/Popover'
import Button from 'app/mainframe/components/Button/Button'
import TextInput from 'app/mainframe/components/Input/TextInput'
import PrimerListItem from 'app/mainframe/components/Log/PrimerSelect/PrimerListItem'
import PrimerCreateButton from 'app/mainframe/components/Log/PrimerSelect/PrimerCreateButton'
import PopConfirm from 'app/mainframe/components/Modals/PopConfirm'

const PrimerSelect = ({ content }) => {
  const { log } = content

  const { mutateAsync: removeLog, isLoading: isLoadingRemove } = useRemoveLog()
  const { data: primers, isLoading: isLoadingPrimers } = useUserPrimersList()

  const [filteredPrimers, setFilteredPrimers] = useState(null)
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (primers) {
      const primersAfterFilter = filterCurrentPrimers(primers)
      setFilteredPrimers(primersAfterFilter)
    }
  }, [primers, log])

  const filterCurrentPrimers = primersToFilter => {
    if (log && log.primers && log.primers.length > 0) {
      const currentPrimersIds = log.primers.map(primer => primer.id)
      const currentPrimers = []
      const filteredPrimers = primersToFilter.filter(primer => {
        if (currentPrimersIds.includes(primer.id)) {
          primer.selected = true
          currentPrimers.push(primer)
        } else {
          return true
        }
      })
      return currentPrimers.concat(filteredPrimers)
    }
    return primersToFilter
  }

  const resetFilter = () => {
    setQuery('')
    setFilteredPrimers(primers)
  }

  const onInputChange = e => {
    const { value } = e.target
    setQuery(value)

    if (primers && primers.length > 0) {
      setFilteredPrimers(
        primers.filter(
          primer =>
            primer.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
        )
      )
    }

    // TODO handle enter press (add to first item in list or create collection + add)
  }

  const SelectPanel = () => {
    return (
      <>
        {primers && primers.length > 5 && (
          <div className='mb-1 rounded border-b border-gray-600 px-3 pt-1 pb-3 shadow-md'>
            <TextInput
              autoFocus
              value={query}
              className='rounded'
              onClear={resetFilter}
              onChange={onInputChange}
              prefix={<AiOutlineSearch />}
              placeholder='Search your collections...'
            />
          </div>
        )}
        <div className='styled-scrollbar max-h-[260px]'>
          <div className='px-2'>
            {filteredPrimers &&
              filteredPrimers.map(primer => (
                <PrimerListItem
                  key={primer.id}
                  primer={primer}
                  log={log}
                  content={content}
                />
              ))}
            {isLoadingPrimers && (
              <AiOutlineLoading className='my-6 w-full animate-spin text-xl' />
            )}
          </div>
        </div>

        <div className='flex h-[56px] items-center rounded border-t border-gray-600 px-4 pt-2'>
          <PrimerCreateButton />
          <PopConfirm
            text='Remove from your library?'
            onConfirm={() => {
              if (!isLoadingRemove) {
                removeLog(log.id).then(() => {
                  close()
                })
              }
            }}
          >
            <div className='absolute right-4 ml-auto cursor-pointer pl-2 text-2xl text-gray-500 transition-all hover:text-gray-50'>
              {isLoadingRemove ? (
                <AiOutlineLoading className='animate-spin cursor-default text-lg text-gray-500 hover:text-gray-500' />
              ) : (
                <AiOutlineDelete />
              )}
            </div>
          </PopConfirm>
        </div>
      </>
    )
  }

  return (
    <Popover offset={-5} width='target' content={<SelectPanel />}>
      <Button className='w-full' type='primary' loading={isLoadingRemove}>
        <div className='single-line flex items-center'>
          {!isLoadingRemove && (
            <FiCheckCircle
              className='mr-3 hidden flex-shrink-0 sm:block'
              size='21px'
            />
          )}
          <div className='single-line text-left'>
            {`${
              log.primersCount > 0
                ? log.primersCount === 1
                  ? `${log.primers[0].title}`
                  : `${log.primersCount} collections`
                : 'Logged'
            }`}
          </div>
        </div>
      </Button>
    </Popover>
  )
}

export default PrimerSelect
