import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import AppHeader from 'app/mainframe/components/Header/AppHeader'
import MobileBar from 'app/mainframe/components/Header/MobileBar'
import SearchBar from 'app/mainframe/components/Search/SearchBar/SearchBar'
import Sidebar from 'app/mainframe/components/Sidebar/Sidebar'
import AudioPlayer from 'app/mainframe/components/AudioPlayer/AudioPlayer'

import { AiOutlineLoading } from 'react-icons/ai'

const AppLayout = () => {
  return (
    <>
      <div className='hidden sm:block'>
        <Sidebar />
      </div>
      <div className='sm:ml-24'>
        <div className='sticky top-0 z-50 hidden sm:block'>
          <AppHeader />
        </div>
        <div className='block sm:hidden'>
          <div className='border-b border-gray-700'>
            <SearchBar />
          </div>
          <MobileBar />
        </div>
        <Suspense
          fallback={
            <div className='absolute flex h-96 w-screen items-center justify-center'>
              <AiOutlineLoading className='flex animate-spin items-center justify-center text-2xl leading-none text-gray-50' />
            </div>
          }
        >
          <div className='mx-auto max-w-screen-2xl px-4 pt-4 pb-20 sm:pb-0 lg:px-6'>
            <Outlet />
          </div>
        </Suspense>
      </div>
      <AudioPlayer />
    </>
  )
}

export default AppLayout
