import React from 'react'
import { Link } from 'react-router-dom'

import Image from 'app/mainframe/components/Image'

const PrimerResult = ({ item = null }) => (
  <Link to={`/collection/${item.slug}`} className='flex items-center p-3'>
    <div className='flex-shrink-0 mr-2 p-1 w-12 h-12'>
      <Image
        src={
          item.image_url
            ? item.image_url
            : item.content_collection && item.content_collection[0]
            ? item.content_collection[0].image
            : null
        }
        alt={item.title}
        className='w-full h-full object-cover'
      />
    </div>
    <div className='flex flex-col min-w-0'>
      <div
        className='single-line mb-1 text-gray-200 text-base'
        dangerouslySetInnerHTML={{
          __html: item._highlightResult.title
            ? item._highlightResult.title.value
            : item.title
        }}
      />
      <div
        className='single-line text-gray-400 text-sm'
        dangerouslySetInnerHTML={{
          __html: item._highlightResult.user_list
            ? item._highlightResult.user_list.map(c => c.value).join(', ')
            : item.user_list.join(', ')
        }}
      />
    </div>
  </Link>
)

export default PrimerResult
