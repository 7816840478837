import React, { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { useAuth } from 'app/mainframe/context/auth'
import RequireAuth from 'app/mainframe/components/Auth/RequireAuth'
import Image from 'app/mainframe/components/Image'
import Button from 'app/mainframe/components/Button/Button'

const menu = [
  { title: 'Explore', to: '/explore' },
  { title: 'About', to: '/about' },
  { title: 'Features', to: '/features' }
]

const Header = () => {
  const { currentUser } = useAuth()
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = throttle(
    () => setIsScrolled(window.pageYOffset > 0),
    250
  )

  return (
    <div
      className={clsx([
        'sticky top-0 z-50 border-b',
        {
          'border-gray-700 bg-gray-850': showMobileNav,
          'border-gray-700 backdrop-blur backdrop-brightness-75 transition duration-300':
            isScrolled && !showMobileNav,
          'border-transparent bg-transparent transition duration-300':
            !isScrolled && !showMobileNav
        }
      ])}
    >
      <div className='mx-auto flex max-w-screen-2xl flex-col'>
        <div className='flex max-h-16 w-full items-center justify-between'>
          <Link className='relative ml-6 w-40 flex-shrink-0 md:w-48' to='/'>
            <img
              src='https://ik.imagekit.io/analogue/analogue_logo_WeDM8Tame.png?tr=h-63'
              alt='Analogue Logo'
            />
          </Link>
          <nav className='invisible flex h-full min-w-0 flex-shrink items-center justify-center py-5 text-base md:visible md:space-x-10'>
            {menu.map(({ to, title }) => (
              <Link
                key={title}
                to={to}
                className='relative text-sm font-thin uppercase leading-6 tracking-[4px] text-gray-200 transition duration-150 ease-out hover:text-gold-500'
              >
                <span className='block'>{title}</span>
              </Link>
            ))}
          </nav>
          <div className='mr-6 flex w-48 items-center justify-end '>
            {currentUser ? (
              <Link to={`/@${currentUser.username}`}>
                <Image
                  className='ml-6 h-8 w-8 cursor-pointer rounded-full object-cover'
                  src={currentUser.image}
                  alt={currentUser.username}
                />
              </Link>
            ) : (
              <>
                <Link
                  to='/login'
                  className='hidden text-sm font-thin uppercase leading-6 tracking-[4px] text-gray-200 transition duration-150 ease-out hover:text-gold-500 md:block'
                >
                  Login
                </Link>
                <RequireAuth>
                  <Button
                    unstyled
                    className='my-2 ml-6 hidden rounded-none bg-gray-900 text-gold-500 hover:border-gold-500 md:block'
                  >
                    Signup
                  </Button>
                </RequireAuth>
              </>
            )}

            <svg
              className='block flex-shrink-0 cursor-pointer md:hidden'
              onClick={() => setShowMobileNav(!showMobileNav)}
              width='24'
              height='24'
              fill='#aaa'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H4C3.44772 6 3 5.55228 3 5ZM3 12C3 11.4477 3.44772 11 4 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12ZM3 19C3 18.4477 3.44772 18 4 18H16C16.5523 18 17 18.4477 17 19C17 19.5523 16.5523 20 16 20H4C3.44772 20 3 19.5523 3 19Z'
              ></path>
            </svg>
          </div>
        </div>
        {showMobileNav && (
          <div className='flex flex-col md:hidden'>
            {!currentUser && (
              <>
                <RequireAuth>
                  <div className='flex w-full px-6'>
                    <Button
                      unstyled
                      className='w-full rounded-none bg-gray-900 py-6 text-base tracking-[4px] text-gold-500 hover:border-gold-500'
                    >
                      Request Access
                    </Button>
                  </div>
                </RequireAuth>
                <Link
                  to='/login'
                  onClick={() => setShowMobileNav(!showMobileNav)}
                  className='w-full px-6 py-6 text-base uppercase tracking-[4px] text-gray-100 transition'
                >
                  Login
                </Link>
              </>
            )}
            {menu.map(({ to, title }) => (
              <Link
                key={to}
                to={to}
                onClick={() => setShowMobileNav(!showMobileNav)}
                className='w-full px-6 py-6 text-base uppercase tracking-[4px] text-gray-100 transition'
              >
                {title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
