import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import { DirectUpload } from 'activestorage'
import { toast } from 'react-toastify'

import { useAuth } from 'app/mainframe/context/auth'
import { useEditContent } from 'app/mainframe/queries/content'

import { RiImageAddLine } from 'react-icons/ri'

const ContentUpload = ({ className = null, content }) => {
  const { currentUser } = useAuth()
  const { mutateAsync: editContent, isLoading } = useEditContent()
  const [imageLoading, setImageLoading] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0]
    if (file) {
      setImageLoading(true)
      const toasted = toast.loading('Uploading image', {
        autoClose: false
      })
      const upload = new DirectUpload(
        file,
        '/api/rails/active_storage/direct_uploads'
      )
      upload.create((error, blob) => {
        setImageLoading(false)
        if (error) {
          toast.update(toasted, {
            render: 'Error updating',
            type: 'error',
            autoClose: 3333,
            isLoading: false
          })
        } else {
          toast.update(toasted, {
            render: 'Updating content'
          })
          editContent({ image: blob.signed_id, slug: content.slug }).then(
            () => {
              toast.update(toasted, {
                render: 'Success',
                type: 'success',
                autoClose: 3333,
                isLoading: false
              })
            }
          )
        }
      })
    } else {
      toast.error('No image file found')
    }
  }, [])

  const onDropRejected = fileRejections => {
    const error =
      fileRejections[0]?.errors[0]?.code === 'file-invalid-type'
        ? 'Only JPG or PNG images allowed'
        : 'Image must be smaller than 15MB'
    toast.error(error)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    onDropRejected: onDropRejected,
    accept: 'image/jpeg, image/png',
    maxSize: 15728640, // max 15mb
    maxFiles: 1,
    multiple: false,
    disabled: isLoading
  })

  const loading = isLoading || imageLoading

  if (
    currentUser &&
    (currentUser.type === 'oracle' || currentUser.type === 'curator')
  ) {
    return (
      <div
        {...getRootProps({
          className: clsx([
            'absolute bottom-2 left-2 z-10 flex h-10 w-10 items-center border border-gray-650 justify-center hover:scale-110 text-gray-100 hover:text-gold-500 rounded-full bg-gray-800 duration-200 hover:border-gold-500 group-hover:translate-y-0 group-hover:opacity-100 shadow-md',
            className,
            loading ? '' : 'translate-y-1/4 opacity-0'
          ])
        })}
      >
        <input {...getInputProps()} />
        <button
          className='absolute flex items-center justify-center w-full h-full'
          onClick={e => e.preventDefault()}
        >
          <RiImageAddLine className='text-xl' />
        </button>
      </div>
    )
  }

  return null
}
export default ContentUpload
