/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'

const CustomMenu = ({ menu, children, position = null, className = null }) => {
  return (
    <Menu>
      <div className='relative'>
        <Menu.Button>{children}</Menu.Button>

        <Transition
          as={Fragment}
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'
        >
          <Menu.Items
            className={clsx([
              className,
              'shadow-main absolute rounded-md border border-gray-666 bg-gray-750 p-1',
              {
                'bottom-full': position === 'top',
                'left-0 bottom-full': position === 'top-start',
                'right-0 bottom-full': position === 'top-end',
                'top-full': position === 'bottom',
                'left-0 top-full': position === 'bottom-start',
                'right-0 top-full': position === 'bottom-end'
              }
            ])}
          >
            {menu.map((section, sectionIndex) => (
              <div
                key={sectionIndex}
                className='select-none border-b border-gray-666 pt-1 pb-1 first:pt-0 last:border-none last:pb-0'
              >
                {section.title && (
                  <h4 className='px-2.5 text-xxs uppercase tracking-widest text-gray-300'>
                    {section.title}
                  </h4>
                )}
                {section.items.map((item, itemIndex) => (
                  <Menu.Item key={itemIndex}>
                    {({ active }) =>
                      React.cloneElement(item, {
                        key: `${itemIndex}`,
                        className: clsx([
                          'rounded-md flex w-full cursor-pointer items-center text-base px-2.5 py-3 leading-none text-gray-100 transition hover:bg-gray-666',
                          { 'bg-gray-666': active }
                        ])
                      })
                    }
                  </Menu.Item>
                ))}
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  )
}

export default CustomMenu
