import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { useAuth } from 'app/mainframe/context/auth'
import { useAddLog, useLog } from 'app/mainframe/queries/log'
import { useContent } from 'app/mainframe/queries/content'

import Modal from 'app/mainframe/components/Modals/Modal'
import { AddContentSearch } from 'app/mainframe/components/Search/Searches'
import LogNote from 'app/mainframe/components/Log/LogNote/LogNote'

import { AiOutlineLoading } from 'react-icons/ai'

const LogEditorModal = NiceModal.create(({ slug }) => {
  const modal = useModal()

  const { mutateAsync: addLog } = useAddLog()

  return (
    <Modal
      centered={!!slug}
      maskOnly={!slug}
      closable={!!slug}
      visible={modal.visible}
      onCancel={modal.hide}
      maskClosable={!slug}
      width={slug ? 'max-w-screen-sm' : 'max-w-md'}
      className={
        slug
          ? ''
          : 'relative flex flex-col mt-6 mx-auto w-full rounded-lg shadow-xl'
      }
      padding={slug && 'p-0'}
    >
      {slug ? (
        <LogEditor slug={slug} onCancel={modal.hide} />
      ) : (
        <AddContentSearch
          onSelect={item => {
            addLog({ id: item.objectID, title: item.title }).then(response => {
              modal.show({ slug: response.slug })
            })
          }}
        />
      )}
    </Modal>
  )
})

const LogEditor = ({ slug, onCancel }) => {
  const { currentUser } = useAuth()
  const { data: content, isLoading: isLoadingContent } = useContent(slug)
  const { data: log, isLoading: isLoadingLog } = useLog(
    slug,
    currentUser.username
  )

  if (isLoadingLog || isLoadingContent) {
    return <AiOutlineLoading className='my-6 w-full text-xl animate-spin' />
  }
  return <LogNote content={content} log={log} onCancel={onCancel} editable />
}

export default LogEditorModal
