import React, { FunctionComponent } from 'react'
import { EmbedProviders } from 'app/mainframe/context/embed'
import clsx from 'clsx'

import { PrimerLogFeed } from 'app/mainframe/components/Feed/Grids'
import AudioPlayer from 'app/mainframe/components/AudioPlayer/AudioPlayer'

interface PrimerEmbedProps {
  slug: string
  theme?: string
  col?: string
}

const PrimerEmbedProvider: FunctionComponent<PrimerEmbedProps> = props => {
  return (
    <EmbedProviders initialTheme={props.theme}>
      <PrimerEmbed {...props} />
    </EmbedProviders>
  )
}

const PrimerEmbed: FunctionComponent<PrimerEmbedProps> = ({
  slug,
  theme,
  col
}) => {
  return (
    <>
      <div
        className='w-full overflow-auto pt-8 md:pt-12'
        style={{ maxHeight: 'calc(100vh - 54px)' }}
      >
        <div className='mx-auto max-w-6xl'>
          <PrimerLogFeed
            slug={slug}
            gridClassName={clsx([
              'grid-cols-2 md:grid-cols-3',
              {
                'lg:grid-cols-4': col && col === '4'
              }
            ])}
          />
        </div>
      </div>
      <a
        className='fixed bottom-0 z-10 flex w-full items-center justify-center border-t border-gray-50 p-4 text-sm font-light uppercase tracking-[3px] text-gray-200 dark:border-gray-700 dark:bg-gray-800 md:text-[16px]'
        href={`${process.env.ROOT_PATH}/collection/${slug}`}
        target='_blank'
        rel='noreferrer'
      >
        Powered by
        <img
          className='ml-2 h-[21px]'
          src='https://ik.imagekit.io/analogue/analogue_logo_WeDM8Tame.png?tr=h-63'
          alt='Analogue Logo'
        />
      </a>
      <AudioPlayer />
    </>
  )
}

export default PrimerEmbedProvider
