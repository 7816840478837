import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
  createContext,
  useContext
} from 'react'

const ThemeContext = createContext(null)

/**
 * Saves the old theme for future use
 * @param {string} theme - Name of current theme
 * @return {string} previousTheme
 */
const usePreviousTheme = (theme: string) => {
  const ref = useRef<string>()
  useEffect(() => {
    ref.current = theme
  })
  return ref.current
}

/**
 * Gets user preferences from local storage
 * @param {string} key - localStorage key
 * @return {array} getter and setter for user preferred theme
 */
const useStorageTheme = (key: string) => {
  const defaultTheme = 'dark'

  // get user preference from media
  // const userPreference =
  //   !!window.matchMedia &&
  //   window.matchMedia('(prefers-color-scheme: dark)').matches

  const [theme, setTheme] = useState<string>(
    localStorage.getItem(key) || defaultTheme
  )

  // update stored theme
  useEffect(() => {
    localStorage.setItem(key, theme)
  }, [theme, key])

  return [theme, setTheme] as const
}

interface Props {
  initialTheme?: string
  children: any
}

const ThemeProvider = ({ initialTheme, children }: Props) => {
  let value

  if (initialTheme) {
    const root = document.documentElement
    root.className = ''
    root.classList.add(initialTheme)

    value = { initialTheme }
  } else {
    const [theme, setTheme] = useStorageTheme('theme')

    // update root element class on theme change
    const previousTheme = usePreviousTheme(theme)
    useLayoutEffect(() => {
      const root = document.documentElement
      root.classList.remove(previousTheme)
      root.classList.add(theme)
    }, [theme, previousTheme])

    const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light')

    value = useMemo(
      () => ({
        theme,
        toggleTheme
      }),
      [theme]
    )
  }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

const useTheme = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error(`useTheme must be used within ThemeProvider`)
  }
  return context
}

export { ThemeProvider, useTheme }
