import React, { useRef, useEffect } from 'react'
import throttle from 'lodash.throttle'
import Flickity from 'react-flickity-component'

import 'flickity/css/flickity.css'
import './Carousel.css'

const defaultOptions = {
  freeScroll: true,
  groupCells: true,
  imagesLoaded: true,
  pageDots: false,
  contain: true,
  cellAlign: 'left',
  selectedAttraction: 0.2,
  friction: 0.8
}

const Carousel = ({ className = null, options = defaultOptions, children }) => {
  const flickity = useRef(null)

  useEffect(() => {
    flickity.current.on('dragStart', onDragStart)
    flickity.current.on('dragEnd', onDragEnd)
    return () => {
      flickity.current.off('dragStart', onDragStart)
      flickity.current.off('dragEnd', onDragEnd)
    }
  }, [])

  const onDragStart = () =>
    flickity.current.slider.childNodes.forEach(
      slide => (slide.style.pointerEvents = 'none')
    )
  const onDragEnd = () =>
    flickity.current.slider.childNodes.forEach(
      slide => (slide.style.pointerEvents = 'all')
    )

  const onWheel = throttle((e: any) => {
    if (e.deltaX > 0) {
      flickity.current?.next()
    } else if (e.deltaX < 0) {
      flickity.current?.previous()
    }
  }, 250)

  return (
    <div onWheel={onWheel}>
      <Flickity
        flickityRef={c => (flickity.current = c)}
        className={className}
        options={options}
      >
        {children}
      </Flickity>
    </div>
  )
}

export default Carousel
