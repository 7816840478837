import React from 'react'
import clsx from 'clsx'

import { useAuth } from 'app/mainframe/context/auth'
import { SiAndela } from 'react-icons/si'

const OracleButton = ({ to, className = null }) => {
  const { currentUser } = useAuth()

  if (currentUser && currentUser.type === 'oracle') {
    return (
      <a
        href={`/admin${to}`}
        target='_blank'
        rel='noopener noreferrer'
        className={clsx([
          'flex items-center justify-center w-10 h-10  rounded-full shadow',
          className
            ? className
            : 'fixed z-50 top-20 right-5 bg-gray-200 dark:bg-gray-900'
        ])}
      >
        <SiAndela className='text-lg' />
      </a>
    )
  }
  return null
}

export default OracleButton
