import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from 'app/mainframe/components/Modals/Modal'
import { UniversalSearch } from 'app/mainframe/components/Search/Searches'

const SearchModal = NiceModal.create(() => {
  const modal = useModal()

  return (
    <Modal
      closable={false}
      maskOnly
      visible={modal.visible}
      onCancel={modal.hide}
      width='max-w-md'
      className='relative flex flex-col mt-6 mx-auto w-full rounded-lg shadow-xl'
    >
      <UniversalSearch />
    </Modal>
  )
})

export default SearchModal
