import React from 'react'
import { Select } from 'antd'

import './CollectiveSelect.css'

interface Props {
  users: any[]
  filteredUsers: any[]
  onSelect: (value) => void
  onClear: () => void
  loading: boolean
}

const CollectiveSelect = ({
  users,
  filteredUsers,
  onSelect,
  onClear,
  loading
}: Props) => {
  const onChange = value => onSelect(value)

  const handleChange = value => {
    if (value.length === 0) onClear()
  }

  return (
    <Select
      allowClear
      mode='multiple'
      placeholder='Filter by member . . .'
      value={filteredUsers}
      className='collectiveSelect'
      dropdownClassName='collectiveSelectDropdown'
      notFoundContent={null}
      disabled={loading}
      onSelect={onChange}
      onDeselect={onChange}
      onChange={handleChange}
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      {users.map(user => (
        <Select.Option
          key={`${user.id},${user.name}`}
          value={`${user.id},${user.name}`}
        >
          <img src={user.image} alt={user.name} /> {user.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CollectiveSelect
