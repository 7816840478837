import React, { useState } from 'react'
import clsx from 'clsx'

import { useAuth } from 'app/mainframe/context/auth'
import { useEditLog } from 'app/mainframe/queries/log'

import { VscLock, VscUnlock } from 'react-icons/vsc'

const LogStatus = ({ log, content }) => {
  const { currentUser } = useAuth()
  const [status, setStatus] = useState(log.status)

  const { mutateAsync: editLog, isLoading } = useEditLog(
    content && content.slug,
    currentUser && currentUser.username
  )

  const handleClick = e => {
    e.preventDefault()
    const newStatus = status === 'pub' ? 'priv' : 'pub'
    setStatus(newStatus)
    editLog({
      ...log,
      status: newStatus
    })
  }

  if (
    log &&
    currentUser &&
    log.status !== 'saved' &&
    log.user?.username === currentUser.username
  ) {
    return (
      <div
        className={clsx([
          'mx-3 cursor-pointer text-lg transition duration-200 hover:text-gold-500',
          {
            'pointer-events-none': isLoading
          }
        ])}
        onClick={handleClick}
      >
        {status === 'pub' ? (
          <VscUnlock />
        ) : status === 'priv' ? (
          <VscLock />
        ) : null}
      </div>
    )
  }
  return null
}

export default LogStatus
