import React from 'react'
import { useModal } from '@ebay/nice-modal-react'
import { useHotkeys } from 'react-hotkeys-hook'

import { AiOutlineSearch } from 'react-icons/ai'

import './SearchBar.css'

const SearchBar = () => {
  const searchModal = useModal('search-modal')

  useHotkeys('cmd+k', () => {
    searchModal.show()
  })

  return (
    <div
      onClick={() => searchModal.show()}
      className='searchBar group relative mx-4 flex cursor-pointer items-center py-[20px] px-2 text-lg font-light leading-none text-gray-500 md:w-full'
    >
      <AiOutlineSearch className='mr-4 text-2xl text-gray-200 group-hover:text-gray-100' />
      Search
    </div>
  )
}

export default SearchBar
