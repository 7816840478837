import React, { createContext, useContext } from 'react'

const AppConfigContext = createContext(null)

const AppConfigProvider = ({ embed = false, children }) => {
  return (
    <AppConfigContext.Provider value={{ embed }}>
      {children}
    </AppConfigContext.Provider>
  )
}

const useAppConfig = () => {
  const context = useContext(AppConfigContext)
  if (context === undefined) {
    throw new Error('useAppConfig must be used within AppConfigProvider')
  }
  return context
}

export { AppConfigProvider, useAppConfig }
