import React, { useState } from 'react'
import { BubbleMenu as Bubble } from '@tiptap/react'
import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineStrikethrough
} from 'react-icons/ai'
import { BiHighlight, BiLink, BiLinkExternal, BiTrash } from 'react-icons/bi'
import { BsListUl, BsListOl } from 'react-icons/bs'
import clsx from 'clsx'

import { Input } from 'antd'

const BubbleMenu = ({ editor }) => {
  if (editor.isActive('image')) {
    return <Bubble editor={editor} className='display-none'></Bubble>
  }
  return (
    <Bubble
      className='shadow-main flex items-center px-1 py-1.5 bg-gray-650 rounded-md'
      tippyOptions={{ duration: 100 }}
      editor={editor}
    >
      {editor.isActive('link') ? (
        <LinkEditor editor={editor} />
      ) : (
        <>
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
          >
            <AiOutlineBold />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
          >
            <AiOutlineItalic />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            isActive={editor.isActive('strike')}
          >
            <AiOutlineStrikethrough />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleLink().run()}
            isActive={editor.isActive('link')}
          >
            <BiLink />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
          >
            <BsListUl />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}
          >
            <BsListOl />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            isActive={editor.isActive('blockquote')}
          >
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0'
              viewBox='0 0 24 24'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g>
                <path fill='none' d='M0 0h24v24H0z'></path>
                <path d='M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z'></path>
              </g>
            </svg>
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            isActive={editor.isActive('highlight')}
          >
            <BiHighlight />
          </Button>
        </>
      )}
    </Bubble>
  )
}

const Button = ({ onClick, isActive, children }) => (
  <button
    onClick={onClick}
    className={clsx(
      'mx-0.5 p-1.5 hover:text-gold-500 text-lg leading-none rounded-md',
      {
        'text-gold-500 bg-gray-550': isActive
      }
    )}
  >
    {children}
  </button>
)

const LinkEditor = ({ editor }) => {
  const editorLink = editor.getAttributes('link').href
  const [link, setLink] = useState(editorLink)

  return (
    <div className='w-[300px] flex items-center'>
      <Input
        autoFocus={!(editorLink && editorLink.length > 0)}
        className='mr-1 hover:bg-transparent border border-transparent outline-none focus:outline-none shadow-none focus:shadow-none ring-0 focus:ring-0'
        defaultValue={editorLink}
        placeholder='Enter link URL...'
        onChange={e => {
          setLink(e.target.value)
        }}
        onPressEnter={e => {
          e.preventDefault()
          editor.chain().focus().setLink({ href: link }).run()
        }}
      />
      <a href={link} target='_blank' rel='noopener noreferrer'>
        <BiLinkExternal className='flex-shrink-0 ml-auto mr-2 hover:text-gold-500 text-gray-200 text-base leading-none' />
      </a>
      <BiTrash
        onClick={() => editor.chain().focus().unsetLink().run()}
        className='flex-shrink-0 mr-1 hover:text-gold-500 text-gray-200 text-base leading-none cursor-pointer'
      />
    </div>
  )
}

export default BubbleMenu
