import React, { useMemo } from 'react'
import NiceModal, { ModalDef } from '@ebay/nice-modal-react'

import InfoModal from './InfoModal'
import PopConfirmModal from './PopConfirmModal'
import SignupModal from './SignupModal'
import SearchModal from './SearchModal'
import LogEditorModal from './LogEditorModal'
import LogCarouselModal from './LogCarouselModal'

const modals = [
  { 'info-modal': InfoModal },
  { 'pop-confirm-modal': PopConfirmModal },
  { 'signup-modal': SignupModal },
  { 'search-modal': SearchModal },
  { 'log-editor-modal': LogEditorModal },
  { 'log-carousel-modal': LogCarouselModal }
]

const ModalsProvider = ({ children }: { children: React.ReactNode }) => {
  const modalDefs = useMemo(
    () =>
      modals.map(modal =>
        Object.entries(modal).map(([id, component]) => (
          <ModalDef key={id} id={id} component={component} />
        ))
      ),
    [modals]
  )

  return (
    <NiceModal.Provider>
      {children}
      {modalDefs}
    </NiceModal.Provider>
  )
}

export { ModalsProvider }
