import React from 'react'
import { Link } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react'

import { useAppConfig } from 'app/mainframe/context/appConfig'

const LinkWrapper = ({ children, to, className = null, onClick = null }) => {
  const { embed } = useAppConfig()

  const handleClick = e => {
    NiceModal.hide('log-editor-modal')
    NiceModal.hide('log-carousel-modal')
    if (onClick) onClick(e)
  }

  if (embed) {
    return (
      <a
        href={`${process.env.ROOT_PATH}${to}`}
        onClick={onClick}
        className={className}
        target='_blank'
        rel='noopener noreferrer'
      >
        {children}
      </a>
    )
  }
  return (
    <Link to={to} onClick={handleClick} className={className}>
      {children}
    </Link>
  )
}

export default LinkWrapper
