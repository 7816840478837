import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useHistory } from 'app/mainframe/context/history'
import { useAuth } from 'app/mainframe/context/auth'

import Notifications from 'app/mainframe/components/Notifications'

import Image from 'app/mainframe/components/Image'
import Menu from 'app/mainframe/components/Menu'
import Button from 'app/mainframe/components/Button/Button'
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc'

import analogueIcon from 'shared/assets/img/icon_gold_transparent.png'

const MobileBar = () => {
  const { currentUser, logout } = useAuth()
  const { hasBack, hasForward } = useHistory()
  const navigate = useNavigate()

  return (
    <div className='fixed bottom-0 z-10 flex w-full flex-row items-center justify-between border-t border-b border-gray-700 bg-gray-800 px-6 py-2'>
      <div className='flex flex-row'>
        <Button
          disabled={!hasBack}
          onClick={hasBack ? () => navigate(-1) : null}
          unstyled
          type='text'
          className='mr-5 p-0 text-3xl'
        >
          <VscChevronLeft />
        </Button>
        <Button
          disabled={!hasForward}
          onClick={hasForward ? () => navigate(1) : null}
          unstyled
          type='text'
          className='p-0 text-3xl'
        >
          <VscChevronRight />
        </Button>
      </div>

      <Link className='justify-self-center' to='/'>
        <img src={analogueIcon} alt='Analogue logo' className='w-10' />
      </Link>

      <div className='flex flex-row space-x-2'>
        <Notifications />

        <Menu
          position='top-end'
          className='mb-3 w-32'
          menu={[
            {
              items: [
                <Link to={`/@${currentUser.username}`}>Profile</Link>,
                <Link to='/settings/profile'>Settings</Link>,
                <Link to='/membership'>Membership</Link>,
                <button type='button' onClick={logout}>
                  Logout
                </button>
              ]
            }
          ]}
        >
          <Image
            className='h-8 w-8 cursor-pointer rounded-full object-cover'
            src={currentUser.image}
            alt={currentUser.username}
          />
        </Menu>
      </div>
    </div>
  )
}

export default MobileBar
