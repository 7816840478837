import React from 'react'
import { useNavigate } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react'

import { useAuth } from 'app/mainframe/context/auth'

import SearchWrapper from 'app/mainframe/components/Search/SearchWrapper'
import ProfileResult from 'app/mainframe/components/Search/results/ProfileResult'
import ContentResult from 'app/mainframe/components/Search/results/ContentResult'
import PrimerResult from 'app/mainframe/components/Search/results/PrimerResult'

const getIndex = (key, currentUser) => {
  const indices = {
    user: {
      key: 'user',
      title: 'People',
      indexName: `User_${process.env.ALGOLIASEARCH_INDEX}`,
      filter: `has_works?:true OR public:true${
        currentUser
          ? ` OR follower_ids:${currentUser.id} OR following_ids:${
              currentUser.id
            }${currentUser.followingIds.map(
              userId => ` OR following_ids:${userId}`
            )}`
          : ''
      }`,
      component: <ProfileResult />
    },
    content: {
      key: 'content',
      title: 'Content',
      indexName: `Content_${process.env.ALGOLIASEARCH_INDEX}`,
      filter: `has_creators?:true${
        currentUser
          ? ` OR creator_ids:${currentUser.id} OR public_log_user_ids:${currentUser.id} OR private_log_user_ids:${currentUser.id} OR saved_log_user_ids:${currentUser.id}`
          : ''
      }`,
      component: <ContentResult />
    },
    primer: {
      key: 'primer',
      title: 'Collection',
      indexName: `Primer_${process.env.ALGOLIASEARCH_INDEX}`,
      filter: `private:false${
        currentUser
          ? ` OR user_ids:${currentUser.id} OR follower_ids:${currentUser.id}`
          : ''
      }`,
      component: <PrimerResult />
    }
  }
  return indices[key]
}

const UniversalSearch = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  return (
    <SearchWrapper
      autoFocus
      placeholder='Search content, people, or collections'
      indices={[
        getIndex('content', currentUser),
        getIndex('user', currentUser),
        getIndex('primer', currentUser)
      ]}
      onSelect={item => {
        NiceModal.hide('search-modal')
        item.form_slug
          ? navigate(`/${item.form_slug}/${item.slug}`)
          : item.username
          ? navigate(`/@${item.username}`)
          : navigate(`/collection/${item.slug}`)
      }}
    />
  )
}

const AddContentSearch = ({ onSelect }) => {
  const { currentUser } = useAuth()
  return (
    <SearchWrapper
      autoFocus
      placeholder='Search or paste a link'
      indices={[getIndex('content', currentUser)]}
      onSelect={item => {
        onSelect(item)
      }}
    />
  )
}

export { UniversalSearch, AddContentSearch }
