import React, { useState, useRef, useEffect } from 'react'
import Flickity from 'react-flickity-component'

import NiceModal from '@ebay/nice-modal-react'

import ContentHeader from 'app/mainframe/components/Content/ContentHeader'
import LogNote from 'app/mainframe/components/Log/LogNote/LogNote'
import EmptyNote from 'app/mainframe/components/Log/LogNote/EmptyNote'

import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc'

import './LogCarousel.css'
import { useLogCarouselContext } from 'app/mainframe/context/logCarousel'

const LogCarousel = ({ logs, content, center, hideContent }) => {
  const [currentSlide, setCurrentSlide] = useState(center)
  const _flickity = useRef(null)
  const { isResized, setIsResized } = useLogCarouselContext()
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 768px)').matches
  )

  const flickityOptions = {
    pageDots: logs && logs.length > 1,
    prevNextButtons: false,
    initialIndex: center,
    draggable: false,
    dragThreshold: 20,
    setGallerySize: true
  }

  useEffect(() => {
    if (_flickity.current) _flickity.current.on('change', onChange)
    return () => {
      if (_flickity.current) _flickity.current.off('change', onChange)
    }
  }, [])

  // hack: add delay to focus keyboard
  // https://github.com/theolampert/react-flickity-component/issues/97
  useEffect(() => {
    if (_flickity.current) {
      setTimeout(() => {
        _flickity.current.element.focus()
      }, 1111)
    }
  }, [_flickity.current])

  useEffect(() => {
    if (currentSlide >= 0 && logs[currentSlide]?.content) {
      NiceModal.show('log-carousel-modal', {
        logs: logs,
        content: logs[currentSlide].content,
        center: currentSlide
      })
    }
  }, [currentSlide])

  useEffect(() => {
    if (_flickity.current) _flickity.current.resize()
  }, [isResized])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    const handleResize = () => setIsMobile(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleResize)
    return () => mediaQuery.removeEventListener('change', handleResize)
  }, [])

  flickityOptions.draggable = isMobile

  const hideModal = () => NiceModal.hide('log-carousel-modal')

  const onChange = index => setCurrentSlide(index)

  const nextSlide = e => {
    e.preventDefault()
    e.stopPropagation()
    _flickity.current?.next()
  }
  const prevSlide = e => {
    e.preventDefault()
    e.stopPropagation()
    _flickity.current?.previous()
  }

  return (
    <>
      {currentSlide !== 0 && (
        <div
          className='w-[15vw] fixed z-40 inset-0 flex flex-col items-center justify-center h-screen bg-gradient-to-r from-gray-900 to-transparent invisible sm:visible'
          onClick={hideModal}
        >
          <div
            onClick={prevSlide}
            className='w-[45px] h-[45px] dark:hover:text-gold-500 flex items-center justify-center text-gray-200 dark:text-gray-300 hover:text-gray-600 text-3xl dark:bg-gray-800 bg-white border border-gray-50 dark:border-gray-600 rounded-full shadow hover:shadow-lg cursor-pointer select-none transition'
          >
            <VscChevronLeft />
          </div>
        </div>
      )}
      <Flickity
        flickityRef={c => (_flickity.current = c)}
        options={flickityOptions}
      >
        {logs && logs.length > 0 ? (
          logs.map(log => (
            <div
              key={log.id}
              className='styled-scrollbar mx-12 w-full max-w-screen-sm max-h-screen dark:bg-gray-800 bg-white rounded-lg shadow-xl sm:max-h-almost'
            >
              <LogNote
                hideContent={hideContent}
                log={log}
                content={log?.content ? log?.content : content}
              />
            </div>
          ))
        ) : (
          <div className='mx-12 p-5 w-full max-w-screen-sm dark:bg-gray-800 bg-white rounded-lg shadow-xl overflow-y-hidden'>
            {content && (
              <ContentHeader
                content={content}
                className='mb-5 pb-8 pt-5 border-b border-gray-50 dark:border-gray-700'
              />
            )}
            <EmptyNote
              className='my-12'
              text='No notes found'
              link={`/${content?.formSlug}/${content?.slug}`}
            />
          </div>
        )}
      </Flickity>
      {logs &&
        logs.length > 1 &&
        currentSlide !== _flickity.current?.slides.length - 1 && (
          <div
            className='w-[15vw] fixed z-40 right-0 top-0 flex flex-col items-center justify-center h-screen bg-gradient-to-l from-gray-900 to-transparent invisible sm:visible'
            onClick={hideModal}
          >
            <div
              onClick={nextSlide}
              className='w-[45px] h-[45px] dark:hover:text-gold-500 flex items-center justify-center text-gray-200 dark:text-gray-300 hover:text-gray-600 text-3xl dark:bg-gray-800 bg-white border border-gray-50 dark:border-gray-600 rounded-full shadow hover:shadow-lg cursor-pointer select-none transition'
            >
              <VscChevronRight />
            </div>
          </div>
        )}
    </>
  )
}

export default LogCarousel
