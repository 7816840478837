import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Image from 'app/mainframe/components/Image'
import Number from 'app/mainframe/components/Number'
import ProfileAvatarGroup from 'app/mainframe/components/Profile/ProfileAvatarGroup'

import './PrimerPreview.css'

interface PrimerPreviewProps {
  primer?: any
  className?: string
  showDescription?: boolean
}

const PrimerPreview = ({
  primer,
  className = null,
  showDescription = false
}: PrimerPreviewProps) => {
  const createPlaceholders = () => {
    if (primer.contentCollection && primer.contentCollection.length < 3) {
      const images = []
      for (let i = 3 - primer.contentCollection.length; i > 0; i--) {
        images.push(<div />)
      }
      return images
    }
    return null
  }

  return (
    <Link to={`/collection/${primer.slug}`} className={className}>
      <div
        className={clsx([
          'primerPreview flex-shrink-0 rounded-lg shadow-lg',
          {
            hasImage: primer.image,
            isPrivate: primer.private
          }
        ])}
      >
        {primer.image ? (
          <Image
            ratio='16x9'
            alt={primer.title}
            className='m-auto block h-full w-full object-cover'
            src={primer.image}
          />
        ) : (
          <div className='contentCollection'>
            <div />

            {createPlaceholders()}

            {primer.contentCollection &&
              primer.contentCollection
                .slice(
                  primer.contentCollection.length >= 4 ? 1 : 0,
                  primer.contentCollection.length >= 4 ? 4 : 3
                )
                .map(content => (
                  <Image
                    key={content.id}
                    ratio='2x3'
                    alt={content.title}
                    className={
                      content.formDisplay === 'film' ||
                      content.formDisplay === 'book'
                        ? ''
                        : 'square'
                    }
                    src={content.image}
                  />
                ))}
          </div>
        )}
        <div
          className={clsx(
            'primerContent absolute left-0 top-0 flex h-full flex-col py-6 pl-5 pr-2',
            primer.image ? 'w-full' : 'w-1/2'
          )}
        >
          <h3 className='mb-2 text-base line-clamp-2'>{primer.title}</h3>
          {primer.users && primer.users.length > 1 ? (
            <ProfileAvatarGroup users={primer.users} />
          ) : (
            <div className='flex items-center'>
              <img
                alt={primer.users[0].displayName}
                className={clsx([
                  'mr-2 h-5 w-5 rounded-full border border-gray-800',
                  { 'border-gold-500': primer.users[0].curated }
                ])}
                src={primer.users[0].image}
              />
              <p className='single-line text-sm text-gray-200'>
                {primer.users[0].displayName}
              </p>
            </div>
          )}
          <p className='mt-auto text-base text-gray-100'>
            <Number count={primer.logsCount} noun='log' />
          </p>
        </div>
      </div>

      {showDescription && primer.bodyText && (
        <div className='mt-5 mb-2 max-w-full px-2 text-base text-gray-200 line-clamp-3'>
          {primer.bodyText}
        </div>
      )}
    </Link>
  )
}

export default PrimerPreview
