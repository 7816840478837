import React from 'react'
import Carousel from 'app/mainframe/components/Carousel/Carousel'
import { FiPlus } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'
import clsx from 'clsx'

const primers = [
  {
    title: 'Manual for Civilization',
    slug: ''
  },
  {
    title: 'Long Term Thinking',
    slug: ''
  },
  {
    title: 'Mechanics of Civilization',
    slug: ''
  },
  { title: 'Rigorous Science Fiction', slug: '' },
  {
    title: 'Cultural Canon',
    slug: ''
  }
]
const CollectiveTagsCarousel = ({ filters, setFilters }) => {
  const filter = filters && filters.primer

  return (
    <div className='bg-gray-50 shadow-sm'>
      <Carousel className='max-w-[1055px] mx-auto px-1 py-3'>
        {primers.map(({ title }, index) => {
          const isActive =
            filter === primers[index].title || (index === 0 && !filter)

          return (
            <div
              key={title}
              onClick={() =>
                isActive || index === 0
                  ? setFilters({
                      ...filters,
                      primer: 'Manual for Civilization'
                    })
                  : setFilters({ ...filters, primer: title })
              }
              className={clsx([
                'flex whitespace-nowrap items-center m-2 px-4 py-1.5 text-sm border rounded-2xl cursor-pointer transition duration-200 ',
                isActive
                  ? 'text-white border-gray-300 shadow-inner flex-row-reverse font-medium bg-gray-200'
                  : 'text-gray-400 hover:text-gray-800 border-gray-100 hover:shadow bg-white'
              ])}
            >
              {index !== 0 && (
                <>
                  {isActive ? (
                    <IoClose className='flex-shrink-0 ml-1.5' />
                  ) : (
                    <FiPlus className='flex-shrink-0 mr-1.5' />
                  )}
                </>
              )}
              {title}
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

export default CollectiveTagsCarousel
