import { useQuery, useQueryClient, useMutation } from 'react-query'
import api from 'app/mainframe/api'
import { toast } from 'react-toastify'

const useLog = (slug, username) =>
  useQuery(['log', slug, username], () => api.Log.get(slug, username))

const useAddLog = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Log.create, {
    onMutate: async ({ title }) => {
      // TODO optimistically update books
      const toasted = toast.loading(`Adding ${title}`, {
        autoClose: false
      })
      return { toasted }
    },
    onSuccess: (data, variables, context: any) => {
      toast.update(context?.toasted, {
        render: `Added ${variables?.title}`,
        type: 'success',
        autoClose: 3333,
        isLoading: false
      })
      queryClient.invalidateQueries()
      queryClient.invalidateQueries('feed')
      queryClient.invalidateQueries(['content', data.slug])
      queryClient.invalidateQueries(['content', data.slug, 'logs'])
    },
    onError: (error: any, variables, context: any) => {
      toast.update(context?.toasted, {
        render: error.message,
        type: 'error',
        autoClose: 3333,
        isLoading: false
      })
    }
  })
}

const useLikeLog = logId => {
  const queryClient = useQueryClient()
  return useMutation(
    (liked: boolean) => (liked ? api.Log.unlike(logId) : api.Log.like(logId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['logs', 'saved'])
        queryClient.invalidateQueries(['logs', 'featured'])
      },
      onError: (error: any, variables, context: any) => {
        toast.error(error.message)
      }
    }
  )
}

const useSaveLog = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Log.save, {
    onMutate: async ({ content }) => {
      await queryClient.cancelQueries(['logs', 'saved'])

      const previousContent = queryClient.getQueryData([
        'content',
        content.slug
      ])
      queryClient.setQueryData(['content', content.slug], (data: any) => {
        return { ...data, log: { ...data.log, status: 'saved' } }
      })
      const toasted = toast.loading(`Saving ${content.title}`, {
        autoClose: false
      })
      return { previousContent, toasted }
    },
    onSuccess: (data, variables, context: any) => {
      toast.update(context?.toasted, {
        render: `Saved ${variables.content?.title}`,
        type: 'success',
        autoClose: 3333,
        isLoading: false
      })
      queryClient.invalidateQueries()
      queryClient.invalidateQueries(['logs', 'saved'])
      queryClient.invalidateQueries(['feed'])
      queryClient.invalidateQueries(['content', data.slug])
      queryClient.invalidateQueries(['content', data.slug, 'logs'])
    },
    onError: (error: any, variables, context: any) => {
      toast.update(context?.toasted, {
        render: error.message,
        type: 'error',
        autoClose: 3333,
        isLoading: false
      })
      if (context?.previousContent) {
        queryClient.setQueryData(
          ['content', variables.content.slug],
          context.previousContent
        )
      }
    }
  })
}

const useEditLog = (slug, username) => {
  const queryClient = useQueryClient()
  return useMutation(api.Log.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(['content', slug])
      queryClient.invalidateQueries(['content', slug, 'logs'])
      queryClient.invalidateQueries(['log', slug, username])
      queryClient.invalidateQueries('feed')
    },
    onError: (error: any, variables, context: any) => {
      toast.error(error.message)
    }
  })
}

const useRemoveLog = () => {
  const queryClient = useQueryClient()
  return useMutation(api.Log.del, {
    onMutate: async content => {
      const toasted = toast.loading('Removing from library', {
        autoClose: false
      })
      const previousContent = queryClient.getQueryData([
        'content',
        content.slug
      ])
      queryClient.setQueryData(['content', content.slug], (data: any) => {
        return { ...data, log: null }
      })
      return { previousContent, toasted }
    },
    onSuccess: (data, variables, context: any) => {
      queryClient.invalidateQueries()
      toast.update(context?.toasted, {
        render: `Removed ${data.title}`,
        type: 'success',
        autoClose: 3333,
        isLoading: false
      })
    },
    onError: (error: any, variables, context: any) => {
      toast.update(context?.toasted, {
        render: error.message,
        type: 'error',
        autoClose: 3333,
        isLoading: false
      })
      if (context?.previousContent) {
        queryClient.setQueryData(
          ['content', variables.content.slug],
          context.previousContent
        )
      }
    }
  })
}

const useFeaturedLogs = () =>
  useQuery(['logs', 'featured'], api.Log.featured, {
    staleTime: Infinity
  })

export {
  useLog,
  useAddLog,
  useEditLog,
  useRemoveLog,
  useSaveLog,
  useLikeLog,
  useFeaturedLogs
}
