import React from 'react'
import RequireAuth from 'app/mainframe/components/Auth/RequireAuth'
import { useSaveLog, useRemoveLog } from 'app/mainframe/queries/log'

import Button from 'app/mainframe/components/Button/Button'
import { IoBookmarkOutline, IoBookmark } from 'react-icons/io5'
import clsx from 'clsx'

const SaveButton = ({ content }) => {
  const { log } = content
  const saved = log && log.status === 'saved'

  const { mutate: saveLog, isLoading: isSaving } = useSaveLog()
  const { mutate: removeLog, isLoading: isDeleting } = useRemoveLog()

  const onClick = e => {
    if (saved) {
      removeLog(log.id)
    } else {
      saveLog({ content })
    }
  }

  return (
    <RequireAuth>
      <div onClick={onClick}>
        <Button
          className={clsx([
            'w-full hover:bg-gray-600',
            saved ? 'bg-gray-750 text-gold-500' : 'border-gray-500 bg-gray-750'
          ])}
          type='text'
          loading={isDeleting || isSaving}
        >
          {saved ? (
            <div className='flex items-center'>
              <IoBookmark className='mr-3' size='21px' />
              Saved
            </div>
          ) : (
            <div className='flex items-center'>
              {!isDeleting && !isSaving && (
                <IoBookmarkOutline className='mr-3' size='21px' />
              )}{' '}
              Save for later
            </div>
          )}
        </Button>
      </div>
    </RequireAuth>
  )
}

export default SaveButton
