import React from 'react'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import './ToastConfig.css'

const ToastConfig = () => (
  <ToastContainer
    position='top-right'
    autoClose={3333}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    transition={Slide}
    theme='dark'
    toastClassName={() =>
      'dark:bg-gray-700 dark:font-gray-300 light:bg-gray-50 light:font-gray-700 rounded px-2.5 flex py-3 shadow-main text-base'
    }
  />
)

export default ToastConfig
