import React, { useRef } from 'react'
import { Dialog } from '@headlessui/react'
import clsx from 'clsx'

interface ModalProps {
  children: React.ReactElement
  visible: boolean
  onCancel: () => void
  width?: string
  className?: string
  padding?: string
  centered?: boolean
  maskOnly?: boolean
  closable?: boolean
  maskClosable?: boolean
  initialFocus?: any
}

const Modal = ({
  children,
  visible,
  onCancel,
  width = 'max-w-sm',
  className,
  padding,
  centered,
  maskOnly = false,
  closable = true,
  maskClosable = true,
  initialFocus
}: ModalProps) => {
  const cancelButtonRef = useRef()

  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      initialFocus={
        initialFocus
          ? initialFocus
          : cancelButtonRef?.current
          ? cancelButtonRef
          : null
      }
      className={clsx([
        'fixed z-50 inset-0 flex justify-center',
        {
          'items-center': centered
        }
      ])}
    >
      <Dialog.Overlay
        className={clsx([
          'fixed inset-0 bg-black opacity-90',
          { 'pointer-events-none': !maskClosable }
        ])}
      />
      {closable && (
        <div
          className='absolute z-50 right-6 top-6 cursor-pointer'
          onClick={onCancel}
          ref={cancelButtonRef}
        >
          <svg
            className='text-grey-800 dark:text-gray-50 fill-current'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 18 18'
          >
            <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
          </svg>
        </div>
      )}
      <div
        onMouseDown={e => e.stopPropagation()}
        className={clsx([
          width,
          className,
          padding ? padding : 'p-5',
          {
            'relative flex flex-col mx-auto w-full bg-white dark:bg-gray-800 rounded-lg shadow-xl max-h-screen sm:max-h-almost styled-scrollbar':
              !maskOnly
          }
        ])}
      >
        {children}
      </div>
    </Dialog>
  )
}

export default Modal
