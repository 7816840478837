import React from 'react'
import NumericLabel from 'react-pretty-numbers'
import Pluralize from 'react-pluralize'

interface NumberProps {
  count: number
  noun?: string
  className?: string
}
const Number = ({ count, noun, className }: NumberProps) => {
  let precision
  if (count > 9999) {
    precision = 1
  } else if (count > 999 && count < 10000) {
    precision = 2
  } else {
    precision = 0
  }

  return (
    <span className={className}>
      <NumericLabel
        params={{
          currency: false,
          precision,
          shortFormat: true,
          justification: 'L'
        }}
      >
        {count}
      </NumericLabel>
      {noun && (
        <>
          {' '}
          <Pluralize
            className={
              className || 'text-xs uppercase tracking-wide text-gray-300'
            }
            singular={noun}
            count={count}
            showCount={false}
          />
        </>
      )}
    </span>
  )
}

export default Number
