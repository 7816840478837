import React, { useRef } from 'react'
import { FloatingMenu as Floating } from '@tiptap/react'
import { BsListUl, BsListOl } from 'react-icons/bs'
import { BiHeading, BiImage } from 'react-icons/bi'
import clsx from 'clsx'

import { handleUpload } from '../extensions/image'

const FloatingMenu = ({ editor, upload }) => {
  const fileInput = useRef(null)

  const handleFileChange = event => {
    const images = Array.from(event.target.files).filter((file: any) =>
      /image/i.test(file.type)
    )
    if (images.length === 0) {
      return
    }

    images.forEach(async image => {
      handleUpload(editor.view, image, upload)
    })
  }

  return (
    <Floating
      className='flex items-center px-1 py-1.5 bg-gray-650 rounded-md'
      tippyOptions={{ duration: 100 }}
      editor={editor}
    >
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        isActive={editor.isActive('heading', { level: 1 })}
      >
        <BiHeading />
      </Button>
      <Button
        onClick={() => fileInput.current?.click()}
        isActive={editor.isActive('image')}
      >
        <BiImage />
      </Button>
      <input
        type='file'
        ref={fileInput}
        className='hidden'
        accept='image/png,image/gif,image/jpeg'
        onChange={handleFileChange}
      />
      <Button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive('bulletList')}
      >
        <BsListUl />
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive('orderedList')}
      >
        <BsListOl />
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        isActive={editor.isActive('blockquote')}
      >
        <svg
          stroke='currentColor'
          fill='currentColor'
          strokeWidth='0'
          viewBox='0 0 24 24'
          height='1em'
          width='1em'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g>
            <path fill='none' d='M0 0h24v24H0z'></path>
            <path d='M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z'></path>
          </g>
        </svg>
      </Button>
    </Floating>
  )
}

const Button = ({ onClick, isActive, children }) => (
  <button
    onClick={onClick}
    className={clsx(
      'mx-0.5 p-1.5 hover:text-gold-500 text-lg leading-none rounded-md',
      {
        'text-gold-500 bg-gray-550': isActive
      }
    )}
  >
    {children}
  </button>
)

export default FloatingMenu
