import Image from '@tiptap/extension-image'
import { Plugin, PluginKey } from 'prosemirror-state'
import { toast } from 'react-toastify'

export default upload =>
  Image.extend({
    addAttributes() {
      const attrs = Image.config as any
      return {
        ...attrs.addAttributes(),
        id: {
          default: null
        }
      }
    },
    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('image'),
          props: {
            handlePaste(view, event) {
              const items = event.clipboardData.items as any
              for (const item of items) {
                if (item.type.indexOf('image') === 0) {
                  event.preventDefault()
                  const image = item.getAsFile()
                  handleUpload(view, image, upload)
                  return true
                }
              }
              return false
            },
            handleDrop(view, event: any) {
              if (event.dataTransfer?.files?.length < 1) {
                return
              }
              const images = Array.from(event.dataTransfer.files).filter(
                (file: any) => /image/i.test(file.type)
              )
              if (images.length === 0) {
                return
              }
              event.preventDefault()
              event.stopImmediatePropagation()

              images.forEach(async image => {
                handleUpload(view, image, upload)
              })
              return false
            }
          }
        })
      ]
    }
  })

export const handleUpload = (view, image, upload) => {
  const { schema } = view.state
  const toasted = toast.loading(`Uploading ${image.name}`, { autoClose: false })

  const reader = new FileReader()
  reader.onload = readerEvent => {
    upload(image).then(
      response => {
        toast.update(toasted, {
          render: 'Upload complete',
          type: 'success',
          autoClose: 3333,
          isLoading: false
        })
        const node = schema.nodes.image.create({
          src: response.url, // fallback: readerEvent.target.result
          alt: image.name,
          title: image.name,
          id: response.id
        })
        const transaction = view.state.tr.replaceSelectionWith(node)
        view.dispatch(transaction)
      },
      error => {
        toast.update(toasted, {
          render: 'Upload failed. Try to reload the page.',
          type: 'error',
          autoClose: 3333,
          isLoading: false
        })
      }
    )

    // TODO add placeholder
    // const placeholder = schema.nodes.image.create({
    //   src: readerEvent.target.result
    // })
    // const transaction = view.state.tr.insert(
    //   coordinates.pos,
    //   placeholder
    // )
    // view.dispatch(transaction)
  }
  reader.readAsDataURL(image)
}
