import React from 'react'
import LinkWrapper from 'app/mainframe/components/LinkWrapper'

import { useMixpanel } from 'app/mainframe/context/mixpanel'

import ContentUpload from 'app/mainframe/components/Upload/ContentUpload'
import ContentImage from 'app/mainframe/components/Content/ContentImage/ContentImage'
import Button from 'app/mainframe/components/Button/Button'

import { AiOutlineLink } from 'react-icons/ai'

import clsx from 'clsx'
import './ContentPreviewBox.css'

const formClassNames = {
  film: 'w-1/2',
  book: 'w-1/2',
  video: 'w-5/6',
  article: 'w-5/6',
  audio: 'w-2/3'
}

const ContentImageWrapper = ({ content, text = 'Purchase' }) => (
  <div
    className={clsx([
      'relative rounded-md',
      content.formDisplay === 'video' ? 'shadow-md' : 'shadow-book',
      formClassNames[content.formDisplay]
    ])}
  >
    {content.form === 'text' && (
      <div className='absolute z-10 hidden h-full w-full flex-col items-center justify-center text-center opacity-0 transition duration-300 group-hover:opacity-100 sm:flex'>
        <div className='absolute h-full w-full bg-gradient-to-br from-[#000000aa] to-[#000000dd]' />
        <Button size='small' className='max-w-[80%] bg-gray-800'>
          {content.medium === 'link' ? (
            <div className='flex w-full items-center text-xxs'>
              <AiOutlineLink className='mr-0.5 flex-shrink-0 text-sm text-gray-300' />
              <div className='single-line w-full'>{content.origin}</div>
            </div>
          ) : (
            text
          )}
        </Button>
      </div>
    )}
    <ContentImage content={content} />
    <ContentUpload className='mt-4' content={content} />
  </div>
)

const ContentPreviewBox = ({ content, to = null }) => {
  const { mixpanel } = useMixpanel()

  const amazonReferralCode = process.env.AMAZON_REFERRAL_CODE

  const onContentClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    mixpanel.track('ContentLink Clicked', {
      slug: content.slug,
      context: 'button'
    })
  }
  if (to) {
    return (
      <LinkWrapper
        to={to}
        className='contentPreviewBox grouprelative mx-auto flex aspect-square h-full w-full items-center justify-center sm:max-w-sm'
      >
        <ContentImageWrapper content={content} />
      </LinkWrapper>
    )
  }
  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={
        content.origin === 'amazon.com'
          ? `${content.originUrl}?tag=${amazonReferralCode}`
          : content.originUrl
      }
      onClick={onContentClick}
      className='contentPreviewBox group relative mx-auto flex aspect-square h-full w-full items-center justify-center sm:max-w-sm'
    >
      <ContentImageWrapper content={content} />
    </a>
  )
}

export default ContentPreviewBox
