/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { useMixpanel } from 'app/mainframe/context/mixpanel'

import { useAuth } from 'app/mainframe/context/auth'

export const Analytics = ({ children }) => {
  if (process.env.NODE_ENV === 'production') {
    const { currentUser } = useAuth()
    const { mixpanel } = useMixpanel()

    const [logRocketInitialized, setLogRocketInitialized] = useState(false)

    useEffect(() => {
      if (currentUser) {
        const userId = currentUser.id.toString()
        // Sentry (track all users)
        Sentry.setUser({
          id: userId,
          name: currentUser.name,
          email: currentUser.email,
          username: currentUser.username,
          ip_address: '{{auto}}'
        })

        // only track events for non curated users
        if (!currentUser.curated) {
          // Mixpanel
          mixpanel.identify(userId)
          mixpanel.people.set({
            $name: currentUser.name,
            $email: currentUser.email,
            $avatar: currentUser.image,
            username: currentUser.username,
            'Sign up date': currentUser.createdAt,
            USER_ID: userId
          })

          // LogRocket
          if (!logRocketInitialized) {
            setLogRocketInitialized(true)
            LogRocket.init('9zropr/mainframe')
          }

          LogRocket.identify(userId, {
            name: currentUser.name,
            email: currentUser.email,
            username: currentUser.username
          })

          // add LogRocket session URL to Sentry + Mixpanel
          LogRocket.getSessionURL(sessionURL => {
            Sentry.configureScope(scope => {
              scope.setExtra('sessionURL', sessionURL)
            })
            mixpanel.track('LogRocket', { sessionURL })
          })
        }
      }
    }, [currentUser])

    // track page views
    useEffect(() => {
      const properties = { path: window?.location?.pathname, search: null }
      const search = window?.location?.search
      if (search) {
        properties.search = search
      }
      mixpanel.track('Page Viewed', properties)
    }, [window?.location?.pathname])
  }

  return children
}
