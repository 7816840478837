import React, { useState, useEffect } from 'react'
import NiceModal from '@ebay/nice-modal-react'

import {
  useInfiniteContentLogs,
  useInfiniteContentPrimers
} from 'app/mainframe/queries/content'

import { useInfinitePrimerLogs } from 'app/mainframe/queries/primer'

import {
  useInfiniteCollectiveContents,
  useCollectiveLogs
} from 'app/mainframe/queries/collective'

import InfiniteGrid from 'app/mainframe/components/InfiniteGrid'
import {
  useInfiniteSavedContent,
  useInfiniteSavedLogs
} from 'app/mainframe/queries/user'
import {
  useInfinitePrimers,
  useInfiniteWorks
} from 'app/mainframe/queries/profile'

const ContentLogFeed = ({ slug, content }) => (
  <InfiniteGrid
    type='log'
    query={useInfiniteContentLogs(slug)}
    content={content}
  />
)
const ContentPrimerFeed = ({ slug }) => (
  <InfiniteGrid type='primer' query={useInfiniteContentPrimers(slug)} />
)

const CollectiveBookFeed = ({ slug, users, gridClassName, filters = null }) => {
  const [collectiveContent, setCollectiveContent] = useState(null)
  const { data, refetch } = useCollectiveLogs(slug, collectiveContent)

  // manually refetch data when content changes
  useEffect(() => {
    if (collectiveContent) {
      refetch()
    }
  }, [collectiveContent])

  // refetching triggers data change, which we set in logs carousel
  useEffect(() => {
    if (data) {
      NiceModal.show('log-carousel-modal', {
        logs: data,
        content: collectiveContent
      })
    }
  }, [data])

  return (
    <InfiniteGrid
      isCarousel
      content={collectiveContent}
      collectiveLogs={data}
      setCollectiveContent={setCollectiveContent}
      type='content'
      query={useInfiniteCollectiveContents(slug, users, filters)}
      gridClassName={gridClassName}
    />
  )
}
const PrimerLogFeed = ({ slug, gridClassName }) => (
  <InfiniteGrid
    isCarousel
    type='content'
    query={useInfinitePrimerLogs(slug)}
    gridClassName={gridClassName}
  />
)

const SavedContentFeed = () => (
  <InfiniteGrid
    type='content'
    query={useInfiniteSavedContent()}
    gridClassName='grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6'
  />
)

const SavedLogFeed = () => (
  <InfiniteGrid
    type='log'
    query={useInfiniteSavedLogs()}
    gridClassName='grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
  />
)

const ProfileWorkFeed = ({ username, gridClassName }) => (
  <InfiniteGrid
    type='content'
    query={useInfiniteWorks(username)}
    gridClassName={gridClassName}
  />
)
const ProfilePrimerFeed = ({ username }) => (
  <InfiniteGrid type='primer' query={useInfinitePrimers(username)} />
)

export {
  ContentLogFeed,
  ContentPrimerFeed,
  CollectiveBookFeed,
  PrimerLogFeed,
  SavedContentFeed,
  SavedLogFeed,
  ProfileWorkFeed,
  ProfilePrimerFeed
}
