import React from 'react'
import { Link } from 'react-router-dom'
import {
  FiBook,
  FiFilm,
  FiHeadphones,
  FiLink,
  FiMic,
  FiTv,
  FiYoutube
} from 'react-icons/fi'
import clsx from 'clsx'

const mediumIcons = {
  book: <FiBook />,
  album: <FiHeadphones />,
  song: <FiHeadphones />,
  podcast: <FiMic />,
  film: <FiFilm />,
  tv: <FiTv />,
  link: <FiLink />,
  video: <FiYoutube />
}

const ContentMedium = ({ content, size }) => {
  const medium = content?.medium.split('_')[0]

  return (
    <div
      className={clsx([
        'flex flex-row items-center space-x-1 text-gray-200',
        {
          'text-base': size === 'default',
          'text-sm': size === 'small'
        }
      ])}
    >
      {mediumIcons[medium]}
      <p
        className={clsx([
          'uppercase tracking-wider text-gray-200',
          {
            'text-sm': size === 'default',
            'text-xs': size === 'small'
          }
        ])}
      >
        {medium === 'link' ? content.origin : medium}
      </p>
    </div>
  )
}

const ContentMediumWrapper = ({ content, size = 'default' }) => {
  if (content.medium === 'link') {
    return (
      <a href={content.originUrl} target='_blank' rel='noopener noreferrer'>
        <ContentMedium content={content} size={size} />
      </a>
    )
  }
  return (
    <Link to={`/${content.formSlug}/${content.slug}`}>
      <ContentMedium content={content} size={size} />
    </Link>
  )
}
export default ContentMediumWrapper
