import React from 'react'
import ReactPlayer from 'react-player/lazy'

import Modal from 'app/mainframe/components/Modals/Modal'

const VideoModal = ({ content, visible = false, setVisible }) => {
  return (
    <Modal
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      className='m-0 p-0'
      width='max-w-100-vw'
    >
      <div className='aspect-video w-full'>
        <ReactPlayer
          controls
          url={content.mediaUrl}
          playing={visible}
          className='h-full w-full'
        />
      </div>
    </Modal>
  )
}

export default VideoModal
