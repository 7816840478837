import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import Image from 'app/mainframe/components/Image'

const ContentResult = ({ item = null }) => (
  <Link to={`/@${item.username}`} className='flex items-center px-3 py-1'>
    <div className='flex-shrink-0 mr-2 w-12 h-12 p-2'>
      <Image
        src={item.image_url}
        alt={item.title}
        className={clsx([
          'w-full h-full rounded-full object-contain border',
          item.curated ? 'border-gold-500' : 'border-gray-700'
        ])}
      />
    </div>
    <div
      className='single-line mr-2 text-gray-200 text-base'
      dangerouslySetInnerHTML={{
        __html: item._highlightResult.name
          ? item._highlightResult.name.value
          : item.name
      }}
    />

    <div
      className='single-line text-gray-400 text-sm'
      dangerouslySetInnerHTML={{
        __html: item._highlightResult.username
          ? item._highlightResult.username.value
          : item.username
      }}
    />
  </Link>
)

export default ContentResult
