import React, { useState } from 'react'
import clsx from 'clsx'

import { useModal } from '@ebay/nice-modal-react'
import { useAuth } from 'app/mainframe/context/auth'

import Button from 'app/mainframe/components/Button/Button'
import AuthWindow from 'app/mainframe/components/Auth/AuthWindow'

import { AiFillApple } from 'react-icons/ai'

const SocialAuth = ({
  text,
  provider,
  className = ''
}: {
  text?: string
  provider: 'google' | 'apple'
  className?: string
}) => {
  const { loadUser } = useAuth()
  const [popupVisible, setPopupVisible] = useState(false)
  const signupModal = useModal('signup-modal')

  const onClick = e => setPopupVisible(true)

  const onUnload = () => {
    signupModal.hide()
    loadUser()
    setPopupVisible(false)
  }

  return (
    <>
      <Button
        unstyled
        onClick={onClick}
        className={clsx(['w-full px-4 py-6 text-sm uppercase', className])}
      >
        {providerIcons[provider]}
        <span className='font-light'>
          {text} with {provider}
        </span>
      </Button>

      {popupVisible && (
        <AuthWindow onUnload={onUnload} url={`/api/users/auth/${provider}`} />
      )}
    </>
  )
}

const providerIcons = {
  apple: <AiFillApple className='mr-[10px] text-2xl text-white' />,
  google: (
    <svg width='25' height='25' className='mr-[7px] h-[25px] w-[25px]'>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z'
          fill='#4285F4'
        ></path>
        <path
          d='M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z'
          fill='#34A853'
        ></path>
        <path
          d='M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z'
          fill='#FBBC05'
        ></path>
        <path
          d='M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z'
          fill='#EA4335'
        ></path>
      </g>
    </svg>
  )
}

export default SocialAuth
