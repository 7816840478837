import React from 'react'

import Image from 'app/mainframe/components/Image'
import ContentPlayButton from 'app/mainframe/components/Content/ContentPlayButton/ContentPlayButton'
import { AiOutlineLink } from 'react-icons/ai'

import clsx from 'clsx'

import './ContentImage.css'

const ContentImage = ({ content, className = null }) => {
  const { title, image, formDisplay, mediaUrl } = content
  return (
    <div
      className={clsx([
        'contentImage group relative rounded-md',
        formDisplay,
        className
      ])}
    >
      <div>
        <Image
          ratio='2x3'
          src={image}
          className={clsx([
            'w-full object-cover object-center',
            formDisplay === 'video' || formDisplay === 'article'
              ? 'aspect-video'
              : 'aspect-auto'
          ])}
          alt={title}
        />
        {mediaUrl && <ContentPlayButton content={content} />}
        {formDisplay === 'article' && (
          <div className='linkCaption absolute -bottom-5 flex w-full flex-row items-center space-x-1'>
            <AiOutlineLink className='text-xxs text-gray-500' />
            <p className='truncate text-tiny uppercase tracking-wide text-[#888888]'>
              {content.origin}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ContentImage
